import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 50%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Titles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  align-items: center;
  padding: 0 60px;

  @media screen and (max-width: 600px) {
    padding: 0 30px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #f4f4f4;
`;

export const LineBlock = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #f4f4f4;

  &:last-child {
    display: none;
  }
`;

export const ScrollBlock = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  height: 320px;
  padding: 0 32px;

  @media screen and (max-width: 600px) {
    padding: 0 16px;
  }
`;

export const Item = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  align-items: center;
  padding: 0 20px;
  position: relative;

  @media screen and (max-width: 600px) {
    padding: 0 10px;
  }
`;

export const ShowActions = styled.div`
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;

  @media screen and (max-width: 600px) {
    left: -16px;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 25px;
  left: 10px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(73, 79, 89, 0.15);
  border-radius: 4px;
  padding: 12px 0 0;
  min-width: 120px;
  z-index: 99;
`;

export const MenuBlock = styled.div`
  margin-bottom: 12px;
`;

export const MenuItem = styled.p`
  padding: 12px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #1e2529;
  cursor: pointer;

  &:hover {
    background-color: #eceff1;
    color: #0E71EB;
  }
`;

export const RegularText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #333443;
  
  @media screen and (max-width: 425px) {
    padding: 0 5px;
  }
`;

export const BoldText = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 23px 0 0;
`;

export const Actions = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #09091A;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;
