import styled from 'styled-components';

export const Content = styled.div`
  margin: 32px 40px 0;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (max-width: 720px) {
    margin: 24px 16px 0;
    padding-top: 80px;
  }
`;