import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Top,
  Title,
  Length,
  Actions,
  ReportList,
  ReportItem,
  ReportTitle,
  ReportTextBlock,
  ReportText,
  ReportBoldText,
  ButtonMore,
} from './reports.style';
import { getReport } from '../../API/reports';
import { Document } from '../../assets/icons';
import Button from '../../components/Button/Button';
import Container from '../../layout/Container';
import ModalReport from '../../layout/Modal/ModalReport/ModalReport';
import useOnClickOutside from '../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addParams } from '../../store/slices/reportsSlice';

const Reports: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const refReport = useRef<any>();

  const { data } = useAppSelector(
    (state: { report: any }) => state.report,
  );

  const openModal = () => {
    setOpen(true);
  };

  const showReportData = (title: string, data: any) => {
    const params = {
      title,
      data,
    }
    dispatch(addParams(params));
    navigation('/report');
  }

  useEffect(() => {
    dispatch(getReport(null));
  }, [])

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open])

  useOnClickOutside(refReport, () => setOpen(false));
  
  return (
    <Container>
      <Wrapper>
        <Top>
          <Title>
            Reports <Length>(This month)</Length>
          </Title>
          <Actions>
            <Button
              text="Generate custom report"
              pad="10px 20px"
              icon={<Document fill={true} />}
              handleButton={openModal}
            />
          </Actions>
        </Top>

        <ReportList>
          <ReportItem>
            <ReportTitle>Accounts Receivable</ReportTitle>
            <ReportTextBlock>
              <ReportText>Current Due:</ReportText>
              <ReportBoldText>${data.accounts_receivable.current_due}</ReportBoldText>
            </ReportTextBlock>
            <ReportTextBlock>
              <ReportText>Past Due:</ReportText>
              <ReportBoldText>${data.accounts_receivable.past_due}</ReportBoldText>
            </ReportTextBlock>
            <ReportTextBlock>
              <ReportText>Total Due:</ReportText>
              <ReportBoldText>${data.accounts_receivable.total_due}</ReportBoldText>
            </ReportTextBlock>
            <ButtonMore onClick={() => showReportData('Accounts Receivable', data.accounts_receivable)}>More</ButtonMore>
          </ReportItem>
          <ReportItem>
            <ReportTitle>Company Revenue</ReportTitle>
            <ReportTextBlock>
              <ReportText>Company Revenue:</ReportText>
              <ReportBoldText>${data.company_revenue.revenue}</ReportBoldText>
            </ReportTextBlock>
            <ButtonMore onClick={() => showReportData('Company Revenue', data.company_revenue)}>More</ButtonMore>
          </ReportItem>
          <ReportItem>
            <ReportTitle>Accounts Payable</ReportTitle>
            <ReportTextBlock>
              <ReportText>Accounts Payable:</ReportText>
              <ReportBoldText>${data.accounts_payable.revenue}</ReportBoldText>
            </ReportTextBlock>
            <ButtonMore onClick={() => showReportData('Accounts Payable', data.accounts_payable)}>More</ButtonMore>
          </ReportItem>
          <ReportItem>
            <ReportTitle>Driver Pay Report</ReportTitle>
            <ReportTextBlock>
              <ReportText>Revenue:</ReportText>
              <ReportBoldText>${data.driver_pay.revenue}</ReportBoldText>
            </ReportTextBlock>
            <ReportTextBlock>
              <ReportText>Driver Fee:</ReportText>
              <ReportBoldText>${data.driver_pay.fee}</ReportBoldText>
            </ReportTextBlock>
            <ButtonMore onClick={() => showReportData('Driver Pay Report', data.driver_pay)}>More</ButtonMore>
          </ReportItem>
        </ReportList>

        {open && <ModalReport refReport={refReport} close={() => setOpen(false)} />}
      </Wrapper>
    </Container>
  );
};

export default Reports;
