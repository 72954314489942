import React, { useEffect, useRef, useState } from 'react';

import {
  Wrapper,
  Titles,
  Line,
  ScrollBlock,
  ShowActions,
  DropdownMenu,
  MenuBlock,
  MenuItem,
  Item,
  CheckBoxBlock,
  RegularText,
  BoldText,
  Bottom,
  Actions,
} from './modalFleetHistory.style';
import { ThreeDots } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  close: () => void;
  refHistory: any;
};

const ModalFleetHistory: React.FC<ModalAssignProps> = ({ close, refHistory }) => {
  const ref = useRef<any>();
  const [showActions, setShowActions] = useState<boolean>(false);

  const [listHistory, setListHistory] = useState<string[]>([]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showActions && ref.current && !ref.current.contains(e.target)) {
        setShowActions(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showActions]);

  return (
    <ModalPortal>
      <Wrapper ref={refHistory}>
        <Titles>
          <BoldText>Driver</BoldText>
          <BoldText>Miles</BoldText>
          <BoldText>Start date</BoldText>
          <BoldText>Finish date</BoldText>
          {listHistory.length !== 0 && <ShowActions onClick={() => setShowActions(!showActions)}>
            <ThreeDots />

            {showActions && (
              <DropdownMenu ref={ref}>
                <MenuBlock>
                  <MenuItem>Delete</MenuItem>
                </MenuBlock>
              </DropdownMenu>
            )}
          </ShowActions>}
        </Titles>
        <Line />
        <ScrollBlock>
          <Item>
            <CheckBoxBlock>
              <Checkbox
                checkboxName="history-id"
                checked={listHistory.includes('1')}
                setChecked={() => {
                  if (!listHistory.includes('1'))
                    setListHistory([...listHistory, '1']);
                  else setListHistory(listHistory.filter(item => item !== '1'));
                }}
              />
            </CheckBoxBlock>
            <RegularText>Henfry Frei</RegularText>
            <RegularText>438mil.</RegularText>
            <RegularText>19/08/2022</RegularText>
            <RegularText>27/08/2022</RegularText>
          </Item>
        </ScrollBlock>

        <Line />
        <Bottom>
          <Actions>
            <Button text="Close" pad="12px 26px" handleButton={close} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalFleetHistory;
