import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Top,
  TopPanel,
  TopTitle,
  Actions,
  Info,
  ImageTruck,
  TruckInfo,
  BoldExpenseText,
  SemiBoldText,
  BoldText,
  RegularText,
  SpanText,
  FleetExpenseBlock,
  Line,
  FleetInfoItem,
  MessageItem,
  FleetInfoItemColumn,
  FleetInfoItemColumnAttachments,
  FleetInfoItemColumnExpense,
  ExpenseFlex,
  ExpenseItem,
  ExpenseProfile,
  ExpenseLogo,
  ExpenseData,
  ExpenseName,
  ExpenseDate,
  ExpensePrice,
  ExpenseLoad,
  ExpensePhoto,
  FleetInfoBlock,
  FleetInfoMilesBlock,
  FleetInfoBlockTruck,
  FleetSectionBlockSmall,
  FleetSectionBlockBig,
  FleetSectionBlock,
  FleetSection,
  FleetInfoLabel,
  FleetBlock,
  FleetBlockDetail,
  Status,
  InlineFlex,
  ButtonBlock,
  TruckBlock,
  FleetTruckBlock,
  FleetInfoItemTrailer,
} from './truckPage.style';
import {
  Back,
  Document,
  FleetCamera,
  FleetClock,
  FleetNotes,
  Plus,
  Trips,
} from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import AuthHeader from '../../../components/Header/AuthHeader';
import TrackingMap from '../../../components/Tracking/TrackingMap/TrackingMap';
import ModalAddComment from '../../../layout/Modal/ModalFleet/ModalAddComment/ModalAddComment';
import ModalFleet from '../../../layout/Modal/ModalFleet/ModalFleet';
import ModalFleetExpense from '../../../layout/Modal/ModalFleet/ModalFleetExpense/ModalFleetExpense';
import ModalFleetHistory from '../../../layout/Modal/ModalFleet/ModalFleetHistory/ModalFleetHistory';
import ModalFleetNotes from '../../../layout/Modal/ModalFleet/ModalFleetNotes/ModalFleetNotes';
import ModalInspectionHistory from '../../../layout/Modal/ModalFleet/ModalInspectionHistory/ModalInspectionHistory';
import ModalMap from '../../../layout/Modal/ModalFleet/ModalMap/ModalMap';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const Truck: React.FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const refFleet = useRef<any>();
  const refExpense = useRef<any>();
  const refInspHistory = useRef<any>();
  const refNotes = useRef<any>();
  const refAddNotes = useRef<any>();
  const refMap = useRef<any>();
  const refHistory = useRef<any>();

  const { fleetInfo } = useAppSelector((state: { fleet: any }) => state.fleet);
  const [open, setOpen] = useState<boolean>(false);
  const [openFleetExpense, setOpenFleetExpense] = useState<boolean>(false);
  const [openInspectionHistory, setOpenInspectionHistory] =
    useState<boolean>(false);
  const [openNotes, setOpenNotes] = useState<boolean>(false);
  const [openAddNotes, setOpenAddNotes] = useState<boolean>(false);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [openHistory, setOpenHistory] = useState<boolean>(false);
  const formattedDate = fleetInfo.plate_expired_at.split(".");
  const formattedDate2 = fleetInfo.annual_inspection_at.split(".");

  const openModal = () => setOpen(true);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useEffect(() => {
    document.body.style.overflow = openFleetExpense ? 'hidden' : 'unset';
  }, [openFleetExpense]);

  useEffect(() => {
    document.body.style.overflow = openInspectionHistory ? 'hidden' : 'unset';
  }, [openInspectionHistory]);

  useEffect(() => {
    document.body.style.overflow = openHistory ? 'hidden' : 'unset';
  }, [openHistory]);

  useEffect(() => {
    document.body.style.overflow = openMap ? 'hidden' : 'unset';
  }, [openMap]);

  useEffect(() => {
    document.body.style.overflow = openNotes ? 'hidden' : 'unset';
  }, [openNotes]);

  useEffect(() => {
    if (fleetInfo.type === '') {
      navigation('/fleet');
    }
  }, []);

  useOnClickOutside(refFleet, () => setOpen(false));
  useOnClickOutside(refExpense, () => setOpenFleetExpense(false));
  useOnClickOutside(refInspHistory, () => setOpenInspectionHistory(false));
  useOnClickOutside(refNotes, () => setOpenNotes(false));
  useOnClickOutside(refAddNotes, () => setOpenAddNotes(false));
  useOnClickOutside(refMap, () => setOpenMap(false));
  useOnClickOutside(refHistory, () => setOpenHistory(false));

  return (
    <>
      <AuthHeader />
      <Wrapper>
        <Top>
          <TopTitle onClick={() => navigation('/fleet')}>
            <Back fill="#616172" /> Truck
          </TopTitle>
        </Top>
        <TopPanel>
          <TruckInfo>
            <ImageTruck />
            <Info>
              <SemiBoldText>Truck #{fleetInfo.id}</SemiBoldText>
              <RegularText>
                {fleetInfo.make + ' ' + fleetInfo.model}{' '}
                <SpanText>VIN</SpanText>
              </RegularText>
            </Info>
          </TruckInfo>
          <Actions>
            <SemiBoldText>Reminder</SemiBoldText>
            <Button text="Edit" pad="10px 20px" handleButton={openModal} />
          </Actions>
        </TopPanel>
        <Line />
        <FleetTruckBlock>
          <FleetInfoItem>
            <FleetInfoBlock>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenInspectionHistory(true)}
              >
                <div>
                  <FleetCamera />
                </div>
                <RegularText style={{ marginLeft: '12px' }}>
                  Inspection history
                </RegularText>
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenHistory(true)}
              >
                <div>
                  <FleetClock />
                </div>
                <RegularText style={{ marginLeft: '12px' }}>
                  History
                </RegularText>
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenMap(true)}
              >
                <div>
                  <Trips />
                </div>
                <RegularText style={{ marginLeft: '12px' }}>Map</RegularText>
              </div>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setOpenNotes(true)}
              >
                <div>
                  <FleetNotes />
                </div>
                <RegularText style={{ marginLeft: '12px' }}>
                  Coments / notes
                </RegularText>
              </div>
            </FleetInfoBlock>
          </FleetInfoItem>
          <FleetSectionBlock>
            <FleetSection>
              <BoldText>Information</BoldText>
              <FleetInfoItemColumn>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Truck</FleetInfoLabel>
                  <RegularText>{'Truck #' + fleetInfo.id}</RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Year</FleetInfoLabel>
                  <RegularText>{fleetInfo.year}</RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Make</FleetInfoLabel>
                  <RegularText>{fleetInfo.make}</RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Model</FleetInfoLabel>
                  <RegularText>{fleetInfo.model}</RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Color</FleetInfoLabel>
                  <RegularText>{fleetInfo.color}</RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Plate</FleetInfoLabel>
                  <RegularText>{fleetInfo.plate}</RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>State</FleetInfoLabel>
                  <RegularText>{fleetInfo.state}</RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Plate exp</FleetInfoLabel>
                  <RegularText>
                    {moment(formattedDate).format('MM/DD/yyyy')}
                  </RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Vin #</FleetInfoLabel>
                  <RegularText>{fleetInfo.vin}</RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Owner</FleetInfoLabel>
                  <RegularText>{fleetInfo.owner}</RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Annual INSP</FleetInfoLabel>
                  <RegularText>
                    {moment(formattedDate2).format(
                      'MM/DD/yyyy',
                    )}
                  </RegularText>
                </FleetInfoBlockTruck>
                <FleetInfoBlockTruck>
                  <FleetInfoLabel>Pass</FleetInfoLabel>
                  <RegularText>{fleetInfo.pass}</RegularText>
                </FleetInfoBlockTruck>
              </FleetInfoItemColumn>
            </FleetSection>

            <TruckBlock>
              <FleetSectionBlockSmall>
                <FleetSection>
                  <BoldText>Messages</BoldText>
                  <FleetInfoItemColumn>
                    <FleetInfoBlock>
                      <MessageItem>
                        <InlineFlex>
                          <Status
                            style={{
                              color: '#0e71eb',
                              backgroundColor: '#b0dcff',
                              marginRight: '10px',
                            }}
                          >
                            Viewed
                          </Status>
                          <SemiBoldText>Message title</SemiBoldText>
                        </InlineFlex>
                        <RegularText>
                          Short description of the message...
                        </RegularText>
                      </MessageItem>
                      <MessageItem>
                        <InlineFlex>
                          <Status
                            style={{
                              color: '#0baa1b',
                              backgroundColor: 'rgba(11, 170, 27, 0.15)',
                              marginRight: '10px',
                            }}
                          >
                            New
                          </Status>
                          <SemiBoldText>Oil mange soon...</SemiBoldText>
                        </InlineFlex>
                        <RegularText>Oil mange soon...</RegularText>
                      </MessageItem>
                    </FleetInfoBlock>
                  </FleetInfoItemColumn>
                </FleetSection>

                <FleetSection>
                  <BoldText>Expenses</BoldText>
                  <FleetInfoItemColumnExpense>
                    <FleetExpenseBlock>
                      <ExpenseItem>
                        <ExpenseProfile>
                          <ExpenseLogo />
                          <ExpenseData>
                            <ExpenseName>Armein Feinken</ExpenseName>
                            <ExpenseDate>19/09/2022</ExpenseDate>
                          </ExpenseData>
                        </ExpenseProfile>
                        <ExpenseProfile>
                          <ExpenseData>
                            <ExpensePrice>$730.00</ExpensePrice>
                            <ExpenseLoad>Pulling Fee | 5YU-754457</ExpenseLoad>
                          </ExpenseData>
                          <ExpensePhoto>Photo</ExpensePhoto>
                        </ExpenseProfile>
                      </ExpenseItem>
                    </FleetExpenseBlock>
                    <ExpenseFlex>
                      <ButtonBlock>
                        <Button
                          text="Add"
                          pad="10px 20px"
                          icon={<Plus />}
                          handleButton={() => setOpenFleetExpense(true)}
                        />
                      </ButtonBlock>
                      <RegularText>
                        Total: <BoldExpenseText>$3,100.00</BoldExpenseText>
                      </RegularText>
                    </ExpenseFlex>
                  </FleetInfoItemColumnExpense>
                </FleetSection>
              </FleetSectionBlockSmall>
              <FleetSectionBlockBig>
                <FleetSection>
                  <BoldText>Location</BoldText>
                  <FleetInfoItemColumn style={{ height: '540px' }}>
                    <TrackingMap activeDriverList={[]} />
                  </FleetInfoItemColumn>
                </FleetSection>
              </FleetSectionBlockBig>
            </TruckBlock>

            <TruckBlock>
              <FleetSectionBlockSmall>
                <FleetSection>
                  <BoldText>Number of miles</BoldText>
                  <FleetInfoItemColumn>
                    <FleetInfoMilesBlock>
                      <FleetBlock>
                        <SemiBoldText>Week miles</SemiBoldText>
                        <SemiBoldText>$ / Miles</SemiBoldText>
                      </FleetBlock>
                      <FleetBlock>
                        <RegularText>4500 miles</RegularText>
                        <RegularText>2.0 $</RegularText>
                      </FleetBlock>

                      <FleetBlock style={{ marginTop: '68px' }}>
                        <SemiBoldText>Gross</SemiBoldText>
                        <SemiBoldText>Expenses</SemiBoldText>
                      </FleetBlock>
                      <FleetBlock>
                        <RegularText>9000 $</RegularText>
                        <RegularText>1500 $</RegularText>
                      </FleetBlock>
                    </FleetInfoMilesBlock>
                  </FleetInfoItemColumn>
                </FleetSection>
              </FleetSectionBlockSmall>
              <FleetSectionBlockBig>
                <FleetSection>
                  <BoldText>Truck assignment</BoldText>
                  <FleetInfoItemColumn style={{ flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText style={{ marginBottom: 0 }}>
                        Assignment:
                      </SemiBoldText>
                      <RegularText style={{ textAlign: 'center' }}>
                        Sun 01
                      </RegularText>
                      <RegularText style={{ textAlign: 'center' }}>
                        Mono
                      </RegularText>
                      <RegularText style={{ textAlign: 'center' }}>
                        Space
                      </RegularText>
                    </div>
                    <Line
                      style={{ backgroundColor: '#E8E8E8', margin: '24px 0' }}
                    />
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText>Driver 1</SemiBoldText>
                      <Status
                        style={{
                          margin: '0 auto',
                          maxWidth: '78px',
                          color: '#0e71eb',
                          backgroundColor: '#b0dcff',
                        }}
                      >
                        Assigned
                      </Status>
                      <div></div>
                      <div></div>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText>Driver 2</SemiBoldText>
                      <div></div>
                      <div></div>
                      <Status
                        style={{
                          margin: '0 auto',
                          maxWidth: '78px',
                          display: 'inline-flex',
                          color: '#0e71eb',
                          backgroundColor: '#b0dcff',
                        }}
                      >
                        Assigned
                      </Status>
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '6fr 3fr 3fr 3fr',
                        alignItems: 'center',
                      }}
                    >
                      <SemiBoldText>Driver 3</SemiBoldText>
                      <div></div>
                      <Status
                        style={{
                          margin: '0 auto',
                          maxWidth: '78px',
                          display: 'inline-flex',
                          color: '#0e71eb',
                          backgroundColor: '#b0dcff',
                        }}
                      >
                        Assigned
                      </Status>
                      <div></div>
                    </div>
                  </FleetInfoItemColumn>
                </FleetSection>
              </FleetSectionBlockBig>
            </TruckBlock>

            <TruckBlock>
              <FleetSectionBlockSmall>
                <FleetSection>
                  <BoldText>Vehicle attachments</BoldText>
                  <FleetInfoItemColumnAttachments>
                    <FleetBlockDetail>
                      <TruckInfo>
                        <ImageTruck></ImageTruck>
                        <Info>
                          <SemiBoldText>Trailer #{fleetInfo.id}</SemiBoldText>
                          <RegularText>
                            {fleetInfo.make + ' ' + fleetInfo.model}
                          </RegularText>
                        </Info>
                      </TruckInfo>
                    </FleetBlockDetail>
                    <ButtonBlock>
                      <Button
                        text="Detail"
                        pad="10px 20px"
                        handleButton={openModal}
                      />
                    </ButtonBlock>
                  </FleetInfoItemColumnAttachments>
                </FleetSection>
              </FleetSectionBlockSmall>
              <FleetSectionBlockBig>
                <FleetSection>
                  <BoldText>Notes</BoldText>
                  <FleetInfoItemColumn>
                    <RegularText>
                      In ultricies. Ornare sit et molestie id elit. Leo, ipsum
                      vestibulum sed sit urna dictum dapibus imperdiet dapibus
                      platea vulputate ex. Velit interdum est. Eget vitae
                      imperdiet dictumst. Elit. Sit imperdiet venenatis molestie
                      justo nisi augue molestie ornare et. Et platea mattis
                      quis, quam, nulla nis
                    </RegularText>
                  </FleetInfoItemColumn>
                </FleetSection>
              </FleetSectionBlockBig>
            </TruckBlock>
            <FleetSection>
              <BoldText>Paperwork</BoldText>
              <FleetInfoItemTrailer style={{ padding: 0 }}>
                <Status
                  style={{
                    margin: '16px',
                    height: '46px',
                    padding: '10px',
                    display: 'inline-flex',
                    color: '#0e71eb',
                    backgroundColor: '#b0dcff',
                    cursor: 'pointer',
                  }}
                >
                  <Document color="#0E71EB" />
                  <p style={{ marginLeft: '5px' }}>Insurence</p>
                </Status>
                <Status
                  style={{
                    margin: '16px',
                    height: '46px',
                    padding: '10px',
                    display: 'inline-flex',
                    color: '#0e71eb',
                    backgroundColor: '#b0dcff',
                    cursor: 'pointer',
                  }}
                >
                  <Document color="#0E71EB" />
                  <p style={{ marginLeft: '5px' }}>Registration</p>
                </Status>
                <Status
                  style={{
                    margin: '16px',
                    height: '46px',
                    padding: '10px',
                    display: 'inline-flex',
                    color: '#0e71eb',
                    backgroundColor: '#b0dcff',
                    cursor: 'pointer',
                  }}
                >
                  <Document color="#0E71EB" />
                  <p style={{ marginLeft: '5px' }}>Contract</p>
                </Status>
              </FleetInfoItemTrailer>
            </FleetSection>
          </FleetSectionBlock>
        </FleetTruckBlock>

        {open && (
          <ModalFleet
            refFleet={refFleet}
            text='Edit Truck'
            close={() => setOpen(false)}
          />
        )}

        {openFleetExpense && (
          <ModalFleetExpense refExpense={refExpense} close={() => setOpenFleetExpense(false)} />
        )}

        {openInspectionHistory && (
          <ModalInspectionHistory
          refInspHistory={refInspHistory}
            close={() => setOpenInspectionHistory(false)}
          />
        )}

        {openNotes && (
          <ModalFleetNotes
          refNotes={refNotes}
            setOpenAddNotes={setOpenAddNotes}
            close={() => setOpenNotes(false)}
          />
        )}

        {openHistory && (
          <ModalFleetHistory refHistory={refHistory} close={() => setOpenHistory(false)} />
        )}

        {openMap && <ModalMap refMap={refMap} close={() => setOpenMap(false)} />}

        {openAddNotes && (
          <ModalAddComment refAddNotes={refAddNotes} close={() => setOpenAddNotes(false)} />
        )}
      </Wrapper>
    </>
  );
};

export default Truck;
