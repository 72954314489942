import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Actions,
  FieldIcon,
  InputDate,
  LoadsInputField,
  ReportDataBlock,
  ReportDataTitle,
  ReportDataTitleCompany,
  ReportList,
  ReportListScroll,
  ReportTitle,
  ReportTitleBlock,
  ReportTitleCompany,
  Title,
  Top,
  ViewOrders,
  Wrapper,
} from './reportItem.style';
import { Back, Calendar } from '../../../assets/icons';
import Container from '../../../layout/Container';
import { useAppSelector } from '../../../store/hooks';

const ReportItem: React.FC = () => {
  const navigation = useNavigate();
  const [searchDate, setSearchDate] = useState<Date[] | null[]>([null, null]);
  const [searchStartDate, searchEndDate] = searchDate;

  const { report } = useAppSelector((state: { report: any }) => state.report);

  return (
    <Container>
      <Wrapper>
        <Top>
          <Title onClick={() => navigation('/reports')}>
            <Back fill="#616172" /> {report.title}
          </Title>
          <Actions>
            <LoadsInputField>
              <FieldIcon>
                <Calendar />
              </FieldIcon>
              <InputDate
                selectsRange={true}
                startDate={searchStartDate}
                endDate={searchEndDate}
                dateFormat="MM/dd/yyyy"
                placeholderText="Choose..."
                onChange={(update: any) => setSearchDate(update)}
              />
            </LoadsInputField>
          </Actions>
        </Top>

        <ReportList>
          <ReportListScroll>
            {report.title === 'Accounts Receivable' && (
              <ReportTitleBlock
                style={{ gridTemplateColumns: '6fr repeat(4, 1fr)' }}
              >
                <ReportTitleCompany>Company Name</ReportTitleCompany>
                <ReportTitle>Order Count</ReportTitle>
                <ReportTitle>Total Due</ReportTitle>
                <ReportTitle>Order Count</ReportTitle>
                <ReportTitle>Past Due</ReportTitle>
              </ReportTitleBlock>
            )}
            {report.title === 'Company Revenue' && (
              <ReportTitleBlock
                style={{ gridTemplateColumns: '6fr repeat(2, 2fr)' }}
              >
                <ReportTitleCompany>Company Name</ReportTitleCompany>
                <ReportTitle>Order Count</ReportTitle>
                <ReportTitle>Amount</ReportTitle>
              </ReportTitleBlock>
            )}
            {report.title === 'Accounts Payable' && (
              <ReportTitleBlock
                style={{ gridTemplateColumns: '6fr repeat(2, 2fr)' }}
              >
                <ReportTitleCompany>Company Name</ReportTitleCompany>
                <ReportTitle>Order Count</ReportTitle>
                <ReportTitle>Amount</ReportTitle>
              </ReportTitleBlock>
            )}
            {report.title === 'Driver Pay Report' && (
              <ReportTitleBlock
                style={{ gridTemplateColumns: '6fr repeat(2, 2fr)' }}
              >
                <ReportTitleCompany>Name</ReportTitleCompany>
                <ReportTitle>Revenue</ReportTitle>
                <ReportTitle>Driver Fee</ReportTitle>
              </ReportTitleBlock>
            )}
            {report.title === 'Accounts Receivable' &&
              report.data.records.map((item: any) => {
                return (
                  <ReportDataBlock
                    key={item.id}
                    style={{ gridTemplateColumns: '6fr repeat(4, 1fr)' }}
                  >
                    <ReportDataTitleCompany>
                      US Star Trucking LLC
                    </ReportDataTitleCompany>
                    <ReportDataTitle>0</ReportDataTitle>
                    <ReportDataTitle>
                      $1,625.00 <ViewOrders>View Orders</ViewOrders>
                    </ReportDataTitle>
                    <ReportDataTitle>0</ReportDataTitle>
                    <ReportDataTitle>
                      $0.00 <ViewOrders>View Orders</ViewOrders>
                    </ReportDataTitle>
                  </ReportDataBlock>
                );
              })}
            {report.title === 'Company Revenue' &&
              report.data.records.map((item: any) => {
                return (
                  <ReportDataBlock
                    key={item.id}
                    style={{ gridTemplateColumns: '6fr repeat(2, 2fr)' }}
                  >
                    <ReportDataTitleCompany>
                      US Star Trucking LLC
                    </ReportDataTitleCompany>
                    <ReportDataTitle>0</ReportDataTitle>
                    <ReportDataTitle>
                      $1,625.00 <ViewOrders>View Orders</ViewOrders>
                    </ReportDataTitle>
                  </ReportDataBlock>
                );
              })}
            {report.title === 'Accounts Payable' &&
              report.data.records.map((item: any) => {
                return (
                  <ReportDataBlock
                    key={item.id}
                    style={{ gridTemplateColumns: '6fr repeat(2, 2fr)' }}
                  >
                    <ReportDataTitleCompany>
                      US Star Trucking LLC
                    </ReportDataTitleCompany>
                    <ReportDataTitle>0</ReportDataTitle>
                    <ReportDataTitle>
                      $1,625.00 <ViewOrders>View Orders</ViewOrders>
                    </ReportDataTitle>
                  </ReportDataBlock>
                );
              })}
            {report.title === 'Driver Pay Report' &&
              report.data.records.map((item: any) => {
                return (
                  <ReportDataBlock
                    key={item.id}
                    style={{ gridTemplateColumns: '6fr repeat(2, 2fr)' }}
                  >
                    <ReportDataTitleCompany>
                      {item.driver.first_name + ' ' + item.driver.last_name}
                    </ReportDataTitleCompany>
                    <ReportDataTitle>{item.revenue}</ReportDataTitle>
                    <ReportDataTitle>{item.fee}</ReportDataTitle>
                  </ReportDataBlock>
                );
              })}
          </ReportListScroll>
        </ReportList>
      </Wrapper>
    </Container>
  );
};

export default ReportItem;
