import React from 'react';

import {
  CheckBoxItem,
  CheckBoxSquare,
  CustomCheckBoxSquare,
  InputField,
  Label,
} from './checkbox.style';
import { Check } from '../../assets/icons';

type CheckboxProps = {
  checked: boolean;
  setChecked: any;
  checkboxName: string;
  title?: string;
  disabled?: boolean;
};

const Checkbox: React.FC<CheckboxProps> = ({
  checkboxName,
  title,
  checked,
  setChecked,
  disabled,
}) => {
  return (
    <>
      <InputField
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <CheckBoxItem>
          <CheckBoxSquare
            type="checkbox"
            name={checkboxName}
            id={checkboxName}
            onChange={setChecked}
            checked={checked}
            disabled={disabled}
          />
          <CustomCheckBoxSquare style={{backgroundColor: disabled && '#E9EAF8'}}>
            {!disabled && <Check right="3px" />}
          </CustomCheckBoxSquare>
        </CheckBoxItem>
        <Label
          htmlFor={checkboxName}
          style={{ marginBottom: 0, marginLeft: '12px' }}
        >
          {title}
        </Label>
        
      </InputField>
    </>
  );
};

export default Checkbox;
