import React, { ChangeEvent, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import { Calendar, SelectArrow } from '../../../../assets/icons';
import {
  Error,
  FieldIcon,
  InputDiv,
  LoadsBlock,
  LoadsFlex,
  LoadsInput,
  LoadsInputDate,
  LoadsInputField,
  LoadsInputFieldHalf,
  LoadsInputFieldThird,
  LoadsInputTextArea,
  LoadsLabel,
  LoadsSelect,
  LoadsText,
  LoadsTitle,
  Opt,
  SelectedValue,
} from '../createLoad.style';

type LoadsDetailProps = {
  handleInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: any,
    name: any,
    state: any,
  ) => void;
  handleInputTextArea: (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    setState: any,
    name: any,
    state: any,
  ) => void;
  loadPickup: any;
  setLoadPickup: any;
  pickupDate: any;
  setPickupDate: any;
  phonePickup: string;
  setPhonePickup: any;
  statePickup: string;
  setStatePickup: any;
  changeSelectState: any;
  americaStates: string[];
  error: any;
};

const LoadsPickup: React.FC<LoadsDetailProps> = ({
  handleInput,
  handleInputTextArea,
  loadPickup,
  setLoadPickup,
  pickupDate,
  setPickupDate,
  phonePickup,
  setPhonePickup,
  statePickup,
  setStatePickup,
  changeSelectState,
  americaStates,
  error,
}) => {
  const [errZip, setErrZip] = useState<boolean>(false);

  useEffect(() => {
    if (error?.errors['pickup.zip']?.[0]) setErrZip(true);
  }, [error]);

  return (
    <>
      <LoadsBlock>
        <LoadsTitle>Pickup Information</LoadsTitle>
        <LoadsInputField>
          <LoadsLabel htmlFor="businessNamePickup">Business Name</LoadsLabel>
          <LoadsInput
            id="businessNamePickup"
            name="businessNamePickup"
            type="text"
            style={{
              border: '1px solid #c6c7da',
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadPickup, 'businessNamePickup', loadPickup)
            }
            value={loadPickup.businessNamePickup}
          />
        </LoadsInputField>
        <LoadsInputField>
          <LoadsLabel htmlFor="streetAddressPickup">Street Address</LoadsLabel>
          <LoadsInput
            id="streetAddressPickup"
            name="streetAddressPickup"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadPickup, 'streetAddressPickup', loadPickup)
            }
            value={loadPickup.streetAddressPickup}
          />

        </LoadsInputField>
        <LoadsFlex>
          <LoadsInputFieldThird>
            <LoadsLabel htmlFor="cityPickup">City</LoadsLabel>
            <LoadsInput
              id="cityPickup"
              name="cityPickup"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadPickup, 'cityPickup', loadPickup)
              }
              value={loadPickup.cityPickup}
            />
          </LoadsInputFieldThird>
          <LoadsInputFieldThird>
            <LoadsLabel htmlFor="statePickup">State</LoadsLabel>
            <SelectedValue>{statePickup}</SelectedValue>
            <FieldIcon style={{ top: '95%' }}>
              <SelectArrow />
            </FieldIcon>
            <LoadsSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                changeSelectState(e, setStatePickup, setStatePickup)
              }
            >
              <Opt hidden></Opt>
              {americaStates?.map((item: any) => (
                <Opt key={item} value={item} data-value={item}>
                  {item}
                </Opt>
              ))}
            </LoadsSelect>
          </LoadsInputFieldThird>
          <LoadsInputFieldThird>
            <LoadsLabel htmlFor="zipPickup">ZIP</LoadsLabel>
            <LoadsInput
              id="zipPickup"
              name="zipPickup"
              type="number"
              style={{
                border: errZip ? '1px solid #ff0000' : '1px solid #c6c7da',
              }}
              onFocus={() => setErrZip(false)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadPickup, 'zipPickup', loadPickup)
              }
              value={loadPickup.zipPickup}
            />
            {errZip && (
              <Error>{error?.errors['pickup.zip']?.[0]}</Error>
            )}
          </LoadsInputFieldThird>
        </LoadsFlex>
        <LoadsInputField>
          <LoadsLabel htmlFor="pickupDate">Scheduled Pickup Date</LoadsLabel>
          <FieldIcon>
            <Calendar />
          </FieldIcon>
          <LoadsInputDate
            id="pickupDate"
            dateFormat="MM/dd/yyyy"
            selected={pickupDate}
            onChange={(date: any) => setPickupDate(date)}
          />
        </LoadsInputField>
        <LoadsInputField>
          <LoadsLabel htmlFor="buyerNumberPickup">Buyer Number</LoadsLabel>
          <LoadsInput
            id="buyerNumberPickup"
            name="buyerNumberPickup"
            type="number"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadPickup, 'buyerNumberPickup', loadPickup)
            }
            value={loadPickup.buyerNumberPickup}
          />
        </LoadsInputField>
        <LoadsInputField>
          <LoadsLabel htmlFor="notesPickup">Notes</LoadsLabel>
          <LoadsInputTextArea
            id="notesPickup"
            name="notesPickup"
            rows={3}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              handleInputTextArea(e, setLoadPickup, 'notesPickup', loadPickup)
            }
            value={loadPickup.notesPickup}
          />
        </LoadsInputField>

        <LoadsText>Contact details</LoadsText>
        <LoadsInputField>
          <LoadsLabel htmlFor="fullNamePickup">Full Name</LoadsLabel>
          <LoadsInput
            id="fullNamePickup"
            name="fullNamePickup"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setLoadPickup, 'fullNamePickup', loadPickup)
            }
            value={loadPickup.fullNamePickup}
          />
        </LoadsInputField>

        <LoadsFlex>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="phonePickup">Phone</LoadsLabel>
            <InputDiv>
              <PhoneInput
                international
                limitMaxLength
                value={phonePickup}
                id="phonePickup"
                onChange={(value: string) => setPhonePickup(value || '')}
              />
            </InputDiv>
          </LoadsInputFieldHalf>
          <LoadsInputFieldHalf>
            <LoadsLabel htmlFor="emailPickup">Email</LoadsLabel>
            <LoadsInput
              id="emailPickup"
              name="emailPickup"
              type="email"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadPickup, 'emailPickup', loadPickup)
              }
              value={loadPickup.emailPickup}
            />
          </LoadsInputFieldHalf>
        </LoadsFlex>
      </LoadsBlock>
    </>
  );
};

export default LoadsPickup;
