import * as React from "react"
import { SVGProps } from "react"

type DataProps = {
  data? : string;
}

const Edit:React.FC<DataProps> = ({data}, props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 14.518v1.232h1.232l7-7.018L11.25 7.5l-7 7.018ZM3 14v3h3l8.25-8.268-3-3L3 14ZM12.5 4.5 15 2l3 3-2.5 2.5-3-3Zm1.768 0L15.5 5.732 16.232 5 15 3.768l-.732.732Z"
      fill={data ? data : "#616172"}
    />
  </svg>
)

export default Edit
