import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;

  @media screen and (max-width: 600px) {
    padding: 32px 16px;
  }
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 32px;
`;

export const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 4px;
`;

export const Input = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputDiv = styled.div`
  height: 37px;
  border: 1px solid #c6c7da;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 4px;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 25px;
  right: 0;
  padding: 10px;
`;

export const SelectedValue = styled.div`
  width: 100%;
  position: absolute;
  top: 25px;
  left: 0;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  min-height: 37px;
`;

export const Select = styled.select`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  outline: none;
  padding: 6px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
`;

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;
