import {
  Wrapper,
  StyledLink,
  MenuItem,
  Text,
  VerticalLine,
  Line,
} from './sidebar.style';
import {
  Reports,
  Loads,
  Wheel,
  Fleet,
  Dashboard,
  Trips,
  Contact,
  Tracking,
} from '../../assets/icons';

const Sidebar: React.FC = () => {
  return (
    <Wrapper>
      <StyledLink to="/dashboard">
        {({ isActive }: any) => (
          <>
            <MenuItem>
              <Dashboard isActive={isActive} />
              <Text
                style={{
                  color: isActive ? '#0E71EB' : '#09091a',
                  fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                  fontWeight: isActive ? 600 : 400,
                }}
              >
                Dashboard
              </Text>{' '}
            </MenuItem>
            {isActive && <VerticalLine />}
          </>
        )}
      </StyledLink>
      <StyledLink to="/loads">
        {({ isActive }: any) => (
          <>
            <MenuItem>
              <Loads isActive={isActive} />
              <Text
                style={{
                  color: isActive ? '#0E71EB' : '#09091a',
                  fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                  fontWeight: isActive ? 600 : 400,
                }}
              >
                Loads
              </Text>
            </MenuItem>{' '}
            {isActive && <VerticalLine />}
          </>
        )}
      </StyledLink>
      <StyledLink to="/trips">
        {({ isActive }: any) => (
          <>
            <MenuItem>
              <Trips isActive={isActive} />
              <Text
                style={{
                  color: isActive ? '#0E71EB' : '#09091a',
                  fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                  fontWeight: isActive ? 600 : 400,
                }}
              >
                Trips
              </Text>
            </MenuItem>{' '}
            {isActive && <VerticalLine />}
          </>
        )}
      </StyledLink>
      <StyledLink to="/tracking">
        {({ isActive }: any) => (
          <>
            <MenuItem>
              <Tracking isActive={isActive} />
              <Text
                style={{
                  color: isActive ? '#0E71EB' : '#09091a',
                  fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                  fontWeight: isActive ? 600 : 400,
                }}
              >
                Tracking
              </Text>
            </MenuItem>
            {isActive && <VerticalLine />}
          </>
        )}
      </StyledLink>
      <StyledLink to="/fleet">
        {({ isActive }: any) => (
          <>
            <MenuItem>
              <Fleet isActive={isActive} />
              <Text
                style={{
                  color: isActive ? '#0E71EB' : '#09091a',
                  fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                  fontWeight: isActive ? 600 : 400,
                }}
              >
                Fleet
              </Text>{' '}
            </MenuItem>
            {isActive && <VerticalLine />}
          </>
        )}
      </StyledLink>
      <Line />
      <StyledLink to="/contacts">
        {({ isActive }: any) => (
          <>
            <MenuItem>
              <Contact isActive={isActive} />
              <Text
                style={{
                  color: isActive ? '#0E71EB' : '#09091a',
                  fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                  fontWeight: isActive ? 600 : 400,
                }}
              >
                Contact
              </Text>{' '}
            </MenuItem>
            {isActive && <VerticalLine />}
          </>
        )}
      </StyledLink>
      <StyledLink to="/drivers">
        {({ isActive }: any) => (
          <>
            <MenuItem>
              <Wheel isActive={isActive} />
              <Text
                style={{
                  color: isActive ? '#0E71EB' : '#09091a',
                  fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                  fontWeight: isActive ? 600 : 400,
                }}
              >
                Drivers
              </Text>{' '}
            </MenuItem>
            {isActive && <VerticalLine />}
          </>
        )}
      </StyledLink>
      <StyledLink to="/reports">
        {({ isActive }: any) => (
          <>
            <MenuItem>
              <Reports isActive={isActive} />
              <Text
                style={{
                  color: isActive ? '#0E71EB' : '#09091a',
                  fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                  fontWeight: isActive ? 600 : 400,
                }}
              >
                Reports
              </Text>{' '}
            </MenuItem>
            {isActive && <VerticalLine />}
          </>
        )}
      </StyledLink>
    </Wrapper>
  );
};

export default Sidebar;
