import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Block,
  AuthWrapper,
  Title,
  TitleBlue,
  Info,
  AuthBlock,
  Error,
  InfoText,
  Agreement,
  AgreementBold,
  Footer,
} from './auth.style';
import { getUser, loginUser, verifyEmail } from '../../API/auth';
import Button from '../../components/Button/Button';
import Header from '../../components/Header/Header';
import Input from '../../components/Input/Input';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Preloader from "../Preloader/Preloader";

const Auth: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [err, setErr] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const { isLogin } = useAppSelector(state => state.auth);
  const [appLoading, setAppLoading] = useState(true);
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any) =>
    setState(e.target.value);

  const sentToLogin = async () => {
    const res: any = await dispatch(loginUser({ email, password }));
    if (res.type === 'auth/loginUser/rejected') setErr(res.payload?.message);
  };

  const goToForgotPass = () => {
    navigation('/reset-password');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const verifyJWT = async () => {
        const res: any = await dispatch(getUser(null));
        if (res.type === 'auth/getUser/fulfilled') {
          setAppLoading(false);
          navigation('/dashboard');
        }
    }
    if (token) {
        verifyJWT();
    }else{
      setAppLoading(false);
    }

  }, [isLogin]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryToken: any = queryParams.get('token');
    setToken(queryToken);

    if (token) dispatch(verifyEmail(token));
  }, [token]);

  if(appLoading) return <Preloader />
  return (
    <>
      {appLoading ? (<Preloader />) : (
          <>
            <Header />
            <Block>
              <AuthWrapper>
                <Title>
                  Sign In <TitleBlue>Carrier TMS</TitleBlue>
                </Title>
                <AuthBlock onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>)=> {
                  if(event.key === 'Enter') {
                    sentToLogin();
                  }
                }}>
                  <Input
                      inputId="email"
                      inputType="email"
                      label="Email"
                      handleInput={e => handleInput(e, setEmail)}
                      value={email}
                  />
                  <Input
                      inputId="password"
                      inputType="password"
                      label="Password"
                      handleInput={e => handleInput(e, setPassword)}
                      value={password}
                  />
                  <Error>{err}</Error>
                </AuthBlock>
                <Info>
                  <InfoText onClick={goToForgotPass}>Forgot password?</InfoText>
                  <InfoText>Contact support</InfoText>
                </Info>
                <Button
                    text="Sign in"
                    handleButton={sentToLogin}
                    borderRadius="8px"

                />
                <Agreement>
                  By signing in, you agree to Turbo Dispatch’s{' '}
                  <AgreementBold href="/terms" target="_blank">Terms of Service</AgreementBold> and{' '}
                  <AgreementBold href="/privacy-policy" target="_blank">Privacy Policy</AgreementBold>
                </Agreement>
              </AuthWrapper>

              <Footer>
                © {new Date().getFullYear()} Turbo dispatch. All rights reserved.
              </Footer>
            </Block>
          </>
      )}
    </>
  );
};

export default Auth;
