import { Content } from "./container.style";
import AuthHeader from "../components/Header/AuthHeader";
import Sidebar from "../components/Sidebar/Sidebar";

const Container:React.FC = ({children}) => {
    return (
        <>
            <AuthHeader />
            <Content>
            <Sidebar />
            {children}
            </Content>
        </>
    )
}

export default Container;