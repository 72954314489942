import moment from 'moment/moment';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import {
  Actions,
  ErrorContent,
  FieldIcon,
  InputDate,
  InputTextArea,
  InvoiceField,
  InvoiceFieldHalf,
  InvoiceInput,
  InvoiceLabel,
  MUISelect,
  Row,
} from './MarkAsPaidModal.style';
import api from '../../../../../API';
import { routes } from '../../../../../API/loads';
import { Calendar } from '../../../../../assets/icons';
import DrawerModal from '../../../../../layout/Drawer/Drawer';
import Button from '../../../../Button/Button';

type PaymentMethod = {
  id: number;
  slug: string;
  title: string;
};

type defaultErrorType = {
  message: string;
  errors: {
    [key: string]: string[];
  };
};

const paidAmountErrorMessage = 'The paid amount is invalid';
const maxPaidAmount = 100000;

const defaultErrorState: defaultErrorType = {
  message: '',
  errors: {
    date: [],
    driver_pay: [],
    method: [],
    paid_amount: [],
    price: [],
  },
};

type MarkAsPaidModalProps = {
  open: boolean;
  load: any;
  onSuccess: () => void;
  onClose: (status?: boolean) => void;
};

const MarkAsPaidModal: FC<MarkAsPaidModalProps> = ({ open, load, onSuccess, onClose }) => {
  const [reference, setReference] = useState('');
  const [paidAmount, setPaidAmount] = useState('');
  const [paymentName, setPaymentName] = useState();
  const [paymentTitle, setPaymentTitle] = useState();
  const [paidDate, setPaidDate] = useState('');
  const [notes, setNotes] = useState('');
  const [paymentMethods, setPayment_methods] = useState<Array<PaymentMethod>>([]);
  const [err, setErr] = useState(defaultErrorState);

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].label);
  };

  const handlePaidAmount = (e: ChangeEvent<HTMLInputElement>) => {
    handleClearErrorByKey({ errorKey: 'paid_amount' });
    if (Number(e.target.value) < maxPaidAmount) {
      setPaidAmount(e.target.value);
    }
  };
  const handleClearErrorByKey = ({ errorKey }) => {
    setErr((_prev) => ({
      ..._prev,
      errors: {
        ..._prev.errors,
        [errorKey]: [],
      },
    }));
  };

  useEffect(() => {
    api.get(`${routes.loadForData}`).then((res) => setPayment_methods(res?.data?.data?.payment_methods));
  }, []);

  const handleConfirm = async () => {
    const paymentMethod = paymentMethods.find((paymentMethod) => paymentMethod?.title === paymentTitle);

    if (!paidAmount) {
      return setErr((_prev) => ({
        ..._prev,
        errors: {
          ..._prev.errors,
          paid_amount: [paidAmountErrorMessage],
        },
      }));
    }

    api
      .post(`${routes.updatePayment}`, {
        price: parseFloat(load.payment.price),
        paid_amount: parseFloat(paidAmount),
        method: paymentMethod?.id.toString() ?? '0',
        notes: notes,
        date: moment(paidDate).format('DD.MM.YYYY'),
        reference_number: reference,
        load_id: load.id,
      })
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch((err) => setErr(err));
  };

  return (
    <DrawerModal open={open} header="Mark as Paid" onClose={onClose}>
      <Row>
        <InvoiceField>
          <InvoiceLabel htmlFor="invoiceID">Paid Amount</InvoiceLabel>
          <InvoiceInput
            id="paidAmount"
            type="number"
            isError={err.errors?.paid_amount?.[0]}
            onChange={handlePaidAmount}
            value={paidAmount}
          />
        </InvoiceField>
        <ErrorContent>{err.errors?.paid_amount?.[0]}</ErrorContent>
      </Row>
      <Row>
        <InvoiceField>
          <InvoiceLabel htmlFor="bol">Paid method</InvoiceLabel>
          <MUISelect
            label="Paid method"
            value={paymentName}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              changeSelectState(e, setPaymentTitle, setPaymentName);
              handleClearErrorByKey({ errorKey: 'method' });
            }}
          >
            {paymentMethods?.map((item: any) => (
              <option key={item.id} value={item.title} data-value={item.id}>
                {item.title}
              </option>
            ))}
          </MUISelect>
        </InvoiceField>
        <ErrorContent>{err.errors?.method?.[0]}</ErrorContent>
      </Row>
      <Row>
        <InvoiceField>
          <InvoiceLabel htmlFor="referenceNumber">Reference Number</InvoiceLabel>
          <InvoiceInput
            id="referenceNumber"
            type="number"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setReference(e.target.value);
            }}
            value={reference}
          />
        </InvoiceField>
      </Row>
      <Row>
        <InvoiceField>
          <InvoiceLabel htmlFor="invoiceDate">Invoice Date</InvoiceLabel>
          <FieldIcon style={{ top: '72%' }}>
            <Calendar />
          </FieldIcon>
          <InputDate
            id="invoiceDate"
            dateFormat="MM/dd/yyyy"
            selected={paidDate}
            onChange={(date: any) => {
              handleClearErrorByKey({ errorKey: 'date' });
              setPaidDate(date);
            }}
          />
        </InvoiceField>
        <ErrorContent>{err.errors?.date?.[0]}</ErrorContent>
      </Row>
      <Row>
        <InvoiceField>
          <InvoiceLabel htmlFor="invoiceDate">Payment notes</InvoiceLabel>
          <InputTextArea
            rows={8}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setNotes(e.target.value)}
            value={notes}
          />
        </InvoiceField>
      </Row>
      <InvoiceFieldHalf>
        <Actions>
          <Button text="Send" pad="12px 26px" handleButton={handleConfirm} />
        </Actions>
      </InvoiceFieldHalf>
    </DrawerModal>
  );
};

export default MarkAsPaidModal;
