import { Tabs } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-left: 32px;
  margin-top: 60px;
  overflow: hidden;

  @media screen and (max-width: 1125px) {
    width: 67%;
  }

  @media screen and (max-width: 820px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const Title = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-top: 11px;
  max-width: 430px;
`;

export const Block = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 32px;
`;

export const TabPanel = styled(Tabs)`
  margin-top: 30px;
  padding-top: 16px;
  margin-left: 8px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1466px) {
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }
`;

export const Tab = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  margin-right: 20px;
  padding: 0 12px 0;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const Count = styled.div`
  margin: 0 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  font-family: 'MontserratBold';
  font-weight: '700';
  font-size: 12px;
  text-transform: uppercase;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9eaf8;
  margin: 0 0 3px;
`;

export const BorderBottom = styled.div`
  position: absolute;
  bottom: -17px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1267cf;
`;

export const Item = styled.div`
  width: 100%;
  padding: 20px 22px 32px 22px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
`;
