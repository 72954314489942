import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-left: 32px;

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 425px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BackBlock = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #616172;

  @media screen and (max-width: 425px) {
    margin-bottom: 16px;
  }
`;

export const DownloadSample = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  text-decoration: none;

  @media screen and (max-width: 425px) {
    width: 100%;
    justify-content: flex-end;
  }
`;

export const Block = styled.div`
  margin-top: 32px;
  background-color: #fff;
  width: 100%;
  height: 345px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 16px;
    border: 1px dashed #c6c7da;
    border-radius: 8px;
  }
`;

export const InputFile = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const Browse = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #0e71eb;
  margin-bottom: 10px;
`;

export const TypeFile = styled.p`
font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #333443;

  @media screen and (max-width: 360px) {
    text-align: center;
    padding: 0 20px;
  }
`;

export const Errors = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ErrorTitle = styled.div`
  margin-top: 8px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #09091a;
`;

export const ErrorItem = styled.div`
  margin-top: 8px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #f30f00;
`;
