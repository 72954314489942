import React from 'react';

import { Wrapper, Block, Title } from './fields.style';
import Checkbox from '../../../../components/Checkbox/Checkbox';

type FieldsProps = {
  fields: any;
  setFields: any;
};

const Fields: React.FC<FieldsProps> = ({ fields, setFields }) => {
  return (
    <Wrapper>
      <Block>
        <Title>General Info</Title>
        <Checkbox
          title="Load ID"
          checkboxName="generalLoadID"
          checked={fields.generalLoadID}
          setChecked={() =>
            setFields({ ...fields, generalLoadID: !fields.generalLoadID })
          }
        />
        <Checkbox
          title="Internal Load ID"
          checkboxName="internalLoadID"
          checked={fields.internalLoadID}
          setChecked={() =>
            setFields({ ...fields, internalLoadID: !fields.internalLoadID })
          }
        />
        <Checkbox
          title="Dispatcher"
          checkboxName="dispatcher"
          checked={fields.dispatcher}
          setChecked={() =>
            setFields({ ...fields, dispatcher: !fields.dispatcher })
          }
        />
        <Checkbox
          title="Trip"
          checkboxName="trip"
          checked={fields.trip}
          setChecked={() => setFields({ ...fields, trip: !fields.trip })}
        />
        <Checkbox
          title="Has Terminal"
          checkboxName="hasTerminal"
          checked={fields.hasTerminal}
          setChecked={() =>
            setFields({ ...fields, hasTerminal: !fields.hasTerminal })
          }
        />
        <Checkbox
          title="Dispatched to Carrier"
          checkboxName="dispatchToCarrier"
          checked={fields.dispatchToCarrier}
          setChecked={() =>
            setFields({
              ...fields,
              dispatchToCarrier: !fields.dispatchToCarrier,
            })
          }
        />

        <Title style={{ marginTop: '26px' }}>Pickup Info</Title>
        <Checkbox
          title="Pickup Name"
          checkboxName="pickupName"
          checked={fields.pickupName}
          setChecked={() =>
            setFields({ ...fields, pickupName: !fields.pickupName })
          }
        />
        <Checkbox
          title="Pickup Address"
          checkboxName="pickupAddress"
          checked={fields.pickupAddress}
          setChecked={() =>
            setFields({ ...fields, pickupAddress: !fields.pickupAddress })
          }
        />
        <Checkbox
          title="Pickup Date"
          checkboxName="pickupDate"
          checked={fields.pickupDate}
          setChecked={() =>
            setFields({ ...fields, pickupDate: !fields.pickupDate })
          }
        />
        <Checkbox
          title="Actual Pickup Date"
          checkboxName="actualPickupDate"
          checked={fields.actualPickupDate}
          setChecked={() =>
            setFields({ ...fields, actualPickupDate: !fields.actualPickupDate })
          }
        />
        <Checkbox
          title="Buyer Number"
          checkboxName="buyerNumber"
          checked={fields.buyerNumber}
          setChecked={() =>
            setFields({ ...fields, buyerNumber: !fields.buyerNumber })
          }
        />
      </Block>
      <Block>
        <Title>Vehicle Info</Title>
        <Checkbox
          title="Load ID"
          checkboxName="vehicleLoadID"
          checked={fields.vehicleLoadID}
          setChecked={() =>
            setFields({ ...fields, vehicleLoadID: !fields.vehicleLoadID })
          }
        />
        <Checkbox
          title="VIN #"
          checkboxName="vin"
          checked={fields.vin}
          setChecked={() => setFields({ ...fields, vin: !fields.vin })}
        />

        <Title style={{ marginTop: '26px' }}>Delivery Info</Title>
        <Checkbox
          title="Delivery Name"
          checkboxName="deliveryName"
          checked={fields.deliveryName}
          setChecked={() =>
            setFields({ ...fields, deliveryName: !fields.deliveryName })
          }
        />
        <Checkbox
          title="Delivery Address"
          checkboxName="deliveryAddress"
          checked={fields.deliveryAddress}
          setChecked={() =>
            setFields({ ...fields, deliveryAddress: !fields.deliveryAddress })
          }
        />
        <Checkbox
          title="Actual Delivery Date"
          checkboxName="deliveryDate"
          checked={fields.deliveryDate}
          setChecked={() =>
            setFields({ ...fields, deliveryDate: !fields.deliveryDate })
          }
        />
        <Checkbox
          title="Scheduled Delivery Date"
          checkboxName="scheduledDeliveryDate"
          checked={fields.scheduledDeliveryDate}
          setChecked={() =>
            setFields({
              ...fields,
              scheduledDeliveryDate: !fields.scheduledDeliveryDate,
            })
          }
        />
        <Checkbox
          title="Delivery Notes"
          checkboxName="deliveryNotes"
          checked={fields.deliveryNotes}
          setChecked={() =>
            setFields({ ...fields, deliveryNotes: !fields.deliveryNotes })
          }
        />

        <Title style={{ marginTop: '26px' }}>Driver Info</Title>
        <Checkbox
          title="Driver"
          checkboxName="deliveryDriver"
          checked={fields.deliveryDriver}
          setChecked={() =>
            setFields({ ...fields, deliveryDriver: !fields.deliveryDriver })
          }
        />
      </Block>
    </Wrapper>
  );
};

export default Fields;
