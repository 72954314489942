import React from 'react';

import { Block, Label, InputField } from './input.style';

type InputProps = {
  inputType: string;
  label: string;
  inputId: string;
  value: string;
  handleInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Input: React.FC<InputProps> = ({
  inputType,
  label,
  inputId,
  handleInput,
  value,
}) => {
  return (
    <Block>
      <Label htmlFor={`${inputId}`}>{label}</Label>
      <InputField
        id={`${inputId}`}
        type={`${inputType}`}
        onChange={handleInput}
        value={value}
      />
    </Block>
  );
};

export default Input;
