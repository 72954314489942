import React, { ChangeEvent, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Block,
  UsdotBlock,
  UsdotTitle,
  UsdotNumber,
  InputField,
  InputFieldHalf,
  InputDiv,
  FlexBetween,
  Label,
  Input,
  InputTextArea,
  Error,
  SelectedValue,
  FieldIcon,
  Select,
  Opt,
  Text,
  TopTitle,
  Title,
  Delete,
  ImagePlace,
  InputFilePlace,
  InputPlace,
  InputFile,
  BlueButton,
  Bottom,
  ButtonSave,
} from './carrierInformation.style';
import americaStates from '../../../americaStates.json';
import {
  CarrierProfile,
  getCarrierProfile,
  saveCarrierInformation,
} from '../../../API/carrierProfile';
import { SelectArrow } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import countries from '../../../countries.json';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

type CarrierProps = {
  data: CarrierProfile;
};

const CarrierInformation: React.FC<CarrierProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state: { auth: any }) => state.auth);
  const [imageFile, setImageFile] = useState<File | string>('');
  const [image, setImage] = useState<string>(
    data?.carrier_logo ? data?.carrier_logo : '',
  );
  const [carrierName, setCarrierName] = useState<string>(
    data?.carrier_name ? data?.carrier_name : '',
  );
  const [carrierAddress, setCarrierAddress] = useState<string>(
    data?.carrier_name ? data?.carrier_name : '',
  );

  const [billingContactName, setBillingContactName] = useState<string>(
    data?.billing_contact_name ? data?.billing_contact_name : '',
  );
  const [billingPhoneNumbers, setBillingPhoneNumbers] = useState<string>(
    data?.billing_phone_numbers ? data?.billing_phone_numbers : '',
  );
  const [billingEmail, setBillingEmail] = useState<string>(
    data?.billing_email ? data?.billing_email : '',
  );
  const [billingTerms, setBillingTerms] = useState<string>(
    data?.billing_terms ? data?.billing_terms : '',
  );

  const [mcNumber, setMcNumber] = useState<string>(
    data?.carrier_mc_number ? data?.carrier_mc_number : '',
  );

  const [city, setCity] = useState<string>(
    data?.carrier_city ? data?.carrier_city : '',
  );

  const [zip, setZip] = useState<string>(
    data?.carrier_zip ? data?.carrier_zip : '',
  );

  const [carrierEmail, setCarrierEmail] = useState<string>(
    data?.carrier_email ? data?.carrier_email : '',
  );

  const [website, setWebsite] = useState<string>(
    data?.carrier_website ? data?.carrier_website : '',
  );

  const [carrierFax, setCarrierFax] = useState<string>(
    data?.carrier_fax ? data?.carrier_fax : '',
  );

  const [carrierPhoneNumbers, setCarrierPhoneNumbers] = useState<string>(
    data?.carrier_phone_numbers ? data?.carrier_phone_numbers : '',
  );
  const [country, setCountry] = useState<string>(
    data?.carrier_country ? data?.carrier_country : countries[0],
  );

  const USStates = americaStates.slice(0, 50);
  const CanadaStates = americaStates.slice(51);

  const [carrierState, setCarrierState] = useState<string>(
    data?.carrier_state
      ? data?.carrier_state
      : country === 'United States'
      ? USStates[0]
      : CanadaStates[0],
  );

  const [err, setErr] = useState({
    message: '',
    errors: {
      carrier_name: [],
      carrier_address: [],
      carrier_city: [],
      carrier_zip: [],
      carrier_mc_number: [],
      carrier_phone_numbers: [],
      carrier_fax: [],
      carrier_email: [],
      billing_contact_name: [],
      billing_phone_numbers: [],
      billing_email: [],
    },
  });

  const handleConfirm = async () => {
    let formData = new FormData();
    {
      imageFile && formData.append(`carrier_logo`, imageFile);
    }
    formData.append(`carrier_name`, carrierName);
    formData.append(`carrier_address`, carrierAddress);
    formData.append(`carrier_state`, carrierState);
    formData.append(`carrier_city`, city);
    formData.append(`carrier_zip`, zip);
    formData.append(`carrier_country`, country);
    formData.append(`carrier_mc_number`, mcNumber);
    formData.append(`carrier_phone_numbers`, carrierPhoneNumbers);
    formData.append(`carrier_fax`, carrierFax);
    formData.append(`carrier_email`, carrierEmail);
    formData.append(`carrier_website`, website);
    formData.append(`billing_contact_name`, billingContactName);
    formData.append(`billing_phone_numbers`, billingPhoneNumbers);
    formData.append(`billing_email`, billingEmail);
    formData.append(`billing_terms`, billingTerms);
    const res: any = await dispatch(saveCarrierInformation(formData));
    if (res.type === 'carrierProfile/saveCarrierInformation/rejected')
      return setErr(res.payload);
    dispatch(getCarrierProfile(null));
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>, setState: any) => {
    setState(e.target.value);
  };

  const handleInputTextArea = (
    e: ChangeEvent<HTMLTextAreaElement>,
    setState: any,
  ) => {
    setState(e.target.value);
  };

  const changeSelectState = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: any,
  ) => {
    setState(e.target.value);
  };

  const handleChange = async (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    let file: File = (target.files as FileList)[0];
    if (file.size / 1024 / 1024 > 2) {
      alert('File is too big!');
    } else {
      const base64: any = await convertBase64(file);
      setImage(base64);
      setImageFile(file);
    }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  useEffect(() => {
    if (country === 'United States') {
      setCarrierState(USStates[0]);
    } else {
      setCarrierState(CanadaStates[0]);
    }
  }, [country]);

  return (
    <Block>
      <UsdotBlock>
        <UsdotTitle>USDOT number:</UsdotTitle>
        <UsdotNumber>{user.usdot}</UsdotNumber>
      </UsdotBlock>
      <InputField>
        <Label htmlFor="carrier_name">Carrier name *</Label>
        <Input
          id="carrier_name"
          type="text"
          style={{
            border: err.errors?.carrier_name?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({ ...err, errors: { ...err.errors, carrier_name: [] } })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCarrierName(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
          }
          value={carrierName}
        />
        <Error style={{ bottom: '-17px' }}>
          {err.errors?.carrier_name?.[0]}
        </Error>
      </InputField>
      <InputField>
        <Label htmlFor="address">Address *</Label>
        <InputTextArea
          id="address"
          name="address"
          rows={3}
          style={{
            border: err.errors?.carrier_address?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({ ...err, errors: { ...err.errors, carrier_address: [] } })
          }
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleInputTextArea(e, setCarrierAddress)
          }
          value={carrierAddress}
        />
        <Error style={{ bottom: '-17px' }}>
          {err.errors?.carrier_address?.[0]}
        </Error>
      </InputField>
      <InputField>
        <Label htmlFor="state">State *</Label>
        <SelectedValue>{carrierState}</SelectedValue>
        <FieldIcon>
          <SelectArrow />
        </FieldIcon>
        <Select
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            changeSelectState(e, setCarrierState)
          }
        >
          <Opt hidden></Opt>
          {country === 'United States'
            ? USStates?.map((item: any) => (
                <Opt key={item} value={item}>
                  {item}
                </Opt>
              ))
            : CanadaStates?.map((item: any) => (
                <Opt key={item} value={item}>
                  {item}
                </Opt>
              ))}
        </Select>
      </InputField>
      <FlexBetween>
        <InputFieldHalf>
          <Label htmlFor="city">City *</Label>
          <Input
            id="city"
            type="text"
            style={{
              border: err.errors?.carrier_city?.[0]
                ? '1px solid #ff0000'
                : '1px solid #c6c7da',
            }}
            onFocus={() =>
              setErr({ ...err, errors: { ...err.errors, carrier_city: [] } })
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCity(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
            }
            value={city}
          />
          <Error style={{ bottom: '-17px' }}>
            {err.errors?.carrier_city?.[0]}
          </Error>
        </InputFieldHalf>
        <InputFieldHalf>
          <Label htmlFor="zip">Zip *</Label>
          <Input
            id="zip"
            type="number"
            style={{
              border: err.errors?.carrier_zip?.[0]
                ? '1px solid #ff0000'
                : '1px solid #c6c7da',
            }}
            onFocus={() =>
              setErr({ ...err, errors: { ...err.errors, carrier_zip: [] } })
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setZip)
            }
            value={zip}
          />
          <Error style={{ bottom: '-17px' }}>
            {err.errors?.carrier_zip?.[0]}
          </Error>
        </InputFieldHalf>
      </FlexBetween>
      <InputField>
        <Label htmlFor="country">Country *</Label>
        <SelectedValue>{country}</SelectedValue>
        <FieldIcon>
          <SelectArrow />
        </FieldIcon>
        <Select
          id="country"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            changeSelectState(e, setCountry)
          }
        >
          <Opt hidden></Opt>
          {countries?.map((item: any) => (
            <Opt key={item} value={item}>
              {item}
            </Opt>
          ))}
        </Select>
      </InputField>
      <InputField>
        <Label htmlFor="mc_number">MC Number</Label>
        <Input
          id="mc_number"
          type="number"
          style={{
            border: err.errors?.carrier_mc_number?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({ ...err, errors: { ...err.errors, carrier_mc_number: [] } })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInput(e, setMcNumber)
          }
          value={mcNumber}
        />
        <Error style={{ bottom: '-17px' }}>
          {err.errors?.carrier_mc_number?.[0]}
        </Error>
      </InputField>
      <FlexBetween>
        <InputFieldHalf>
          <Label htmlFor="carrier_phone_numbers">Phone number *</Label>
          <InputDiv
            style={{
              border: err.errors?.carrier_phone_numbers[0]
                ? '1px solid #ff0000'
                : '1px solid #c6c7da',
            }}
            onFocus={() =>
              setErr({
                ...err,
                errors: { ...err.errors, carrier_phone_numbers: [] },
              })
            }
          >
            <PhoneInput
              international
              limitMaxLength
              value={carrierPhoneNumbers}
              id="carrier_phone_numbers"
              onChange={(value: string) => setCarrierPhoneNumbers(value || '')}
            />
          </InputDiv>
          <Error style={{ bottom: '-17px' }}>
            {err.errors?.carrier_phone_numbers?.[0]}
          </Error>
        </InputFieldHalf>
        <InputFieldHalf>
          <Label htmlFor="carrier_fax">Fax</Label>
          <Input
            id="carrier_fax"
            type="number"
            style={{
              border: err.errors?.carrier_fax?.[0]
                ? '1px solid #ff0000'
                : '1px solid #c6c7da',
            }}
            onFocus={() =>
              setErr({ ...err, errors: { ...err.errors, carrier_fax: [] } })
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setCarrierFax)
            }
            value={carrierFax}
          />
          <Error style={{ bottom: '-17px' }}>
            {err.errors?.carrier_fax?.[0]}
          </Error>
        </InputFieldHalf>
      </FlexBetween>
      <FlexBetween>
        <InputFieldHalf>
          <Label htmlFor="carrier_email">E-mail *</Label>
          <Input
            id="carrier_email"
            type="text"
            style={{
              border: err.errors?.carrier_email?.[0]
                ? '1px solid #ff0000'
                : '1px solid #c6c7da',
            }}
            onFocus={() =>
              setErr({ ...err, errors: { ...err.errors, carrier_email: [] } })
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setCarrierEmail)
            }
            value={carrierEmail}
          />
          <Error style={{ bottom: '-17px' }}>
            {err.errors?.carrier_email?.[0]}
          </Error>
        </InputFieldHalf>
        <InputFieldHalf>
          <Label htmlFor="carrier_website">Website</Label>
          <Input
            id="carrier_website"
            type="text"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setWebsite)
            }
            value={website}
          />
        </InputFieldHalf>
      </FlexBetween>
      <TopTitle>
        <Title>Logo</Title>
        {image && (
          <Delete
            onClick={() => {
              setImage('');
              setImageFile('');
            }}
          >
            Delete
          </Delete>
        )}
      </TopTitle>
      <ImagePlace>
        <InputFilePlace>
          {!image && (
            <InputFile
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={(event: React.ChangeEvent) => handleChange(event)}
            />
          )}
          <InputPlace src={image} />

          {!image && <BlueButton>Add logo</BlueButton>}
        </InputFilePlace>
      </ImagePlace>
      <Text>Contact information for Billing / Accounting</Text>
      <InputField>
        <Label htmlFor="billing_contact_name">Contact name</Label>
        <Input
          id="billing_contact_name"
          type="text"
          style={{
            border: err.errors?.billing_contact_name?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({
              ...err,
              errors: { ...err.errors, billing_contact_name: [] },
            })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setBillingContactName(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
          }
          value={billingContactName}
        />
        <Error style={{ bottom: '-17px' }}>
          {err.errors?.billing_contact_name?.[0]}
        </Error>
      </InputField>
      <FlexBetween>
        <InputFieldHalf>
          <Label htmlFor="billing_phone_numbers">Phone number</Label>
          <InputDiv
            style={{
              border: err.errors?.billing_phone_numbers[0]
                ? '1px solid #ff0000'
                : '1px solid #c6c7da',
            }}
            onFocus={() =>
              setErr({
                ...err,
                errors: { ...err.errors, billing_phone_numbers: [] },
              })
            }
          >
            <PhoneInput
              international
              limitMaxLength
              value={billingPhoneNumbers}
              id="billing_phone_numbers"
              onChange={(value: string) => setBillingPhoneNumbers(value || '')}
            />
          </InputDiv>
          <Error style={{ bottom: '-17px' }}>
            {err.errors?.billing_phone_numbers?.[0]}
          </Error>
        </InputFieldHalf>
        <InputFieldHalf>
          <Label htmlFor="billing_email">E-mail</Label>
          <Input
            id="billing_email"
            type="email"
            style={{
              border: err.errors?.billing_email?.[0]
                ? '1px solid #ff0000'
                : '1px solid #c6c7da',
            }}
            onFocus={() =>
              setErr({ ...err, errors: { ...err.errors, billing_email: [] } })
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleInput(e, setBillingEmail)
            }
            value={billingEmail}
          />
          <Error style={{ bottom: '-17px' }}>
            {err.errors?.billing_email?.[0]}
          </Error>
        </InputFieldHalf>
      </FlexBetween>
      <InputField>
        <Label htmlFor="billing_terms">Terms and Conditions</Label>
        <InputTextArea
          id="billing_terms"
          name="billing_terms"
          rows={3}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
            handleInputTextArea(e, setBillingTerms)
          }
          value={billingTerms}
        />
      </InputField>
      <Bottom>
        <ButtonSave>
          <Button
            text="Save Changes"
            pad="8px 24px"
            handleButton={handleConfirm}
          />
        </ButtonSave>
      </Bottom>
    </Block>
  );
};

export default CarrierInformation;
