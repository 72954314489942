import * as React from "react"
import { SVGProps } from "react"

type DataProps = {
  data?: string;
};

const EmailForm:React.FC<DataProps> = ({data},props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.083 5H4.917L10 8.916 15.083 5Zm-11.75.833V15h13.334V5.833L10 11.083l-6.667-5.25ZM2.5 16.666a.833.833 0 0 1-.833-.833V4.166c0-.46.373-.833.833-.833h15c.46 0 .833.373.833.833v11.667c0 .46-.373.833-.833.833h-15Z"
      fill={data ? '#616172' : '#C6C7DA'}
    />
  </svg>
)

export default EmailForm
