import React from 'react';

import { AddressSmall, CalendarSmall, PersonSmall } from '../../../../assets/icons';
import {
  DeliverText,
  DeliverTextEmpty,
  DeliveryFlex,
  FlexBetween,
  LoadsBlock,
  LoadsEdit,
  LoadsFlexColumn,
  LoadsNameSemiBold,
  LoadsTitle,
} from '../loadsItem.style';

type LoadsItemProps = {
  setCustomerModal?: () => void;
  loadInfo: any;
};

const LoadsItemCustomer: React.FC<LoadsItemProps> = ({ setCustomerModal, loadInfo }) => {
  const { phone: customerPhone, fax: customerFax } = loadInfo?.load?.customer ?? {};

  return (
    <>
      <LoadsBlock>
        <FlexBetween>
          <LoadsTitle>Customer Information</LoadsTitle>
          {setCustomerModal && (
            <LoadsEdit className="noPrint" onClick={setCustomerModal}>
              Edit
            </LoadsEdit>
          )}
        </FlexBetween>
        <LoadsFlexColumn>
          <DeliveryFlex>
            <AddressSmall />
            <LoadsNameSemiBold style={{ marginLeft: '4px', marginBottom: 0 }}>
              {loadInfo?.load?.customer?.business_name}
            </LoadsNameSemiBold>
          </DeliveryFlex>

          <DeliveryFlex>
            <CalendarSmall />
            <DeliverTextEmpty>
              {loadInfo?.load?.customer?.street_address +
                ', ' +
                loadInfo?.load?.customer?.state +
                ', ' +
                loadInfo?.load?.customer?.zip}
            </DeliverTextEmpty>
          </DeliveryFlex>

          <DeliveryFlex>
            <PersonSmall />
            <LoadsFlexColumn>
              <DeliverTextEmpty>
                {loadInfo?.load?.customer?.full_name ? loadInfo?.load?.customer?.full_name : 'No contact name'}
              </DeliverTextEmpty>
              <DeliverText>
                {`${customerPhone}, ${customerFax}`}
                {!customerPhone && !customerFax && 'No phone number'}
              </DeliverText>
            </LoadsFlexColumn>
          </DeliveryFlex>
        </LoadsFlexColumn>
      </LoadsBlock>
    </>
  );
};

export default LoadsItemCustomer;
