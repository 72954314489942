import moment from 'moment';
import React, { ChangeEvent, useState, useEffect } from 'react';

import {
  Wrapper,
  Block,
  BlockScroll,
  LoadsBlock,
  LoadsTitle,
  LoadsFlex,
  LoadsInputField,
  LoadsInputFieldHalf,
  SelectedValue,
  FieldIcon,
  LoadsSelect,
  Opt,
  LoadsInput,
  InputDate,
  LoadsLabel,
  InputTextArea,
  LoadsFlexColumn,
  LoadsImagePlace,
  LoadsInputFile,
  InputFile,
  BlueButton,
  DeliverTextEmpty,
  CheckBoxItem,
  CheckBoxSquare,
  CustomCheckBoxSquare,
  TextPrice,
  Line,
  Bottom,
  Actions,
  Cancel,
  Error,
} from './modalFleetExpense.style';
import { getDataForLoad } from '../../../../API/loads';
import { Calendar, Check, SelectArrow } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalExpensesProps = {
  close: () => void;
  refExpense: any;
};

const ModalFleetExpense: React.FC<ModalExpensesProps> = ({ close, refExpense }) => {
  const { loadInfo } = useAppSelector(
    (state: { loads: any }) => state.loads,
  );

  let formData: any = new FormData();

  const [err, setErr] = useState({
    message: '',
    errors: {
      price: [],
    },
  });

  const [loadsExpense, setLoadsExpense] = useState({
    price: '',
    specifyType: '',
  });

  const [priceSign, setPriceSign] = useState<boolean>(false);
  const [updateImages, setupdateImages] = useState<any[]>([]);

  const [receiptDateType, setReceiptDateType] = useState<Date>(new Date());

  const [invoice, setInvoice] = useState<boolean>(
    false,
  );
  const [driverPay, setDriverPay] = useState<boolean>(
    false,
  );

  const [expenseType, setExpenseType] = useState<string>(
    loadInfo && loadInfo?.expense_types?.[0]?.title,
  );
  const [expenseTypeId, setExpenseTypeId] = useState<number>(
    loadInfo && loadInfo?.expense_types?.[0]?.id,
  );

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: any,
    name: any,
    state: any,
  ) => {
    setState({ ...state, [name]: e.target.value });
  };

  const handleInputTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    setState: any,
    name: any,
    state: any,
  ) => setState({ ...state, [name]: e.target.value });

  const changeSelectState = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: any,
    setStateIndex?: any,
  ) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const handleCheckboxExpense = (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: any,
    elem: any,
  ) => {
    setState(!elem);
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = event.target.files;
    const arr = Array.from(file || []);

    arr.forEach((elem: any) => {
      if (elem.size / 1024 / 1024 > 2) {
        alert(`${elem.name} is too big`);
      } else {
        setupdateImages([...updateImages, elem]);
      }
    });
  };

  const dispatch = useAppDispatch();

  const handleConfirm = async () => {
    formData.append('price', loadsExpense.price);
    formData.append('expense_type_id', expenseTypeId);
    formData.append('date', moment(receiptDateType).format('DD.MM.YYYY'));
    formData.append(
      'specify_type',
      loadsExpense.specifyType ? loadsExpense.specifyType : '',
    );
    formData.append('invoice', invoice ? 1 : 0);
    formData.append('driver_pay', driverPay ? 1 : 0);
    updateImages.forEach((elem: any) => {
      return formData.append('images[]', elem);
    });

    //   const res: any = await dispatch(
    //     updateExpense([formData, loadId.id, elem.id]),
    //   );
    //   if (res.type === 'loads/updateExpense/rejected')
    //     return setErr(res.payload);
    //   await dispatch(getLoadData(loadId.id));
    //   close();
    
  };

  useEffect(() => {
    dispatch(getDataForLoad(null));
  }, []);
  
  return (
    <ModalPortal>
      <Wrapper ref={refExpense}>
        <LoadsBlock>
          <LoadsTitle>Add Expense</LoadsTitle>
          <Line />
          <Block>
            <BlockScroll>
              <LoadsInputField>
                <LoadsLabel htmlFor="expenseType">Type</LoadsLabel>
                <SelectedValue>{expenseType}</SelectedValue>
                <FieldIcon>
                  <SelectArrow />
                </FieldIcon>
                <LoadsSelect
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    changeSelectState(e, setExpenseType, setExpenseTypeId)
                  }
                >
                  <Opt hidden defaultValue></Opt>
                  {loadInfo?.expense_types?.map((item: any) => (
                    <Opt key={item.id} value={item.title} data-value={item.id}>
                      {item.title}
                    </Opt>
                  ))}
                </LoadsSelect>
              </LoadsInputField>
              <LoadsFlex>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="price">Price</LoadsLabel>
                  <TextPrice
                    style={{
                      color:
                        priceSign || loadsExpense.price ? '#09091A' : '#C6C7DA',
                    }}
                  >
                    $
                  </TextPrice>
                  <LoadsInput
                    id="price"
                    type="number"
                    style={{
                      border: err.errors?.price?.[0]
                        ? '1px solid #ff0000'
                        : '1px solid #c6c7da',
                      paddingLeft: '20px',
                    }}
                    onFocus={() => {
                      setErr({ ...err, errors: { ...err.errors, price: [] } });
                      setPriceSign(true);
                    }}
                    onBlur={() => setPriceSign(false)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInput(e, setLoadsExpense, 'price', loadsExpense)
                    }
                    value={loadsExpense.price}
                  />
                  <Error>{err.errors?.price?.[0]}</Error>
                </LoadsInputFieldHalf>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="receiptDateExpense">
                    Receipt Date
                  </LoadsLabel>
                  <FieldIcon>
                    <Calendar />
                  </FieldIcon>
                  <InputDate
                    id="receiptDateExpense"
                    dateFormat="MM/dd/yyyy"
                    selected={receiptDateType}
                    onChange={(date: any) => setReceiptDateType(date)}
                  />
                </LoadsInputFieldHalf>
              </LoadsFlex>
              <LoadsInputField>
                <LoadsLabel htmlFor="specifyType">Specify Type</LoadsLabel>
                <InputTextArea
                  id="specifyType"
                  rows={2}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    handleInputTextArea(
                      e,
                      setLoadsExpense,
                      'specifyType',
                      loadsExpense,
                    )
                  }
                  value={loadsExpense.specifyType}
                />
              </LoadsInputField>
              <LoadsImagePlace style={{ padding: '0 0 16px' }}>
                <LoadsInputFile style={{ marginBottom: '10px' }}>
                  <InputFile
                    type="file"
                    multiple
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleChange(event)
                    }
                  />
                  <LoadsFlexColumn>
                    <BlueButton>Upload Receipt File</BlueButton>
                    <DeliverTextEmpty>or Drag & Drop files</DeliverTextEmpty>
                  </LoadsFlexColumn>
                </LoadsInputFile>
              </LoadsImagePlace>
              <LoadsInputFieldHalf
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <CheckBoxItem>
                  <CheckBoxSquare
                    type="checkbox"
                    name="invoice"
                    id="invoice"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleCheckboxExpense(e, setInvoice, invoice)
                    }
                    checked={invoice}
                  />
                  <CustomCheckBoxSquare>
                    <Check right="3px" />
                  </CustomCheckBoxSquare>
                </CheckBoxItem>
                <LoadsLabel
                  htmlFor="invoice"
                  style={{ marginBottom: 0, marginLeft: '12px' }}
                >
                  Show expense on invoice
                </LoadsLabel>
              </LoadsInputFieldHalf>
              <LoadsInputFieldHalf
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <CheckBoxItem>
                  <CheckBoxSquare
                    type="checkbox"
                    name="driverPay"
                    id="driverPay"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleCheckboxExpense(e, setDriverPay, driverPay)
                    }
                    checked={driverPay}
                  />
                  <CustomCheckBoxSquare>
                    <Check right="3px" />
                  </CustomCheckBoxSquare>
                </CheckBoxItem>
                <LoadsLabel
                  htmlFor="driverPay"
                  style={{ marginBottom: 0, marginLeft: '12px' }}
                >
                  Deduct from driver pay
                </LoadsLabel>
              </LoadsInputFieldHalf>
            </BlockScroll>
          </Block>
        </LoadsBlock>

        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalFleetExpense;
