import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 32%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const TopText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  padding: 24px;
  text-align: center;
`;

export const Title = styled.span`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #F30F00;
`;

export const Terms = styled.ul`
  margin: 0 24px 24px;
  padding: 0 24px;
  text-align: center;
`;

export const TermsItem = styled.li`
  margin: 10px auto;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  line-height: 36px;
  color: #09091a;

  &::marker {
    font-size: x-small;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
`;

export const Actions = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #f4f4f4;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Button = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px 0;
  font-family: 'MontserratSemiBold';
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  color: #252736;
  cursor: pointer;
`;
