import * as React from 'react';
import { SVGProps } from 'react';

type DataProps = {
  data?: string;
};

const PasswordForm: React.FC<DataProps> = (
  { data },
  props: SVGProps<SVGSVGElement>,
) => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      d="M10 1.667a4.171 4.171 0 0 0-4.167 4.167v2.5H5c-.92 0-1.667.747-1.667 1.666v6.667c0 .92.748 1.667 1.667 1.667h10c.92 0 1.667-.748 1.667-1.667V10c0-.919-.748-1.666-1.667-1.666h-.833v-2.5A4.171 4.171 0 0 0 10 1.667ZM15 10l.002 6.667H5V10h10ZM7.5 8.334v-2.5c0-1.379 1.122-2.5 2.5-2.5s2.5 1.121 2.5 2.5v2.5h-5Z"
      fill={data ? '#616172' : '#C6C7DA'}
    />
  </svg>
);

export default PasswordForm;
