import * as React from 'react';
import { SVGProps } from 'react';

type IconProps = {
  isActive?: boolean;
};

const Trips: React.FC<IconProps> = ({ isActive }, props: SVGProps<SVGSVGElement>) => (
  <svg width={18} height={22} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8 2.6c.66 0 1.2.54 1.2 1.2 0 .66-.54 1.2-1.2 1.2-.66 0-1.2-.54-1.2-1.2 0-.66.54-1.2 1.2-1.2Zm0 4.8c-1.57 0-2.903-1-3.396-2.4H5.4a2.4 2.4 0 1 0 0 4.8h7.2a4.8 4.8 0 0 1 0 9.6H7.596A3.596 3.596 0 0 1 4.2 21.8a3.595 3.595 0 0 1-3.6-3.6c0-1.992 1.608-3.6 3.6-3.6 1.571 0 2.903 1 3.396 2.4H12.6a2.4 2.4 0 0 0 0-4.8H5.4a4.8 4.8 0 0 1 0-9.6h5.004A3.596 3.596 0 0 1 13.8.2c1.992 0 3.6 1.608 3.6 3.6s-1.608 3.6-3.6 3.6ZM5.4 18.2c0-.66-.54-1.2-1.2-1.2-.66 0-1.2.54-1.2 1.2 0 .66.54 1.2 1.2 1.2.66 0 1.2-.54 1.2-1.2Z"
      fill={isActive ? '#0E71EB' : '#9292A4'}
    />
  </svg>
);

export default Trips;
