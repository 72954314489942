import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 32px;

  /* @media screen and (max-width: 1024px) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 720px) {
    margin-left: 0;
  } */
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const Length = styled.span`
  color: #616172;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 20px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ImportBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;
`;

export const ImportText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  margin-left: 10px;
`;

export const DriverBlock = styled.div`
  width: 100%;
  margin-top: 32px;
  height: auto;
  display: flex;
  justify-content: space-between;

  /* @media screen and (max-width: 920px) {
    flex-direction: column;
    height: auto;
  } */
`;

export const ClientBlock = styled.div`
  width: 19%;
  min-width: 200px;
  height: 100%;
  border-radius: 8px;
  background-color: #fff;

  @media screen and (max-width: 1280px) {
    width: 36%;
  }
`;

export const ClientItem = styled.div`
  width: 78%;
  height: auto;
  border-radius: 8px;
  background-color: #fff;
  padding: 24px;

  @media screen and (max-width: 1280px) {
    width: 62%;
  }
`;
