import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import { addFleet, Fleet, FleetData, FleetItem, getFleet, getFleetType, updateFleet } from '../../API/fleet';

type InitialStateType = {
  fleetList: Fleet;
  fleetTypes: FleetData;
  loading: boolean;
  error: ErrorType | null;
  fleetInfo: FleetItem;
  fleetId: Object;
};

const initialState: InitialStateType = {
  fleetList: [],
  fleetTypes: [],
  loading: false,
  error: null,
  fleetInfo: {
    type: '',
    vin: '',
    plate: '',
    pass: '',
    state: '',
    make: '',
    model: '',
    year: '',
    color: '',
    owner: '',
    fleet_type_id: null,
    annual_inspection_at: '',
    plate_expired_at: '',
  },
  fleetId: {},
};

export const fleetSlice = createSlice({
  name: 'fleet',
  initialState,
  reducers: {
    showFleetInfo: (state, action) => {
      state.fleetInfo = action.payload;
    },
    showFleetId: (state, action) => {
        state.fleetId = action.payload;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getFleet.fulfilled, (state, action) => {
      state.loading = false;
      state.fleetList = action.payload;
    });
    builder.addCase(getFleet.pending, state => {
      state.loading = true;
    });
    builder.addCase(getFleet.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getFleetType.fulfilled, (state, action) => {
      state.loading = false;
      state.fleetTypes = action.payload;
    });
    builder.addCase(getFleetType.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(addFleet.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateFleet.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export const { showFleetInfo, showFleetId } = fleetSlice.actions;

export default fleetSlice.reducer;
