import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import { Dashboard, getDashboard } from '../../API/dashboard';

type InitialStateType = {
  dashboardData: Dashboard;
  loading: boolean;
  error: ErrorType | null;
  dashboardTab: string;
};

const initialState: InitialStateType = {
  dashboardData: {},
  loading: false,
  error: null,
  dashboardTab: 'To Pick Up',
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    showLoadsTab: (state, action) => {
      state.dashboardTab = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      state.loading = false;
      state.dashboardData = action.payload;
    });
    builder.addCase(getDashboard.pending, state => {
      state.loading = true;
    });
    builder.addCase(getDashboard.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export const { showLoadsTab } = dashboardSlice.actions;

export default dashboardSlice.reducer;
