import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import tripsTable from './tripsTable.json';
import { Active, MenuBlock, MenuItem, MenuItemRed } from './tripsTable.style';
import { changeTripStatus, getTrips } from '../../API/trips';
import { ThreeDots } from '../../assets/icons';
import ModalTripAction from '../../layout/Modal/ModalTrip/ModalTripAction';
import useOnClickOutside from '../../layout/Modal/useClickOutsideModal';
import { useAppDispatch } from '../../store/hooks';
import { getTripID } from '../../store/slices/tripsSlice';

type TripsTableProps = {
  tripsList: [];
  tab: string;
};

const TripsTable: React.FC<TripsTableProps> = ({ tripsList, tab }) => {
  const [open, setOpen] = useState<boolean>(false);
  const refTrips = useRef<any>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openEl = Boolean(anchorEl);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [tripId, setTripId] = useState<number>(0);

  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const ref = useRef<any>();
  const refActions = useRef<any>();

  const showDropdownMenu = (tripItem: any) => {
    setShowMenu(!showMenu);
    setTripId(tripItem.id);
  };

  const openModal = () => setOpen(true);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showMenu && ref.current && !ref.current.contains(e.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showMenu]);

  useOnClickOutside(refTrips, () => setOpen(false));

  const chooseType = (elem: any, tripItem: any) => {
    switch (elem.type) {
      case 'name':
        return (
          <div
            onClick={() => {
              navigation(`/trips/${tripItem.id}`);
              dispatch(getTripID({ id: tripItem.id }));
            }}
          >
            {tripItem[elem.array[0]]}
          </div>
        );
      case 'status':
        return (
          <Active
            style={{
              backgroundColor: tripItem[elem.array[0]] === 'archived' ? '#E9EAF8' : 'rgba(11, 170, 27, 0.15)',
              color: tripItem[elem.array[0]] === 'archived' ? '#9292A4' : '#0baa1b',
            }}
            onClick={() => {
              navigation(`/trips/${tripItem.id}`);
              dispatch(getTripID({ id: tripItem.id }));
            }}
          >
            {tripItem[elem.array[0]].replace('_', ' ')}
          </Active>
        );
      case 'loads':
        return (
          <div
            onClick={() => {
              navigation(`/trips/${tripItem.id}`);
              dispatch(getTripID({ id: tripItem.id }));
            }}
          >
            {tripItem[elem.array[0]].length + ' loads'}
          </div>
        );
      case 'driver':
        return (
          <div
            onClick={() => {
              navigation(`/trips/${tripItem.id}`);
              dispatch(getTripID({ id: tripItem.id }));
            }}
          >
            {tripItem[elem.array[0]]
              ? tripItem[elem.array[0]]?.first_name + ' ' + tripItem[elem.array[0]]?.last_name
              : '-'}
          </div>
        );
      case 'date':
        const correctDate = moment(tripItem[elem.array[0]]).format('YYYY/MM/DD');

        return (
          <div
            onClick={() => {
              navigation(`/trips/${tripItem.id}`);
              dispatch(getTripID({ id: tripItem.id }));
            }}
          >
            {correctDate}
          </div>
        );
      case 'actions':
        return (
          <div
            onClick={(event: any) => {
              showDropdownMenu(tripItem);
              refActions.current = tripItem.id;
              setAnchorEl(event.currentTarget);
            }}
          >
            <ThreeDots />
          </div>
        );
    }
  };

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'inherit' }}>
        <TableContainer sx={{ height: 'auto', overflow: 'auto' }}>
          <Table sx={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                {tripsTable[0]?.label?.map((elem: any) => (
                  <TableCell
                    key={elem}
                    sx={{
                      whiteSpace: 'nowrap',
                      padding: '15px',
                      fontFamily: 'MontserratBold',
                      fontWeight: 700,
                      fontSize: '12px',
                      color: '#616172',
                      textTransform: 'uppercase',
                    }}
                  >
                    {elem}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tripsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((tripItem: any, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={i}
                    sx={{
                      borderBottom: '1px solid #E2E2E2',
                    }}
                  >
                    {tripsTable[1]?.data?.map((elem: any, i: number) => {
                      return (
                        <TableCell
                          key={i}
                          sx={{
                            padding: '15px',
                            whiteSpace: 'nowrap',
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            fontSize: '16px',
                            color: '#09091A',
                            cursor: 'pointer',
                            display: tripItem.status === tab ? 'table-cell' : 'none',
                          }}
                        >
                          {typeof elem === 'object' ? chooseType(elem, tripItem) : tripItem[elem]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tripsList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <MenuBlock
        anchorEl={anchorEl}
        open={openEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {tab !== 'archived' && (
          <MenuItem
            onClick={async () => {
              await dispatch(changeTripStatus([tripId, 'archived']));
              await dispatch(getTrips(null));
              setAnchorEl(null);
            }}
          >
            Archive
          </MenuItem>
        )}
        <MenuItemRed
          onClick={() => {
            openModal();
            setAnchorEl(null);
          }}
        >
          Delete
        </MenuItemRed>
      </MenuBlock>

      {open && <ModalTripAction refTrips={refTrips} typeData="trip" tripId={tripId} close={() => setOpen(false)} />}
    </>
  );
};

export default TripsTable;
