import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Wrapper,
  TopText,
  Line,
  Bottom,
  Actions,
  Cancel,
  FleetNumber,
  FleetForm,
  FleetBlock,
  FleetField,
  SelectedValue,
  FleetSelect,
  FieldIcon,
  Opt,
  FleetLabel,
  FleetInput,
  InputDate,
  Error,
  PaperWorkBlock,
  InputField,
  FieldFlex,
  InputLabel,
  IconField,
  FilePlace,
  InputFilePlace,
  InputFile,
  FlexColumn,
  BlueButton,
  TextEmpty,
  DocumentName,
  DocumentText,
} from './modalFleet.style';
import americaStates from '../../../americaStates.json';
import { getDrivers } from '../../../API/drivers';
import { addFleet, getFleet, getFleetType, updateFleet } from '../../../API/fleet';
import { Calendar, MinusDriver, PlusDriver, SelectArrow, Document } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import { useAppSelector } from '../../../store/hooks';
import { showFleetInfo } from '../../../store/slices/fleetSlice';
import ModalPortal from '../ModalPortal';

type ModalContentProps = {
  close: () => void;
  text: string;
  addCheck?: boolean;
  trailerCheck?: string;
  refFleet: any;
};

const ModalFleet: React.FC<ModalContentProps> = ({ text, addCheck, trailerCheck, refFleet, close }) => {
  const [err, setErr] = useState({
    message: '',
    errors: {
      year: [],
      make: [],
      model: [],
      color: [],
      plate: [],
      state: [],
      plate_exp: [],
      vin: [],
      owner: [],
      annual: [],
      pass: [],
    },
  });

  const { fleetInfo, fleetList } = useAppSelector((state: { fleet: any }) => state.fleet);
  const { driversList } = useAppSelector((state: { driver: any }) => state.driver);

  const dispatch = useDispatch();
  const formattedDate = fleetInfo.plate_expired_at.split('.');
  const formattedDate2 = fleetInfo.annual_inspection_at.split('.');

  const [year, setYear] = useState<string>(addCheck ? '' : fleetInfo.year);
  const [make, setMake] = useState<string>(addCheck ? '' : fleetInfo.make);
  const [model, setModel] = useState<string>(addCheck ? '' : fleetInfo.model);
  const [color, setColor] = useState<string>(addCheck ? '' : fleetInfo.color);
  const [plate, setPlate] = useState<string>(addCheck ? '' : fleetInfo.plate);
  const [plateExp, setPlateExp] = useState<Date>(
    addCheck ? new Date() : new Date(`${formattedDate[0]}/${formattedDate[1]}/${formattedDate[2]}`)
  );
  const [annual, setAnnual] = useState<Date>(
    addCheck ? new Date() : new Date(`${formattedDate2[0]}/${formattedDate2[1]}/${formattedDate2[2]}`)
  );
  const [vin, setVin] = useState<string>(addCheck ? '' : fleetInfo.vin);
  const [owner, setOwner] = useState<string>(addCheck ? '' : fleetInfo.owner);
  const [driver, setDriver] = useState<string>(
    addCheck ? '' : fleetInfo.driver ? fleetInfo.driver?.first_name + ' ' + fleetInfo.driver?.last_name : ''
  );
  const [driverId, setDriverId] = useState<number>(addCheck ? null : fleetInfo.driver?.id);
  const [first_name, setFirst_name] = useState<string>(addCheck ? '' : fleetInfo.driver?.first_name);
  const [last_name, setLast_name] = useState<string>(addCheck ? '' : fleetInfo?.last_name);
  const [trailer, setTrailer] = useState<string>(addCheck ? '' : fleetInfo.trailer?.id);
  const [trailerId, setTrailerId] = useState<number>(addCheck ? null : fleetInfo.trailer?.id);
  const [pass, setPass] = useState<string>(addCheck ? '' : fleetInfo.pass);
  const [USState, setUSState] = useState<string>(addCheck ? americaStates[0] : fleetInfo.state);

  const [attachData, setAttachData] = useState({
    insurence: false,
    registration: false,
    contract: false,
  });

  const [insurenceFile, setinsurenceFile] = useState<File | string>('');
  const [insurenceName, setinsurenceName] = useState<string>('');
  const [registrationFile, setregistrationFile] = useState<File | string>('');
  const [registrationName, setregistrationName] = useState<string>('');
  const [contractFile, setContractFile] = useState<File | string>('');
  const [contractName, setContractName] = useState<string>('');

  const handleDriverFile = async (event: React.ChangeEvent, setState: any, setStatename: any) => {
    const target = event.target as HTMLInputElement;
    let file: File = (target.files as FileList)[0];
    if (file.size / 1024 / 1024 > 2) {
      alert('File is too big!');
    } else {
      setState(file);
      setStatename(file.name);
    }
  };

  const showAddData = (state: string) => {
    if (state === 'insurence') {
      setAttachData({
        ...attachData,
        insurence: !attachData.insurence,
      });
    } else if (state === 'registration') {
      setAttachData({ ...attachData, registration: !attachData.registration });
    } else {
      setAttachData({ ...attachData, contract: !attachData.contract });
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any) => setState(e.target.value);

  const changeSelectUSState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any) => {
    setState(e.target.value);
  };

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  useEffect(() => {
    setFirst_name(driver.split(' ').slice(0, -1).join(' '));
    setLast_name(driver.split(' ').slice(-1).join(' '));
  }, [driver]);

  const handleConfirm = async () => {
    if (addCheck) {
      if (trailerCheck === 'truck') {
        const res: any = await dispatch(
          addFleet({
            type: trailerCheck,
            vin: vin,
            plate: plate,
            pass: pass,
            state: USState,
            make: make,
            model: model,
            year: year,
            color: color,
            owner: owner,
            fleet_type_id: 1,
            trailer: {
              id: trailerId,
            },
            annual_inspection_at: moment(annual).format('YYYY-MM-DD'),
            plate_expired_at: moment(plateExp).format('YYYY-MM-DD'),
            driver: {
              id: driverId,
              first_name: first_name,
              last_name: last_name,
            },
            driver_id: +driverId ? +driverId : null,
            trailer_id: +trailerId ? +trailerId : null,
          })
        );

        if (res.type === 'fleets/addFleet/rejected') return setErr(res.payload);
      } else {
        const res: any = await dispatch(
          addFleet({
            type: trailerCheck,
            vin: vin,
            plate: plate,
            pass: pass,
            state: USState,
            make: make,
            model: model,
            year: year,
            color: color,
            owner: owner,
            fleet_type_id: 2,
            trailer: {
              id: trailerId,
            },
            annual_inspection_at: moment(annual).format('YYYY-MM-DD'),
            plate_expired_at: moment(plateExp).format('YYYY-MM-DD'),
            driver: {
              id: driverId,
              first_name: first_name,
              last_name: last_name,
            },
            driver_id: +driverId ? +driverId : null,
            trailer_id: +trailerId ? +trailerId : null,
          })
        );
        if (res.type === 'fleets/addFleet/rejected') return setErr(res.payload);
      }
    } else {
      if (fleetInfo.type === 0) {
        const res: any = await dispatch(
          updateFleet([
            {
              type: fleetInfo.type === 'truck' ? 'truck' : 'trailer',
              vin: vin,
              plate: plate,
              pass: pass,
              state: USState,
              make: make,
              model: model,
              year: year,
              color: color,
              owner: owner,
              fleet_type_id: 1,
              trailer: {
                id: trailerId,
              },
              annual_inspection_at: moment(annual).format('YYYY-MM-DD'),
              plate_expired_at: moment(plateExp).format('YYYY-MM-DD'),
              driver: {
                id: driverId,
                first_name: first_name,
                last_name: last_name,
              },
              driver_id: +driverId,
              trailer_id: +trailerId,
            },
            fleetInfo.id,
          ])
        );

        if (res.type === 'fleets/updateFleet/rejected') return setErr(res.payload);
      } else {
        const res: any = await dispatch(
          updateFleet([
            {
              type: fleetInfo.type === 'truck' ? 'truck' : 'trailer',
              vin: vin,
              plate: plate,
              pass: pass,
              state: USState,
              make: make,
              model: model,
              year: year,
              color: color,
              owner: owner,
              fleet_type_id: 2,
              trailer: {
                id: trailerId,
              },
              annual_inspection_at: moment(annual).format('YYYY-MM-DD'),
              plate_expired_at: moment(plateExp).format('YYYY-MM-DD'),
              driver: {
                id: driverId,
                first_name: first_name,
                last_name: last_name,
              },
              driver_id: +driverId,
              trailer_id: +trailerId,
            },
            fleetInfo.id,
          ])
        );
        if (res.type === 'fleets/updateFleet/rejected') return setErr(res.payload);
      }
      await dispatch(
        showFleetInfo({
          id: fleetInfo.id,
          type: fleetInfo.type === 'truck' ? 'truck' : 'trailer',
          vin: vin,
          plate: plate,
          pass: pass,
          state: USState,
          make: make,
          model: model,
          year: year,
          color: color,
          owner: owner,
          trailer: {
            id: trailerId,
          },
          annual_inspection_at: moment(annual).format('YYYY-MM-DD'),
          plate_expired_at: moment(plateExp).format('YYYY-MM-DD'),
          driver: {
            id: driverId,
            first_name: first_name,
            last_name: last_name,
          },
          driver_id: +driverId,
          trailer_id: +trailerId,
        })
      );
    }
    await dispatch(getFleet(null));
    close();
  };

  useEffect(() => {
    dispatch(getDrivers(null));
    dispatch(getFleetType(null));
  }, []);

  return (
    <ModalPortal>
      <Wrapper ref={refFleet}>
        <TopText>{text}</TopText>
        <Line />
        <FleetNumber>{addCheck ? `Truck number : Truck #1` : `Truck number : Truck #${fleetInfo.id}`}</FleetNumber>
        <FleetForm>
          <FleetBlock>
            <FleetField style={{ width: '32%' }}>
              <FleetLabel htmlFor="year">Year</FleetLabel>
              <FleetInput
                id="year"
                type="number"
                style={{
                  border: err.errors?.year?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, year: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setYear(e.target.value.slice(0, 4));
                }}
                value={year}
              />
              <Error>{err.errors?.year?.[0]}</Error>
            </FleetField>
            <FleetField style={{ width: '32%' }}>
              <FleetLabel htmlFor="make">Make</FleetLabel>
              <FleetInput
                id="make"
                type="text"
                style={{
                  border: err.errors?.make?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, make: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setMake(e.target.value.replace(/[^A-Za-z\s]/gi, ''))}
                value={make}
              />
              <Error>{err.errors?.make?.[0]}</Error>
            </FleetField>
            <FleetField style={{ width: '32%' }}>
              <FleetLabel htmlFor="model">Model</FleetLabel>
              <FleetInput
                id="model"
                type="text"
                style={{
                  border: err.errors?.model?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, model: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setModel)}
                value={model}
              />
              <Error>{err.errors?.model?.[0]}</Error>
            </FleetField>
          </FleetBlock>
          <FleetBlock>
            <FleetField style={{ width: '32%' }}>
              <FleetLabel htmlFor="color">Color</FleetLabel>
              <FleetInput
                id="color"
                type="text"
                style={{
                  border: err.errors?.color?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, color: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setColor(e.target.value.replace(/[^A-Za-z]/gi, ''))}
                value={color}
              />
              <Error>{err.errors?.color?.[0]}</Error>
            </FleetField>
            <FleetField style={{ width: '32%' }}>
              <FleetLabel htmlFor="plate">Plate</FleetLabel>
              <FleetInput
                id="plate"
                type="text"
                style={{
                  border: err.errors?.plate?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, plate: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setPlate)}
                value={plate}
              />
              <Error>{err.errors?.plate?.[0]}</Error>
            </FleetField>
            <FleetField style={{ width: '32%' }}>
              <FleetLabel htmlFor="state">State</FleetLabel>
              <SelectedValue>{USState}</SelectedValue>
              <FieldIcon>
                <SelectArrow />
              </FieldIcon>
              <FleetSelect
                id="state"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSelectUSState(e, setUSState)}
              >
                <Opt hidden></Opt>
                {americaStates.map((item) => (
                  <Opt key={item} value={item}>
                    {item}
                  </Opt>
                ))}
              </FleetSelect>
            </FleetField>
          </FleetBlock>
          <FleetBlock>
            <FleetField style={{ width: '49%' }}>
              <FleetLabel htmlFor="plate-exp">Plate exp</FleetLabel>
              <FieldIcon>
                <Calendar />
              </FieldIcon>
              <InputDate
                id="plate-exp"
                dateFormat="MM/dd/yyyy"
                selected={plateExp}
                onChange={(date: any) => setPlateExp(date)}
              />
            </FleetField>
            <FleetField style={{ width: '49%' }}>
              <FleetLabel htmlFor="vin">Vin #</FleetLabel>
              <FleetInput
                id="vin"
                type="text"
                style={{
                  border: err.errors?.vin?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, vin: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setVin)}
                value={vin}
              />
              <Error>{err.errors?.vin?.[0]}</Error>
            </FleetField>
          </FleetBlock>
          <FleetBlock>
            <FleetField style={{ width: '49%' }}>
              <FleetLabel htmlFor="owner">Owner</FleetLabel>
              <FleetInput
                id="owner"
                type="text"
                style={{
                  border: err.errors?.owner?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, owner: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setOwner(e.target.value.replace(/[^A-Za-z\s]/gi, ''))}
                value={owner}
              />
              <Error>{err.errors?.owner?.[0]}</Error>
            </FleetField>
            <FleetField style={{ width: '49%' }}>
              <FleetLabel htmlFor="driver">Driver</FleetLabel>
              <SelectedValue>{driver}</SelectedValue>
              <FieldIcon>
                <SelectArrow />
              </FieldIcon>
              <FleetSelect
                id="driver"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSelectState(e, setDriver, setDriverId)}
              >
                <Opt hidden defaultValue></Opt>
                {driversList.map((elem: any) => (
                  <Opt key={elem.id} value={elem.first_name + ' ' + elem.last_name} data-value={elem.id}>
                    {elem.first_name} {elem.last_name}
                  </Opt>
                ))}
              </FleetSelect>
            </FleetField>
          </FleetBlock>
          <FleetBlock>
            <FleetField style={{ width: '32%' }}>
              <FleetLabel htmlFor="annual">Annual INSP</FleetLabel>
              <FieldIcon>
                <Calendar />
              </FieldIcon>
              <InputDate
                id="annual"
                dateFormat="MM/dd/yyyy"
                selected={annual}
                onChange={(date: any) => setAnnual(date)}
              />
            </FleetField>
            <FleetField style={{ width: '32%' }}>
              <FleetLabel htmlFor="trailer">Trailer</FleetLabel>
              <SelectedValue>{trailer}</SelectedValue>
              <FieldIcon>
                <SelectArrow />
              </FieldIcon>
              <FleetSelect
                id="trailer"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSelectState(e, setTrailer, setTrailerId)}
              >
                <Opt hidden defaultValue></Opt>
                {fleetList
                  .filter((elem: any) => elem.type === 1)
                  .map((elem: any) => (
                    <Opt key={elem.id} value={`Trailer #${elem.id}`} data-value={elem.id}>
                      {`Trailer #${elem.id}`}
                    </Opt>
                  ))}
              </FleetSelect>
            </FleetField>
            <FleetField style={{ width: '32%' }}>
              <FleetLabel htmlFor="pass">Pass</FleetLabel>
              <FleetInput
                id="pass"
                type="number"
                style={{
                  border: err.errors?.pass?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, pass: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setPass)}
                value={pass}
              />
              <Error>{err.errors?.pass?.[0]}</Error>
            </FleetField>
          </FleetBlock>
          <PaperWorkBlock>PAPERWORK</PaperWorkBlock>
          <InputField>
            <FieldFlex>
              <InputLabel htmlFor="insurence">Insurence</InputLabel>
              <IconField onClick={() => showAddData('insurence')}>
                {attachData.insurence ? <MinusDriver /> : <PlusDriver />}
              </IconField>
            </FieldFlex>
            {attachData.insurence && (
              <>
                <FilePlace>
                  <InputFilePlace>
                    <InputFile
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleDriverFile(e, setinsurenceFile, setinsurenceName)
                      }
                    />
                    <FlexColumn>
                      <BlueButton>Upload attachments</BlueButton>
                      <TextEmpty>or drag and drop files</TextEmpty>
                    </FlexColumn>
                  </InputFilePlace>
                </FilePlace>
                {insurenceName && (
                  <DocumentName>
                    <Document />
                    <DocumentText>{insurenceName}</DocumentText>
                  </DocumentName>
                )}
              </>
            )}

            {/* <Error style={{ bottom: '-18px' }}>
                  {err.errors?.driver_license?.[0]}
                </Error> */}
          </InputField>
          <Line style={{ margin: '20px auto' }} />
          <InputField>
            <FieldFlex>
              <InputLabel htmlFor="registration">Registration</InputLabel>
              <IconField onClick={() => showAddData('registration')}>
                {attachData.registration ? <MinusDriver /> : <PlusDriver />}
              </IconField>
            </FieldFlex>
            {attachData.registration && (
              <>
                <FilePlace>
                  <InputFilePlace>
                    <InputFile
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleDriverFile(e, setregistrationFile, setregistrationName)
                      }
                    />
                    <FlexColumn>
                      <BlueButton>Upload attachments</BlueButton>
                      <TextEmpty>or drag and drop files</TextEmpty>
                    </FlexColumn>
                  </InputFilePlace>
                </FilePlace>
                {registrationName && (
                  <DocumentName>
                    <Document />
                    <DocumentText>{registrationName}</DocumentText>
                  </DocumentName>
                )}
              </>
            )}
            {/* <Error style={{ bottom: '-18px' }}>
                  {err.errors?.registration?.[0]}
                </Error> */}
          </InputField>
          <Line style={{ margin: '20px auto' }} />
          <InputField>
            <FieldFlex>
              <InputLabel htmlFor="contract">Contract</InputLabel>
              <IconField onClick={() => showAddData('contract')}>
                {attachData.contract ? <MinusDriver /> : <PlusDriver />}
              </IconField>
            </FieldFlex>
            {attachData.contract && (
              <>
                <FilePlace>
                  <InputFilePlace>
                    <InputFile
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleDriverFile(e, setContractFile, setContractName)
                      }
                    />
                    <FlexColumn>
                      <BlueButton>Upload attachments</BlueButton>
                      <TextEmpty>or drag and drop files</TextEmpty>
                    </FlexColumn>
                  </InputFilePlace>
                </FilePlace>
                {contractName && (
                  <DocumentName>
                    <Document />
                    <DocumentText>{contractName}</DocumentText>
                  </DocumentName>
                )}
              </>
            )}
            {/* <Error style={{ bottom: '-18px' }}>
                  {err.errors?.contract?.[0]}
                </Error> */}
          </InputField>
          <Line style={{ margin: '20px auto' }} />
        </FleetForm>
        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalFleet;
