import React, { MouseEventHandler } from 'react';

import { Drawer, DrawerModalContent, Header, Main } from './drawerModal.style';
import Close from '../../assets/icons/Close';

type DrawerModalProps = {
  children: React.ReactNode;
  open: boolean;
  header: string;
  onClose: (status?: boolean) => void;
};

const DrawerModal: React.FC<DrawerModalProps> = ({ children, open, header, onClose }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerModalContent>
        <Header>
          <div>{header}</div>
          <div onClick={onClose as any}>
            <Close />
          </div>
        </Header>
        <Main>{children}</Main>
      </DrawerModalContent>
    </Drawer>
  );
};

export default DrawerModal;
