import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const SelectField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CheckBoxBlock = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;

export const CheckBoxLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const CheckBox = styled.input`
  position: absolute;
  margin-right: 16px;

  & + label {
    position: relative;
    padding-right: 50px;
    line-height: 2em;
  }

  & + label::before {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 40px;
    height: 24px;
    border-radius: 16px;
    background: #fff;
    border: 1px solid #d9d9d9;
    transition: all 0.3s;
  }

  & + label::after {
    content: '';
    position: absolute;
    display: block;
    right: 20px;
    top: 0;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    background: #fff;
    border: 1px solid #d9d9d9;
    transition: all 0.3s;
  }

  &:checked + label::before {
    background: #55d069;
  }

  &:checked + label::after {
    margin-right: -20px;
  }
`;

export const SelectedValue = styled.div`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  height: 38px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 40px 0 3px;
  width: 100%;
`;

export const Wrapp = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
`;

export const Name = styled.div`
  display: inline;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background: #e9eaf8;
  border-radius: 4px;
  padding: 2px 7px;
  margin: 0 5px;
  white-space: nowrap;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 90%;
  transform: translateY(-80%);
  right: 0;
  padding: 10px;
`;

export const Dropdown = styled.div`
  position: absolute;
  top: 75px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 6px rgba(14, 26, 37, 0.25);
  padding: 12px 8px;
  width: 100%;
  z-index: 100;
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 16px;
  outline: none;
  margin-bottom: 20px;

  &:focus {
    border: 1px solid #0e71eb;
  }
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

export const CheckBoxItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const CustomCheckBoxSquare = styled.span`
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const CheckBoxSquare = styled.input`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 26px;
  height: 26px;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:checked + span {
    background-color: #0e71eb;
    border: 1px solid transparent;
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: -5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;