import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 20%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0;
`;

export const Title = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
`;

export const Close = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  margin: 12px auto;
  width: 95%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 16px;
  outline: none;
`;

export const ListWrapper = styled.div`
  height: 650px;
  overflow: auto;

  @media screen and (max-width: 600px) {
    height: 85%;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 20px;
  overflow: hidden;
  position: relative;
`;

export const Name = styled.p`
  padding: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #09091a;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
`;

export const Assign = styled.p`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #0e71eb;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
`;
