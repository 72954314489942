import * as React from "react"
import { SVGProps } from "react"

const Download = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      d="m14.166 12.3-1.458-1.556-1.648 1.778V3.333H8.977v9.189L7.29 10.744 5.833 12.3l4.185 4.366 4.148-4.367Z"
      fill="#616172"
    />
  </svg>
)

export default Download
