import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import {
  addDriver,
  Claims,
  createClaim,
  deleteClaim,
  deleteClaimAttachment,
  DriverItem,
  Drivers,
  getClaims,
  getDriver,
  getDrivers,
  updateClaim,
  updateDriver,
} from '../../API/drivers';

type InitialStateType = {
  driversList: Drivers;
  claims: Claims;
  loading: boolean;
  error: ErrorType | null;
  driverInfo: DriverItem;
  claimInfo: object;
};

const initialState: InitialStateType = {
  driversList: [],
  claims: [],
  loading: false,
  error: null,
  claimInfo: {},
  driverInfo: {
    first_name: '',
    last_name: '',
    status: '',
    password: '',
    password_confirmation: '',
    email: '',
    phone: '',
    image: '',
    birthday: '',
    deposit: 0,
    driver_type: '',
    truck_id: '',
    trailer_id: '',
    driver_license: '',
    medcard: '',
    contract: '',
  },
};

export const driversSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    showDriverInfo: (state, action) => {
      state.driverInfo = action.payload;
    },
    showClaimInfo: (state, action) => {
      state.claimInfo = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getDrivers.fulfilled, (state, action) => {
      state.loading = false;
      state.driversList = action.payload;
    });
    builder.addCase(getDrivers.pending, state => {
      state.loading = true;
    });
    builder.addCase(getDrivers.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getDriver.fulfilled, (state, action) => {
      state.loading = false;
      state.driverInfo = action.payload;
    });
    builder.addCase(getDriver.pending, state => {
      state.loading = true;
    });
    builder.addCase(getDriver.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getClaims.fulfilled, (state, action) => {
      state.loading = false;
      state.claims = action.payload;
      state.claims.sort((a: any, b: any) => b.id - a.id);
    });
    builder.addCase(getClaims.pending, state => {
      state.loading = true;
    });
    builder.addCase(getClaims.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(addDriver.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateDriver.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(createClaim.pending, state => {
      state.loading = true;
    });
    builder.addCase(createClaim.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateClaim.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateClaim.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(deleteClaim.pending, state => {
      state.loading = true;
    });
    builder.addCase(deleteClaim.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(deleteClaimAttachment.pending, state => {
      state.loading = true;
    });
    builder.addCase(deleteClaimAttachment.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export const { showDriverInfo, showClaimInfo } = driversSlice.actions;

export default driversSlice.reducer;
