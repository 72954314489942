import styled from 'styled-components';

export const AddTerminalBlock = styled.div`
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TerminalsList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const Info = styled.div`
margin: 10px 0;
  width: 45%;
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const Name = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #09091a;
`;

export const EditBlock = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Block = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`;

export const Label = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const Value = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-left: 8px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
  margin: 8px 0;
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;

  @media screen and (max-width: 600px) {
    text-align: center;
  }
`;

export const LearnMore = styled.span`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #0e71eb;
  line-height: 22px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    display: block;
  }
`;

export const NoTerminal = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #616172;
  margin-top: 32px;
`;

export const Actions = styled.div`
  max-width: 160px;
  margin: 27px auto 0;
`;
