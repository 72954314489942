import React from 'react';

import { Wrapper, Image, Close } from './viewFleetImage.style';
import { CloseIcon } from '../../../../assets/icons';
import ModalPortal from '../../ModalPortal';

type ViewFleetImageProps = {
  close: () => void;
  image: any;
};

const ViewFleetImage: React.FC<ViewFleetImageProps> = ({ close, image }) => {
  return (
    <ModalPortal>
      <Wrapper>
        <Image src={image} alt="images" />{' '}
        <Close onClick={close}>
          <CloseIcon />
        </Close>
      </Wrapper>
    </ModalPortal>
  );
};

export default ViewFleetImage;
