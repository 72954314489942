import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Wrapper, Top, BackBlock, TabPanel, Tab, BorderBottom, Line, Count } from './dashboardItem.style';
import { getLoads } from '../../../API/loads';
import { Back } from '../../../assets/icons';
import NewLoads from '../../../components/Loads/LoadsTab/LoadsList';
import Container from '../../../layout/Container';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Preloader from '../../Preloader/Preloader';

const DashboardItem: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { loadsList, loading } = useAppSelector((state: { loads: any }) => state.loads);

  const { dashboardTab } = useAppSelector((state: { dashboard: any }) => state.dashboard);

  const loadsDelayedDeliveries = loadsList?.filter((elem: any) => elem.status === 'Delayed deliveries');

  const loadsDelayedPickups = loadsList?.filter((elem: any) => elem.status === 'Delayed pickups');

  const [tabValue, setTabValue] = useState<number>(0);
  const [tab, setTab] = useState<string>(dashboardTab);

  useEffect(() => {
    if (dashboardTab === 'To Pick Up') setTabValue(0);
    else if (dashboardTab === 'To Deliver') setTabValue(1);
    else if (dashboardTab === 'Delayed deliveries') setTabValue(2);
    else setTabValue(3);
    dispatch(
      getLoads({
        type: 'load_id',
        sortBy: 'none',
      })
    );
  }, []);

  if (loading) return <Preloader />;

  const itemListContent = (tabKey: string) => {
    const listContent = ({ filterKey, emptyListLabel }) => {
      const filteredLoads = loadsList.filter((elem: any) => elem.status === filterKey);

      return (
        <NewLoads
          loadsList={filteredLoads}
          emptyList={emptyListLabel}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      );
    };

    switch (tabKey) {
      case 'To Pick Up': {
        return listContent({ filterKey: 'Picked up', emptyListLabel: 'To Pick Up' });
      }
      case 'To Deliver': {
        return listContent({ filterKey: 'Delivered', emptyListLabel: 'To Deliver' });
      }
      case 'Delayed deliveries': {
        return listContent({ filterKey: 'Delayed deliveries', emptyListLabel: 'Delayed deliveries' });
      }
      case 'Delayed pickups': {
        return listContent({ filterKey: 'Delayed pickups', emptyListLabel: 'Delayed pickups' });
      }
      default:
        return null;
    }
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <BackBlock onClick={() => navigate('/dashboard')}>
              <Back fill="#616172" /> Dashboard
            </BackBlock>
          </Top>

          <TabPanel variant="scrollable" value={tabValue} scrollButtons allowScrollButtonsMobile>
            <Tab
              style={{
                color: tab === 'To Pick Up' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setCurrentPage(1);
                setTab('To Pick Up');
                setTabValue(0);
              }}
            >
              To Pick Up
            </Tab>

            <Tab
              style={{
                color: tab === 'To Deliver' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setCurrentPage(1);
                setTab('To Deliver');
                setTabValue(1);
              }}
            >
              To Deliver
            </Tab>
            <Tab
              style={{
                color: tab === 'Delayed deliveries' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setCurrentPage(1);
                setTab('Delayed deliveries');
                setTabValue(2);
              }}
            >
              Delayed deliveries{' '}
              <Count
                style={{
                  color: tab === 'Delayed deliveries' ? '#0E71EB' : '#616172',
                  backgroundColor: tab === 'Delayed deliveries' ? '#B0DCFF' : '#e9eaf8',
                }}
              >
                {loadsDelayedDeliveries.length}
              </Count>
            </Tab>

            <Tab
              style={{
                color: tab === 'Delayed pickups' ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setCurrentPage(1);
                setTab('Delayed pickups');
                setTabValue(3);
              }}
            >
              Delayed pickups{' '}
              <Count
                style={{
                  color: tab === 'Delayed pickups' ? '#0E71EB' : '#616172',
                  backgroundColor: tab === 'Delayed pickups' ? '#B0DCFF' : '#e9eaf8',
                }}
              >
                {loadsDelayedPickups.length}
              </Count>
            </Tab>
          </TabPanel>
          <Line />

          {itemListContent(tab)}
        </Wrapper>
      </Container>
    </>
  );
};

export default DashboardItem;
