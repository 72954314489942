import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import { CarrierProfile, getCarrierProfile, saveCargoInsurance, saveCarrierInformation, saveUsdot, saveW9 } from '../../API/carrierProfile';

type InitialStateType = {
  data: CarrierProfile;
  loading: boolean;
  error: ErrorType | null;
};

const initialState: InitialStateType = {
  data: {
    carrier_name: '',
    carrier_address: '',
    carrier_state: '',
    carrier_city: '',
    carrier_zip: '',
    carrier_country: '',
    carrier_mc_number: '',
    carrier_contact_name: '',
    carrier_phone_numbers: '',
    carrier_fax: '',
    carrier_email: '',
    carrier_website: '',
    carrier_logo: '',
    billing_contact_name: '',
    billing_phone_numbers: '',
    billing_email: '',
    billing_terms: '',
    cargo_certificate: '',
    cargo_date: '',
    cargo_limit: '',
    cargo_deductible: '',
    cargo_auto_police_number: '',
    cargo_police_number: '',
    cargo_agent_name: '',
    cargo_agent_phone: '',
    w9: '',
    usdot_certificate: '',
  },
  loading: false,
  error: null,
};

export const carrierProfileSlice = createSlice({
  name: 'carrierProfile',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getCarrierProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      });
      builder.addCase(getCarrierProfile.pending, state => {
        state.loading = true;
      });
      builder.addCase(getCarrierProfile.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.loading = false;
      });
      builder.addCase(saveCarrierInformation.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.loading = false;
      });
      builder.addCase(saveCargoInsurance.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.loading = false;
      });
      builder.addCase(saveW9.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.loading = false;
      });
      builder.addCase(saveUsdot.rejected, (state, action) => {
        state.error = action.payload ? action.payload : null;
        state.loading = false;
      });
  },
});

export default carrierProfileSlice.reducer;
