import React, { ChangeEvent, useState } from 'react';

import {
  Wrapper,
  Title,
  Line,
  BlockScroll,
  LoadsFlex,
  InputFieldHalf,
  InputField,
  Label,
  Input,
  LoadsText,
  InputTextArea,
  CheckBoxBlock,
  CheckBoxItem,
  CheckBox,
  CustomCheckBox,
  CheckBoxTextBlock,
  CheckBoxTitle,
  CheckBoxSubtitle,
  Bottom,
  Actions,
  Cancel,
  Error,
} from './modalLoadDetails.style';
import { getLoadData, updateLoadDetails } from '../../../../API/loads';
import { Check } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  close: () => void;
  refDetails: any;
};

const ModalLoadDetails: React.FC<ModalAssignProps> = ({ close, refDetails }) => {
  const { loadId, loadInfo } = useAppSelector(
    (state: { loads: any }) => state.loads,
  );

  const [err, setErr] = useState({
    message: '',
    errors: {
      load_id: [],
      internal_load_id: [],
      inspection_type: [],
    },
  });

  const dispatch = useAppDispatch();

  const [loadID, setLoadID] = useState<string>(loadInfo?.load?.load_id);
  const [internalLoadID, setInternalLoadID] = useState<string>(
    loadInfo?.load?.internal_load_id,
  );
  const [inspectionType, setInspectionType] = useState<string>(
    loadInfo?.load?.inspection_type,
  );
  const [driverInstructions, setDriverInstructions] = useState<string>(
    loadInfo?.load?.driver_instructions
      ? loadInfo?.load?.driver_instructions
      : '',
  );

  const handleConfirm = async () => {
    const res: any = await dispatch(
      updateLoadDetails([
        {
          load_id: loadID,
          internal_load_id: internalLoadID,
          inspection_type: inspectionType.toLowerCase(),
          driver_instructions: driverInstructions,
        },
        loadId.id,
      ]),
    );
    if (res.type === 'loads/updateLoadDetails/rejected')
      return setErr(res.payload);
    await dispatch(getLoadData(loadId.id));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refDetails}>
        <Title>Load Details</Title>
        <Line />
        <BlockScroll>
        <LoadsFlex>
          <InputFieldHalf>
            <Label htmlFor="loadID">Load ID</Label>
            <Input
              id="loadID"
              name="loadID"
              type="text"
              style={{
                border: err.errors?.load_id?.[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, load_id: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setLoadID(e.target.value)
              }
              value={loadID}
            />
            {err.errors?.load_id && <Error>{err.errors?.load_id?.[0]}</Error>}
          </InputFieldHalf>
          <InputFieldHalf>
            <Label htmlFor="internalLoadID">Internal Load ID</Label>
            <Input
              id="internalLoadID"
              name="internalLoadID"
              type="text"
              style={{
                border: err.errors?.internal_load_id?.[0]
                  ? '1px solid #ff0000'
                  : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, internal_load_id: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setInternalLoadID(e.target.value)
              }
              value={internalLoadID}
            />
            {err.errors?.internal_load_id && <Error>{err.errors?.internal_load_id?.[0]}</Error>}
          </InputFieldHalf>
        </LoadsFlex>
        <Line />
        <LoadsText>Inspection Type</LoadsText>
        <CheckBoxBlock>
          <CheckBoxItem>
            <CheckBox
              id="type"
              type="radio"
              name="inspection"
              onClick={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, inspection_type: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setInspectionType(e.target.value)
              }
              value={'Standard'}
              checked={inspectionType === 'Standard'}
            />
            <CustomCheckBox>
              <Check />
            </CustomCheckBox>
            <CheckBoxTextBlock>
              <CheckBoxTitle>Standard</CheckBoxTitle>
              <CheckBoxSubtitle>
                Doesn’t have any required steps for drivers
              </CheckBoxSubtitle>
            </CheckBoxTextBlock>
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              id="type"
              type="radio"
              name="inspection"
              onClick={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, inspection_type: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setInspectionType(e.target.value)
              }
              value={'Advanced'}
              checked={inspectionType === 'Advanced'}
            />
            <CustomCheckBox>
              <Check />
            </CustomCheckBox>
            <CheckBoxTextBlock>
              <CheckBoxTitle>Advanced</CheckBoxTitle>
              <CheckBoxSubtitle>
                Requires the driver to take at least 6 photos at pickup and
                delivery
              </CheckBoxSubtitle>
            </CheckBoxTextBlock>
          </CheckBoxItem>
          <CheckBoxItem>
            <CheckBox
              id="type"
              type="radio"
              name="inspection"
              onClick={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, inspection_type: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setInspectionType(e.target.value)
              }
              value={'Aiag'}
              checked={inspectionType === 'Aiag'}
            />
            <CustomCheckBox>
              <Check />
            </CustomCheckBox>
            <CheckBoxTextBlock>
              <CheckBoxTitle>AIAG</CheckBoxTitle>
              <CheckBoxSubtitle>
                Used for OEM customers and requires 2 photos per damage
              </CheckBoxSubtitle>
            </CheckBoxTextBlock>
          </CheckBoxItem>
          <Error>{err.errors?.inspection_type?.[0]}</Error>
        </CheckBoxBlock>
        <InputField>
          <Label htmlFor="driverInstructions">Driver instructions</Label>
          <InputTextArea
            id="driverInstructions"
            name="driverInstructions"
            rows={3}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setDriverInstructions(e.target.value)
            }
            value={driverInstructions}
          />
        </InputField>
        </BlockScroll>
        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalLoadDetails;
