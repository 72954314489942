import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import { ActiveDrivers, DriverTrackingArray, getActiveDrivers } from '../../API/tracking';

type InitialStateType = {
  activeDriverList: ActiveDrivers;
  activeDriversTracking: DriverTrackingArray;
  loading: boolean;
  error: ErrorType | null;
};

const initialState: InitialStateType = {
  loading: false,
  error: null,
  activeDriverList: [],
  activeDriversTracking: [],
};

export const trackingSlice = createSlice({
  name: 'tracking',
  initialState,
  reducers: {
    setActiveDriversTracking: (state, action) => {
      state.activeDriversTracking = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getActiveDrivers.fulfilled, (state, action) => {
      state.loading = false;
      state.activeDriverList = action.payload;
    });
    builder.addCase(getActiveDrivers.pending, state => {
      state.loading = true;
    });
    builder.addCase(getActiveDrivers.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export const { setActiveDriversTracking } = trackingSlice.actions;

export default trackingSlice.reducer;
