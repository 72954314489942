import { Tabs } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 32px;
  margin-top: 60px;
  margin-bottom: 40px;

  @media screen and (max-width: 820px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const Title = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const TabPanel = styled(Tabs)`
  margin-top: 30px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1590px) {
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }
`;

export const Tab = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: '600';
  font-size: 20px;
  margin-right: 20px;
  padding: 0 12px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  margin: -5px 0 3px;
`;

export const BorderBottom = styled.div`
  position: absolute;
  bottom: -17px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1267cf;
`;

export const Item = styled.div`
  width: 100%;
  margin-top: 32px;
`;