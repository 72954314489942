import React from 'react';

import { Close, Document } from '../../../../assets/icons';
import {
  BlueButton,
  DeliverText,
  DeliverTextEmpty,
  DocumentName,
  FlexBetween,
  InputFile,
  LoadsAttachFile,
  LoadsBlock,
  LoadsFlexColumn,
  LoadsImagePlace,
  LoadsInputFile,
  LoadsTitle,
} from '../loadsItem.style';

type LoadsItemProps = {
  deleteAttachments: (id: number) => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadInfo: any;
};

const LoadsItemAttachments: React.FC<LoadsItemProps> = ({ deleteAttachments, handleChange, loadInfo }) => {
  const handleOpenDocument = (imageUrl: string) => {
    window.open(imageUrl, '_blank');
  };

  return (
    <LoadsBlock className="noPrint">
      <FlexBetween>
        <LoadsTitle>Attachments</LoadsTitle>
      </FlexBetween>
      <LoadsImagePlace style={{ padding: '16px 0 0' }}>
        {handleChange && (
          <LoadsInputFile>
            <InputFile
              type="file"
              multiple
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event)}
            />
            <LoadsFlexColumn>
              <BlueButton>Upload attachments</BlueButton>
              <DeliverTextEmpty>or drag and drop files</DeliverTextEmpty>
            </LoadsFlexColumn>
          </LoadsInputFile>
        )}
      </LoadsImagePlace>
      {loadInfo?.load?.attachments.map((elem: any) => {
        return (
          <LoadsAttachFile key={elem.id} clickable>
            <DocumentName onClick={() => handleOpenDocument(elem.path)}>
              <Document />
              <DeliverText>document({elem.id})</DeliverText>
            </DocumentName>
            <div onClick={() => deleteAttachments(elem.id)}>
              <Close />
            </div>
          </LoadsAttachFile>
        );
      })}
    </LoadsBlock>
  );
};

export default LoadsItemAttachments;
