import moment from 'moment';
import React, { ChangeEvent, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Block,
  BlueButton,
  Bottom,
  ButtonSave,
  Delete,
  Error,
  FieldIcon,
  ImagePlace,
  Input,
  InputDate,
  InputDiv,
  InputField,
  InputFile,
  InputFilePlace,
  InputPlace,
  Label,
  Title,
  TopTitle,
} from './cargoInsurance.style';
import {
  CarrierProfile,
  getCarrierProfile,
  saveCargoInsurance,
} from '../../../API/carrierProfile';
import { Calendar } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import { useAppDispatch } from '../../../store/hooks';

type CarrierProps = {
  data: CarrierProfile;
};

const CargoInsurance: React.FC<CarrierProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const formattedDate = data?.cargo_date?.split(".");
  const [imageFile, setImageFile] = useState<File | string>('');
  const [image, setImage] = useState<string>(
    data?.cargo_certificate ? data?.cargo_certificate : '',
  );
  const [expirationDate, setExpirationDate] = useState<Date>(
    data?.cargo_date ? new Date(`${formattedDate[2]}/${formattedDate[1]}/${formattedDate[0]}`) : new Date(),
  );
  const [cargoLimit, setCargoLimit] = useState<string>(
    data?.cargo_limit ? data?.cargo_limit : '',
  );
  const [deductible, setDeductible] = useState<string>(
    data?.cargo_deductible ? data?.cargo_deductible : '',
  );
  const [autoPolicyNumber, setAutoPolicyNumber] = useState<string>(
    data?.cargo_auto_police_number ? data?.cargo_auto_police_number : '',
  );
  const [cargoPolicyNumber, setCargoPolicyNumber] = useState<string>(
    data?.cargo_police_number ? data?.cargo_police_number : '',
  );
  const [agentName, setAgentName] = useState<string>(
    data?.cargo_agent_name ? data?.cargo_agent_name : '',
  );
  const [agentPhone, setAgentPhone] = useState<string>(
    data?.cargo_agent_phone ? data?.cargo_agent_phone : '',
  );

  const [err, setErr] = useState({
    message: '',
    errors: {
      cargo_limit: [],
      cargo_deductible: [],
      cargo_agent_name: [],
      cargo_agent_phone: [],
    },
  });

  const handleConfirm = async () => {
    const formData = new FormData();
    {
      imageFile && formData.append(`cargo_certificate`, imageFile);
    }
    formData.append(`cargo_date`, moment(expirationDate).format('DD.MM.YYYY'));
    formData.append(`cargo_limit`, cargoLimit);
    formData.append(`cargo_deductible`, deductible);
    formData.append(`cargo_auto_police_number`, autoPolicyNumber);
    formData.append(`cargo_police_number`, cargoPolicyNumber);
    formData.append(`cargo_agent_name`, agentName);
    formData.append(`cargo_agent_phone`, agentPhone);
    const res: any = await dispatch(saveCargoInsurance(formData));
    if (res.type === 'carrierProfile/saveCargoInsurance/rejected')
      return setErr(res.payload);
    dispatch(getCarrierProfile(null));
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>, setState: any) => {
    setState(e.target.value);
  };

  const handleChange = async (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    if (file.size / 1024 / 1024 > 2) {
      alert('File is too big!');
    } else {
      const base64: any = await convertBase64(file);
      setImage(base64);
      setImageFile(file);
    }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  return (
    <Block>
      <TopTitle>
        <Title>Certificate of Insurance</Title>
        {image && (
          <Delete
            onClick={() => {
              setImage('');
              setImageFile('');
            }}
          >
            Delete
          </Delete>
        )}
      </TopTitle>
      <ImagePlace>
        <InputFilePlace>
          {!image && (
            <InputFile
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={(event: React.ChangeEvent) => handleChange(event)}
            />
          )}
          <InputPlace src={image} />

          {!image && <BlueButton>Add Certificate</BlueButton>}
        </InputFilePlace>
      </ImagePlace>
      <InputField>
        <Label htmlFor="expirationDate">Expiration date *</Label>
        <FieldIcon>
          <Calendar />
        </FieldIcon>
        <InputDate
          id="expirationDate"
          dateFormat="MM/dd/yyyy"
          selected={expirationDate}
          onChange={(date: any) => setExpirationDate(date)}
        />
      </InputField>
      <InputField>
        <Label htmlFor="cargo_limit">Cargo limit *</Label>
        <Input
          id="cargo_limit"
          type="number"
          style={{
            border: err.errors?.cargo_limit?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({ ...err, errors: { ...err.errors, cargo_limit: [] } })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInput(e, setCargoLimit)
          }
          value={cargoLimit}
        />
        <Error style={{ bottom: '-17px' }}>
          {err.errors?.cargo_limit?.[0]}
        </Error>
      </InputField>
      <InputField>
        <Label htmlFor="deductible">Deductible *</Label>
        <Input
          id="deductible"
          type="number"
          style={{
            border: err.errors?.cargo_deductible?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({
              ...err,
              errors: { ...err.errors, cargo_deductible: [] },
            })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInput(e, setDeductible)
          }
          value={deductible}
        />
        <Error style={{ bottom: '-17px' }}>
          {err.errors?.cargo_deductible?.[0]}
        </Error>
      </InputField>
      <InputField>
        <Label htmlFor="autoPolicyNumber">Auto Policy Number</Label>
        <Input
          id="autoPolicyNumber"
          type="number"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInput(e, setAutoPolicyNumber)
          }
          value={autoPolicyNumber}
        />
      </InputField>
      <InputField>
        <Label htmlFor="cargoPolicyNumber">Cargo Policy Number</Label>
        <Input
          id="cargoPolicyNumber"
          type="number"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInput(e, setCargoPolicyNumber)
          }
          value={cargoPolicyNumber}
        />
      </InputField>
      <InputField>
        <Label htmlFor="agentName">Agent name *</Label>
        <Input
          id="agentName"
          type="text"
          style={{
            border: err.errors?.cargo_agent_name?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({
              ...err,
              errors: { ...err.errors, cargo_agent_name: [] },
            })
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAgentName(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
          }
          value={agentName}
        />
        <Error style={{ bottom: '-17px' }}>
          {err.errors?.cargo_agent_name?.[0]}
        </Error>
      </InputField>
      <InputField>
        <Label htmlFor="agentPhone">Agent phone *</Label>
        <InputDiv
          style={{
            border: err.errors?.cargo_agent_phone?.[0]
              ? '1px solid #ff0000'
              : '1px solid #c6c7da',
          }}
          onFocus={() =>
            setErr({
              ...err,
              errors: { ...err.errors, cargo_agent_phone: [] },
            })
          }
        >
          <PhoneInput
            international
            limitMaxLength
            value={agentPhone}
            id="agentPhone"
            onChange={(value: string) => setAgentPhone(value || '')}
          />
        </InputDiv>
        <Error>{err.errors?.cargo_agent_phone?.[0]}</Error>
      </InputField>
      <Bottom>
        <ButtonSave>
          <Button
            text="Save Changes"
            pad="8px 24px"
            handleButton={handleConfirm}
          />
        </ButtonSave>
      </Bottom>
    </Block>
  );
};

export default CargoInsurance;
