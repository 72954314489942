import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 30%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Steps = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 15px 5px;
`;

export const Step = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: '600';
  font-size: 16px;
  margin-right: 20px;
  padding: 0 12px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;

export const BorderBottom = styled.div`
  position: absolute;
  bottom: -17px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1267cf;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
`;

export const IntegrationStep = styled.div`
  width: 100%;
  padding: 24px;
  min-height: 160px;
`;

export const Text = styled.p`
  margin-bottom: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const ButtonBlock = styled.div`
  display: inline-flex;
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 28px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 8px;
`;
