import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export type Contact = [];

export type ContactItem = {
  id?: number;
  name: string;
  city: string;
  address: string;
  contact_name: string;
  email: string;
  phone: string;
  notes: string;
  fax: string;
  state: string;
  zip: string;
  image?: string;
};

export const routes = {
  contacts: 'api/crm/contacts',
  import: 'api/crm/contacts/import'
};

export const getContacts = createAsyncThunk<
  Contact,
  null,
  {
    rejectValue: ErrorType;
  }
>('contacts/getContacts', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.contacts}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const addContact = createAsyncThunk<
  null,
  ContactItem,
  {
    rejectValue: ErrorType;
  }
>('contacts/addContact', async (createData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.contacts}`, createData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateContact = createAsyncThunk<
  null,
  [ContactItem, number],
  {
    rejectValue: ErrorType;
  }
>('contacts/updateContact', async ([updateData, id], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.contacts}/${id}/update`, updateData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const deleteContact = createAsyncThunk<
  null,
  number,
  {
    rejectValue: ErrorType;
  }
>('contacts/deleteContact', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${routes.contacts}/${id}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const importContact = createAsyncThunk<
  null,
  object,
  {
    rejectValue: ErrorType;
  }
>('contacts/importContact', async (importData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.import}`, importData);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.data.message,
      errors: e.data.errors,
    } as ErrorType);
  }
});