import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DropdownMenu,
  LoadsItem,
  LoadsText,
  MenuItem,
} from './modalSendInvoice.style';
import { useAppDispatch } from '../../../store/hooks';
import { getLoadId } from '../../../store/slices/loadsSlice';

type LoadItemProps = {
  item: string;
  loads: string[];
  deleteLoadsFromList: (elem: any) => void;
};

const LoadItem: React.FC<LoadItemProps> = ({
  item,
  loads,
  deleteLoadsFromList,
}) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const menuRef = useRef<any>();
  const [showLoadsMenu, setShowLoadsMenu] = useState<boolean>(false);

  const viewDetails = async (item: string) => {
    await dispatch(getLoadId({ id: item }));
    navigation(`/loads/${item}`);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (
        showLoadsMenu &&
        menuRef.current &&
        !menuRef.current.contains(e.target)
      ) {
        setShowLoadsMenu(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showLoadsMenu]);

  return (
    <>
      <LoadsItem
        key={item}
        onClick={() => {
          if (loads.length > 1) setShowLoadsMenu(true);
        }}
      >
        <LoadsText>{item}</LoadsText>
        {showLoadsMenu && (
          <DropdownMenu ref={menuRef}>
            <MenuItem onClick={() => viewDetails(item)}>View details</MenuItem>
            <MenuItem
              onClick={() => deleteLoadsFromList(item)}
              style={{ color: '#F30F00' }}
            >
              Delete
            </MenuItem>
          </DropdownMenu>
        )}
      </LoadsItem>
    </>
  );
};

export default LoadItem;
