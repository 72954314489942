import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 32px;
  margin-top: 60px;

  @media screen and (max-width: 1280px) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 820px) {
    margin-left: 0;
  }
`;

export const Title = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const Block = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;

  @media screen and (max-width: 1125px) {
    flex-direction: column;
  }
`;

export const HelpCenterBlock = styled.div`
  width: 40%;
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  margin-right: 32px;

  @media screen and (max-width: 1125px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    padding: 16px;
  }
`;

export const HelpCenterContact = styled.div`
  width: 35%;
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  height: fit-content;

  @media screen and (max-width: 1330px) {
    width: 45%;
  }

  @media screen and (max-width: 1125px) {
    width: 100%;
    margin-top: 24px;
  }

  @media screen and (max-width: 425px) {
    padding: 16px;
  }
`;

export const BlockTitle = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  margin-bottom: 32px;
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

export const InputDiv = styled.div`
  height: 37px;
  border: 1px solid #c6c7da;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 4px;
`;

export const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 4px;
`;

export const Input = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  width: 100%;
`;

export const InputTextArea = styled.textarea`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  max-width: 100%;
  max-height: 148px;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const ContactBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const Value = styled.p`
  margin-left: 5px;
  margin-bottom: 4px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
`;

export const FlexEnd = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const Actions = styled.div`
  max-width: 90px;
`;
