import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';

import {
  Wrapper,
  Line,
  BlockScroll,
  LoadsTitle,
  LoadsFlex,
  LoadsInputField,
  LoadsInput,
  LoadsInputFieldHalf,
  LoadsInputTextArea,
  LoadsLabel,
  LoadsText,
  SelectedValue,
  FieldIcon,
  LoadsSelect,
  Opt,
  Bottom,
  Actions,
  Cancel,
  Error,
} from './paymentModal.style';
import { getLoadData, updatePayment } from '../../../../API/loads';
import { SelectArrow } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { resetError } from '../../../../store/slices/loadsSlice';
import ModalPortal from '../../ModalPortal';

const defaultErrorState = {
  message: '',
  errors: {
    price: [],
    driver_pay: [],
  },
};

type ModalAssignProps = {
  close: () => void;
  refPaymentModal: any;
};

const PaymentModal: React.FC<ModalAssignProps> = ({ close, refPaymentModal }) => {
  const { loadId, loadInfo, loadData, error } = useAppSelector((state: { loads: any }) => state.loads);
  const [err, setErr] = useState(defaultErrorState);

  useEffect(() => {
    if (error) {
      setErr(error);
    }

    return () => {
      dispatch(resetError());
    };
  }, error);

  const paymentMethodItem = loadData?.payment_methods.find((elem: any) => elem.title === loadInfo.load.payment.method);

  const [receiptDatePayment, setReceiptDatePayment] = useState<Date>(new Date(loadInfo.load.payment.date));
  const [methodPayment, setMethodPayment] = useState<string>(loadInfo.load.payment.method);
  const [methodPaymentId, setMethodPaymentId] = useState<string>(paymentMethodItem.id);

  const [loadsPayment, setLoadsPayment] = useState({
    paymentPrice: loadInfo.load.payment.price,
    brokerFeePayment: loadInfo.load.payment.broker_fee ? loadInfo.load.payment.broker_fee : '',
    driverPayPayment: loadInfo.load.payment.driver_pay,
    notePayment: loadInfo.load.payment.notes ? loadInfo.load.payment.notes : '',
    paidAmountPayment: loadInfo.load.payment.paid_amount ? loadInfo.load.payment.paid_amount : '',
    referenceNumberPayment: loadInfo.load.payment.reference_number ? loadInfo.load.payment.reference_number : '',
    invoiceIDPayment: loadInfo.load.payment.invoice_id ? loadInfo.load.payment.invoice_id : '',
    invoiceNotes: loadInfo.load.payment.invoice_notes ? loadInfo.load.payment.invoice_notes : '',
  });

  const dispatch = useAppDispatch();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any, name: any, state: any) => {
    setState({ ...state, [name]: e.target.value });
  };

  const handleInputTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>, setState: any, name: any, state: any) =>
    setState({ ...state, [name]: e.target.value });

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const handleConfirm = async () => {
    const res: any = await dispatch(
      updatePayment([
        {
          price: parseFloat(loadsPayment.paymentPrice),
          broker_fee: loadsPayment.brokerFeePayment,
          method: methodPaymentId,
          driver_pay: parseInt(loadsPayment.driverPayPayment),
          notes: loadsPayment.notePayment,
          paid_amount: loadsPayment.paidAmountPayment,
          date: moment(receiptDatePayment).format('DD.MM.YYYY'),
          reference_number: loadsPayment.referenceNumberPayment,
          invoice_id: loadsPayment.invoiceIDPayment,
          invoice_notes: loadsPayment.invoiceNotes,
        },
        loadId.id,
      ])
    );
    if (res.type === 'loads/updatePayment/rejected') return setErr(res.payload);
    await dispatch(getLoadData(loadId.id));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refPaymentModal}>
        <LoadsTitle>Payment</LoadsTitle>
        <BlockScroll>
          <LoadsFlex>
            <LoadsInputFieldHalf>
              <LoadsLabel htmlFor="paymentPrice">Price</LoadsLabel>
              <LoadsInput
                id="paymentPrice"
                type="number"
                style={{
                  border: err.errors?.price?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, price: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (Number(e.target.value) < 100000) {
                    handleInput(e, setLoadsPayment, 'paymentPrice', loadsPayment);
                  }
                }}
                value={loadsPayment.paymentPrice}
              />
              <Error>{err.errors?.price?.[0]}</Error>
            </LoadsInputFieldHalf>
            <LoadsInputFieldHalf>
              <LoadsLabel htmlFor="brokerFeePayment">Broker Fee</LoadsLabel>
              <LoadsInput
                id="brokerFeePayment"
                type="number"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (Number(e.target.value) < 100000) {
                    handleInput(e, setLoadsPayment, 'brokerFeePayment', loadsPayment);
                  }
                }}
                value={loadsPayment.brokerFeePayment}
              />
            </LoadsInputFieldHalf>
          </LoadsFlex>
          <LoadsInputField>
            <LoadsLabel htmlFor="methodPayment">Method</LoadsLabel>
            <SelectedValue>{methodPayment}</SelectedValue>
            <FieldIcon>
              <SelectArrow />
            </FieldIcon>
            <LoadsSelect
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                changeSelectState(e, setMethodPayment, setMethodPaymentId)
              }
            >
              <Opt hidden defaultValue></Opt>
              {loadData?.payment_methods?.map((item: any) => (
                <Opt key={item.id} value={item.title} data-value={item.id}>
                  {item.title}
                </Opt>
              ))}
            </LoadsSelect>
          </LoadsInputField>
          <LoadsInputField>
            <LoadsLabel htmlFor="driverPayPayment">Driver Pay</LoadsLabel>
            <LoadsInput
              id="driverPayPayment"
              type="number"
              style={{
                border: err.errors?.driver_pay?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
              }}
              onFocus={() =>
                setErr({
                  ...err,
                  errors: { ...err.errors, driver_pay: [] },
                })
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (Number(e.target.value) < 100000) {
                  handleInput(e, setLoadsPayment, 'driverPayPayment', loadsPayment);
                }
              }}
              value={loadsPayment.driverPayPayment}
            />
            <Error>{err.errors?.driver_pay?.[0]}</Error>
          </LoadsInputField>
          <LoadsInputField>
            <LoadsLabel htmlFor="notePayment">Notes</LoadsLabel>
            <LoadsInput
              id="notePayment"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadsPayment, 'notePayment', loadsPayment)
              }
              value={loadsPayment.notePayment}
            />
          </LoadsInputField>
          {/* <LoadsFlex>
              <LoadsInputFieldHalf >
                <LoadsLabel htmlFor="paidAmountPayment">Paid Amount</LoadsLabel>
                <LoadsInput
                  id="paidAmountPayment"
                  type="number"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInput(
                      e,
                      setLoadsPayment,
                      'paidAmountPayment',
                      loadsPayment,
                    )
                  }
                  value={loadsPayment.paidAmountPayment}
                />
              </LoadsInputFieldHalf>
              <LoadsInputFieldHalf >
                <LoadsLabel htmlFor="receiptDatePayment">
                  Receipt Date
                </LoadsLabel>
                <FieldIcon>
                  <Calendar />
                </FieldIcon>
                <InputDate
                  id="receiptDatePayment"
                  dateFormat="MM/dd/yyyy"
                  selected={receiptDatePayment}
                  onChange={(date: any) => setReceiptDatePayment(date)}
                />
              </LoadsInputFieldHalf>
            </LoadsFlex> */}
          <LoadsInputField>
            <LoadsLabel htmlFor="referenceNumberPayment">Reference Number</LoadsLabel>
            <LoadsInput
              id="referenceNumberPayment"
              type="number"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadsPayment, 'referenceNumberPayment', loadsPayment)
              }
              value={loadsPayment.referenceNumberPayment}
            />
          </LoadsInputField>
          <Line />
          <LoadsText>Invoice details</LoadsText>
          <LoadsInputField>
            <LoadsLabel htmlFor="invoiceIDPayment">Invoice ID</LoadsLabel>
            <LoadsInput
              id="invoiceIDPayment"
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInput(e, setLoadsPayment, 'invoiceIDPayment', loadsPayment)
              }
              value={loadsPayment.invoiceIDPayment}
            />
          </LoadsInputField>

          <LoadsInputField>
            <LoadsLabel htmlFor="invoiceNotes">Notes</LoadsLabel>
            <LoadsInputTextArea
              id="invoiceNotes"
              rows={1}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleInputTextArea(e, setLoadsPayment, 'invoiceNotes', loadsPayment)
              }
              value={loadsPayment.invoiceNotes}
            />
          </LoadsInputField>
        </BlockScroll>
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default PaymentModal;
