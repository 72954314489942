import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 30%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const TopText = styled.p`
  text-align: center;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  padding: 20px 20px 24px 20px;
`;

export const Title = styled.span`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #0e71eb;
`;

export const TitleAction = styled.span`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #f30f00;
`;

export const Text = styled.p`
  margin-bottom: 24px;
  text-align: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
`;

export const InputField = styled.div`
  padding: 0 24px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 8px;
`;

export const Input = styled.input`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 8px;
  border: 1px solid #c6c7da;
  padding: 10px;
  outline: none;
  color: #09091a;
  margin-bottom: 24px;
  border-radius: 4px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #f4f4f4;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;

export const Error = styled.span`
  position: absolute;
  bottom: 5px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const Button = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px 0;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #252736;
  cursor: pointer;
  text-transform: uppercase;
`;
