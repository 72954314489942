import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export type Report = {
  accounts_receivable: {
    current_due: string;
    past_due: string;
    total_due: string;
    records: [];
  };
  company_revenue: {
    revenue: string;
    records: [];
  };
  accounts_payable: {
    revenue: string;
    records: [];
  };
  driver_pay: {
    revenue: string;
    fee: string;
    records: [];
  };
};

export const routes = {
  reports: 'api/crm/reports',
};

export const getReport = createAsyncThunk<
  Report,
  null,
  {
    rejectValue: ErrorType;
  }
>('reports/getReport', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.reports}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const createReport = createAsyncThunk<
  null,
  FormData,
  {
    rejectValue: ErrorType;
  }
>('reports/createReport', async (createData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.reports}`, createData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});
