import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 50%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
  max-height: 90%;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 20px;
  background-color: #f4f4f4;
`;

export const BlockScroll = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
`;

export const Main = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 20px 24px;
`;

export const Block = styled.div`
  overflow: hidden;
  height: 690px;

  @media screen and (max-width: 1280px) {
    height: 500px;
  }

  @media screen and (max-width: 600px) {
    height: 400px;
  }
`;

export const LoadsBlock = styled.div`
  padding: 18px 16px 0;
  background-color: #fff;
  border-radius: 8px;
`;

export const LoadsTitle = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
`;

export const Header = styled.div`
  padding: 20px 23px;
  background-color: #fff;
  border-radius: 8px;
  border-bottom: 1px solid #f4f4f4;
`;
export const LoadsFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const LoadsInputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

export const LoadsInputFieldHalf = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const LoadsInputFieldThird = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const InputDiv = styled.div`
  height: 37px;
  border: 1px solid #c6c7da;
  padding: 10px;
  border-radius: 4px;
`;

export const LoadsInput = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  width: 100%;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const LoadsInputTextArea = styled.textarea`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  max-width: 100%;
  max-height: 162px;
`;

export const LoadsLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 4px;
`;

export const LoadsText = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #09091a;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const SelectedValue = styled.div`
  width: 100%;
  position: relative;
  left: 0;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  min-height: 37px;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 90%;
  transform: translateY(-75%);
  right: 0;
  padding: 10px;
  z-index: 1;
`;

export const LoadsSelect = styled.select`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  outline: none;
  padding: 6px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
`;

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const InputDate = styled(DatePicker)`
  width: 100%;
  min-height: 37px;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  padding: 7px 16px;
  cursor: pointer;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 20px 23px;
  border-top: 1px solid #f4f4f4;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;

export const CheckBoxItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const CustomCheckBoxSquare = styled.span`
  width: 26px;
  height: 26px;
  background-color: #fff;
  border: 1px solid #c6c7da;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const CheckBoxSquare = styled.input`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 26px;
  height: 26px;
  opacity: 0;
  z-index: 2;
  cursor: pointer;

  &:checked + span {
    background-color: #0e71eb;
    border: 1px solid transparent;
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: -17px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;
