import Pagination from '@mui/material/Pagination';
import React, { useState } from 'react';

import { Wrapper, LoadsListBlock, EmptyLoads, PaginationBlock } from './loadsTab.style';
import LoadsItem from '../LoadsItem/LoadsItem';

type LoadsProps = {
  loadsList: [];
  emptyList: string;
  currentPage: number;
  setCurrentPage: any;
};

const LoadsList: React.FC<LoadsProps> = ({ loadsList, emptyList, currentPage, setCurrentPage }) => {
  const PER_PAGE = 6;

  const offset = currentPage;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const pageCount = Math.ceil(loadsList.length / PER_PAGE);

  const currentPageData = loadsList
    .slice(offset * PER_PAGE - PER_PAGE, offset * PER_PAGE + PER_PAGE - PER_PAGE)
    .map((elem: any) => <LoadsItem key={elem.id} loadItem={elem} />);

  const pageContent = loadsList.length ? currentPageData : <EmptyLoads>No {emptyList} loads</EmptyLoads>;

  return (
    <Wrapper>
      <LoadsListBlock>{pageContent}</LoadsListBlock>

      {loadsList.length > 6 && (
        <PaginationBlock>
          <Pagination count={pageCount} page={currentPage} shape="rounded" onChange={handlePageChange} />
        </PaginationBlock>
      )}
    </Wrapper>
  );
};

export default LoadsList;
