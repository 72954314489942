import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  padding: 26px 40px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999!important;
  overflow: hidden;

  @media screen and (max-width: 450px) {
    padding: 26px 18px;
  }
`;

export const BurgerItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 50px;
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 26px auto;
  text-decoration: none;
  height: 24px;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  margin-left: 14px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
  margin: 37px auto;
`;
