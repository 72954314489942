import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Actions,
  AddPhoto,
  AttachFile,
  BlueButton,
  Bottom,
  Cancel,
  DocumentName,
  DocumentText,
  DriverBlock,
  EditIcon,
  Error,
  FieldFlex,
  FieldIcon,
  FilePlace,
  FlexColumn,
  FlexField,
  IconField,
  Image,
  ImageDefault,
  ImageEmpty,
  ImageName,
  ImagePlace,
  ImageWrapper,
  Info,
  InfoBlock,
  Input,
  InputDate,
  InputDiv,
  InputField,
  InputFieldHalf,
  InputFile,
  InputFilePlace,
  InputLabel,
  InputSelect,
  Line,
  ModalBlock,
  Opt,
  Required,
  SelectedValue,
  Tab,
  TabWrapper,
  TextEmpty,
  TopText,
  Wrapper,
} from './modalDriver.style';
import { addDriver, getDrivers, updateDriver } from '../../../API/drivers';
import { getFleet } from '../../../API/fleet';
import {
  Calendar,
  Camera,
  Close,
  Document,
  EditDriverImage,
  MinusDriver,
  PlusDriver,
  SelectArrow,
} from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { showDriverInfo } from '../../../store/slices/driversSlice';
import ModalPortal from '../ModalPortal';

import 'react-phone-number-input/style.css';

type ModalContentProps = {
  close: () => void;
  text: string;
  addCheck?: boolean;
  driverId?: number;
  refDriver: any;
};

const ModalDriver: React.FC<ModalContentProps> = ({ addCheck, close, text, driverId, refDriver }) => {
  const [err, setErr] = useState({
    message: '',
    errors: {
      first_name: [],
      last_name: [],
      password: [],
      email: [],
      phone: [],
      driver_license: [],
      medcard: [],
      contract: [],
      image: [],
    },
  });

  const { driverInfo } = useAppSelector((state: { driver: any }) => state.driver);
  const { fleetList } = useAppSelector((state: { fleet: any }) => state.fleet);
  const formattedDate = driverInfo?.birthday?.split(".");
  const [first_name, setFirst_name] = useState<string>(
    addCheck ? '' : driverInfo.first_name,
  );

  const [last_name, setLast_name] = useState<string>(addCheck ? '' : driverInfo.last_name);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [phone, setPhone] = useState<string>(addCheck ? '' : driverInfo.phone);
  const [email, setEmail] = useState<string>(addCheck ? '' : driverInfo.email);

  const [image, setImage] = useState(addCheck ? '' : driverInfo?.image?.name);
  const [imageFile, setImageFile] = useState<File | string>(addCheck ? '' : driverInfo.image);

  const [birthday, setBirthday] = useState<string | Date>(
    addCheck ? new Date() : new Date(`${formattedDate[1]}/${formattedDate[2]}/${formattedDate[0]}`)
  );
  const [deposit, setDeposit] = useState<string>(addCheck ? '' : driverInfo?.deposit);
  const [dispatcher, setDispatcher] = useState<string>('');
  const [dispatcherId, setDispatcherId] = useState<string>('');

  const [truck, setTruck] = useState<string>(addCheck ? '' : '');
  const [truckId, setTruckId] = useState<string>(addCheck ? '' : '');

  const [trailer, setTrailer] = useState<string>(addCheck ? '' : '');
  const [trailerId, setTrailerId] = useState<string>(addCheck ? '' : '');

  const [tab, setTab] = useState<string>(addCheck ? 'dl_a' : driverInfo?.driver_type.toLowerCase());

  const [attachData, setAttachData] = useState({
    driverLicense: false,
    medCard: false,
    contract: false,
  });

  const [driverLicenseFile, setDriverLicenseFile] = useState<File | string>('');
  const [driverLicenseName, setDriverLicenseName] = useState<string>('');
  const [medCardFile, setMedCardFile] = useState<File | string>('');
  const [medCardName, setMedCardName] = useState<string>('');
  const [contractFile, setContractFile] = useState<File | string>('');
  const [contractName, setContractName] = useState<string>('');

  const [attachmentName, setAttachmentName] = useState<string[]>([]);
  const [attachment, setAttachment] = useState<string[]>([]);

  const handleAddAttachments = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = event.target.files;
    const arr = Array.from(file || []);

    arr.forEach((elem: any) => {
      if (elem.size / 1024 / 1024 > 2) {
        alert(`${elem.name} is too big`);
      } else {
        setAttachment((prevfiles: any) => [...prevfiles, elem]);
        setAttachmentName((prevfiles: any) => [...prevfiles, elem.name]);
      }
    });
  };

  const handleDriverFile = async (event: React.ChangeEvent, setState: any, setStatename: any) => {
    const target = event.target as HTMLInputElement;
    let file: File = (target.files as FileList)[0];
    if (file.size / 1024 / 1024 > 2) {
      alert('File is too big!');
    } else {
      setState(file);
      setStatename(file.name);
    }
  };

  const deleteAttachments = (index: number) => {
    let dataName = [...attachmentName];
    dataName.splice(index, 1);
    setAttachmentName(dataName);
    let data = [...attachment];
    data.splice(index, 1);
    setAttachment(data);
  };

  const dispatch = useAppDispatch();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any) => setState(e.target.value);

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const formdata: any = new FormData();

  const handleChange = async (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    let file: File = (target.files as FileList)[0];
    if (file.size / 1024 / 1024 > 2) {
      alert('File is too big!');
    } else {
      const base64: any = await convertBase64(file);
      setImage(base64);
      setImageFile(file);
    }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const showAddData = (state: string) => {
    if (state === 'driverLicense') {
      setAttachData({
        ...attachData,
        driverLicense: !attachData.driverLicense,
      });
    } else if (state === 'medCard') {
      setAttachData({ ...attachData, medCard: !attachData.medCard });
    } else {
      setAttachData({ ...attachData, contract: !attachData.contract });
    }
  };

  const handleToSave = async () => {
    if (addCheck) {
      formdata.append('first_name', first_name);
      formdata.append('last_name', last_name);
      formdata.append('email', email);
      formdata.append('phone', phone);
      formdata.append('password', password);
      formdata.append('status', 'active');
      formdata.append('password_confirmation', confirmPassword);
      formdata.append('image', imageFile);
      formdata.append('hire_at', moment(new Date()).format('DD.MM.YYYY'));
      formdata.append('birthday', moment(birthday).format('DD.MM.YYYY'));
      deposit && formdata.append('deposit', deposit);
      formdata.append('driver_type', tab === 'dl_a' ? '0' : '1');
      truckId && formdata.append('truck_id', truckId);
      trailerId && formdata.append('trailer_id', trailerId);
      formdata.append('driver_license', driverLicenseFile);
      formdata.append('medcard', medCardFile);
      formdata.append('contract', contractFile);
      attachment.length !== 0 &&
        attachment.forEach((elem: any) => {
          formdata.append('attachments[]', elem);
        });
      const res: any = await dispatch(addDriver(formdata));

      if (res.type === 'drivers/addDriver/rejected') return setErr(res.payload);
    } else {
      formdata.append('first_name', first_name);
      formdata.append('last_name', last_name);
      formdata.append('email', email);
      formdata.append('phone', phone);
      formdata.append('status', driverInfo.status);
      imageFile !== driverInfo.image && formdata.append('image', imageFile);
      formdata.append('birthday', moment(birthday).format('DD.MM.YYYY'));
      deposit && formdata.append('deposit', deposit);
      formdata.append('driver_type', tab === 'dl_a' ? '0' : '1');
      truckId && formdata.append('truck_id', truckId);
      trailerId && formdata.append('trailer_id', trailerId);
      !driverLicenseFile && formdata.append('driver_license', driverLicenseFile);
      !medCardFile && formdata.append('medcard', medCardFile);
      !contractFile && formdata.append('contract', contractFile);
      attachment.length !== 0 &&
        attachment.forEach((elem: any) => {
          formdata.append('attachments[]', elem);
        });

      const res: any = await dispatch(updateDriver([formdata, driverId || 0]));
      if (res.type === 'drivers/updateDriver/rejected') return setErr(res.payload);
      await dispatch(
        showDriverInfo({
          id: driverId,
          first_name: first_name,
          last_name: last_name,
          email: email,
          phone: phone,
          status: driverInfo.status,
          image: imageFile !== driverInfo.image ? image : imageFile,
        })
      );
    }
    close();
    await dispatch(getDrivers(null));
  };

  useEffect(() => {
    dispatch(getFleet(null));
  }, []);

  return (
    <ModalPortal>
      <Wrapper ref={refDriver}>
        <TopText>{text}</TopText>
        <Line />
        <ModalBlock>
          <Info>
            {addCheck ? (
              <ImageEmpty>
                <AddPhoto type="file" accept=".png, .jpg, .jpeg" onChange={handleChange} /> <Camera />
                {image && <ImagePlace src={image} />}
              </ImageEmpty>
            ) : (
              <ImageWrapper>
                <AddPhoto type="file" accept=".png, .jpg, .jpeg" onChange={handleChange} />
                {image && <ImagePlace style={{ zIndex: 1 }} src={image} />}
                {driverInfo.image && !image ? (
                  <Image src={driverInfo.image} />
                ) : (
                  <ImageDefault>
                    <ImageName>
                      {driverInfo.first_name[0]} {driverInfo.last_name[0]}
                    </ImageName>
                  </ImageDefault>
                )}
                <EditIcon>
                  <EditDriverImage />
                </EditIcon>
              </ImageWrapper>
            )}
            <InfoBlock>
              <FlexField>
                <InputFieldHalf>
                  <InputLabel htmlFor="fname">First name</InputLabel>
                  <Input
                    id="fname"
                    type="text"
                    style={{
                      border: err.errors?.first_name?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                    }}
                    onFocus={() =>
                      setErr({
                        ...err,
                        errors: { ...err.errors, first_name: [] },
                      })
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFirst_name(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
                    }
                    value={first_name}
                  />
                  <Error>{err.errors?.first_name?.[0]}</Error>
                </InputFieldHalf>
                <InputFieldHalf>
                  <InputLabel htmlFor="lname">Last name</InputLabel>
                  <Input
                    id="lname"
                    type="text"
                    style={{
                      border: err.errors?.last_name?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                    }}
                    onFocus={() =>
                      setErr({
                        ...err,
                        errors: { ...err.errors, last_name: [] },
                      })
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setLast_name(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
                    }
                    value={last_name}
                  />
                  <Error>{err.errors?.last_name?.[0]}</Error>
                </InputFieldHalf>
              </FlexField>
              <InputField>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  id="email"
                  type="email"
                  style={{
                    border: err.errors?.email?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                  }}
                  onFocus={() => setErr({ ...err, errors: { ...err.errors, email: [] } })}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setEmail)}
                  value={email}
                />
                <Error>{err.errors?.email?.[0]}</Error>
              </InputField>
              <FlexField>
                {addCheck && (
                  <InputFieldHalf style={{ width: !addCheck && '100%' }}>
                    <InputLabel htmlFor="password">
                      Password <Required>(minimum 6)</Required>
                    </InputLabel>
                    <Input
                      id="password"
                      type="password"
                      style={{
                        border: err.errors?.password?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                      }}
                      onFocus={() =>
                        setErr({
                          ...err,
                          errors: { ...err.errors, password: [] },
                        })
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setPassword)}
                      value={password}
                    />
                    <Error>{err.errors?.password?.[0]}</Error>
                  </InputFieldHalf>
                )}
                {addCheck && (
                  <InputFieldHalf>
                    <InputLabel htmlFor="confirmPassword">Submit Password</InputLabel>
                    <Input
                      id="confirmPassword"
                      type="password"
                      style={{
                        border: err.errors?.password?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                      }}
                      onFocus={() =>
                        setErr({
                          ...err,
                          errors: { ...err.errors, password: [] },
                        })
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setConfirmPassword)}
                      value={confirmPassword}
                    />
                    <Error>{err.errors?.password?.[0]}</Error>
                  </InputFieldHalf>
                )}
              </FlexField>
              <InputField>
                <InputLabel htmlFor="phone">Phone</InputLabel>
                <InputDiv
                  style={{
                    border: err.errors?.phone?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                  }}
                  onFocus={() => setErr({ ...err, errors: { ...err.errors, phone: [] } })}
                >
                  <PhoneInput international limitMaxLength value={phone} onChange={(value) => setPhone(value || '')} />
                </InputDiv>
                <Error>{err.errors?.phone?.[0]}</Error>
              </InputField>
              <Line style={{ margin: '20px auto' }} />
              <DriverBlock>PROFILE INFORMATION</DriverBlock>
              <FlexField>
                <InputFieldHalf>
                  <InputLabel htmlFor="birthday">Birthday</InputLabel>
                  <FieldIcon>
                    <Calendar />
                  </FieldIcon>
                  <InputDate
                    id="birthday"
                    dateFormat="MM/dd/yyyy"
                    selected={birthday}
                    onChange={(date: any) => setBirthday(date)}
                  />
                </InputFieldHalf>
                <InputFieldHalf>
                  <InputLabel htmlFor="deposit">Deposit</InputLabel>
                  <Input
                    id="deposit"
                    type="number"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleInput(e, setDeposit)}
                    value={deposit}
                  />
                </InputFieldHalf>
              </FlexField>
              <FlexField>
                <InputFieldHalf>
                  <InputLabel htmlFor="dispatcher">Dispatcher</InputLabel>
                  <SelectedValue>{dispatcher}</SelectedValue>
                  <FieldIcon>
                    <SelectArrow />
                  </FieldIcon>
                  <InputSelect
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      changeSelectState(e, setDispatcher, setDispatcherId)
                    }
                  >
                    <Opt hidden defaultValue></Opt>
                    {[1, 2, 3].map((item: any) => (
                      <Opt key={item} value={item} data-value={item}>
                        {item}
                      </Opt>
                    ))}
                  </InputSelect>
                </InputFieldHalf>
                <InputFieldHalf>
                  <InputLabel htmlFor="driverType">Driver type</InputLabel>
                  <TabWrapper>
                    <Tab
                      style={{
                        borderRight: '1px solid #9292A4',
                        color: tab === 'dl_a' ? '#0E71EB' : '#333443',
                        backgroundColor: tab === 'dl_a' ? '#B0DCFF' : 'transparent',
                      }}
                      onClick={() => setTab('dl_a')}
                    >
                      DL-A
                    </Tab>
                    <Tab
                      style={{
                        borderLeft: '1px solid #9292A4',
                        color: tab === 'owner' ? '#0E71EB' : '#333443',
                        backgroundColor: tab === 'owner' ? '#B0DCFF' : 'transparent',
                      }}
                      onClick={() => setTab('owner')}
                    >
                      Owner
                    </Tab>
                  </TabWrapper>
                </InputFieldHalf>
              </FlexField>
              <DriverBlock>FLEET</DriverBlock>
              <FlexField>
                <InputFieldHalf>
                  <InputLabel htmlFor="truck">Truck</InputLabel>
                  <SelectedValue>{truck}</SelectedValue>
                  <FieldIcon>
                    <SelectArrow />
                  </FieldIcon>
                  <InputSelect
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSelectState(e, setTruck, setTruckId)}
                  >
                    <Opt hidden defaultValue></Opt>
                    {fleetList &&
                      fleetList.map(
                        (item: any) =>
                          item.type === 0 && (
                            <Opt key={item.id} value={'Truck #' + item.id + ' / ' + item.plate} data-value={item.id}>
                              {'Truck #' + item.id + ' / ' + item.plate}
                            </Opt>
                          )
                      )}
                  </InputSelect>
                </InputFieldHalf>
                <InputFieldHalf>
                  <InputLabel htmlFor="trailer">Trailer</InputLabel>
                  <SelectedValue>{trailer}</SelectedValue>
                  <FieldIcon>
                    <SelectArrow />
                  </FieldIcon>
                  <InputSelect
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      changeSelectState(e, setTrailer, setTrailerId)
                    }
                  >
                    <Opt hidden defaultValue></Opt>
                    {fleetList &&
                      fleetList.map(
                        (item: any) =>
                          item.type === 1 && (
                            <Opt key={item.id} value={'Trailer #' + item.id + ' / ' + item.plate} data-value={item.id}>
                              {'Trailer #' + item.id + ' / ' + item.plate}
                            </Opt>
                          )
                      )}
                  </InputSelect>
                </InputFieldHalf>
              </FlexField>
              <DriverBlock>PAPERWORK</DriverBlock>
              <InputField>
                <FieldFlex>
                  <InputLabel htmlFor="driverLicense">Driver's License</InputLabel>
                  <IconField onClick={() => showAddData('driverLicense')}>
                    {attachData.driverLicense ? <MinusDriver /> : <PlusDriver />}
                  </IconField>
                </FieldFlex>
                {attachData.driverLicense && (
                  <>
                    <FilePlace>
                      <InputFilePlace>
                        <InputFile
                          type="file"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleDriverFile(e, setDriverLicenseFile, setDriverLicenseName)
                          }
                        />
                        <FlexColumn>
                          <BlueButton>Upload attachments</BlueButton>
                          <TextEmpty>or drag and drop files</TextEmpty>
                        </FlexColumn>
                      </InputFilePlace>
                    </FilePlace>
                    {driverLicenseName && (
                      <DocumentName>
                        <Document />
                        <DocumentText>{driverLicenseName}</DocumentText>
                      </DocumentName>
                    )}
                  </>
                )}
                <Error style={{ bottom: '-20px' }}>{err.errors?.driver_license?.[0]}</Error>
              </InputField>
              <Line style={{ margin: '20px auto' }} />
              <InputField>
                <FieldFlex>
                  <InputLabel htmlFor="medcard">Medcard</InputLabel>
                  <IconField onClick={() => showAddData('medCard')}>
                    {attachData.medCard ? <MinusDriver /> : <PlusDriver />}
                  </IconField>
                </FieldFlex>
                {attachData.medCard && (
                  <>
                    <FilePlace>
                      <InputFilePlace>
                        <InputFile
                          type="file"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleDriverFile(e, setMedCardFile, setMedCardName)
                          }
                        />
                        <FlexColumn>
                          <BlueButton>Upload attachments</BlueButton>
                          <TextEmpty>or drag and drop files</TextEmpty>
                        </FlexColumn>
                      </InputFilePlace>
                    </FilePlace>
                    {medCardName && (
                      <DocumentName>
                        <Document />
                        <DocumentText>{medCardName}</DocumentText>
                      </DocumentName>
                    )}
                  </>
                )}
                <Error style={{ bottom: '-20px' }}>{err.errors?.medcard?.[0]}</Error>
              </InputField>
              <Line style={{ margin: '20px auto' }} />
              <InputField>
                <FieldFlex>
                  <InputLabel htmlFor="contract">Contract</InputLabel>
                  <IconField onClick={() => showAddData('contract')}>
                    {attachData.contract ? <MinusDriver /> : <PlusDriver />}
                  </IconField>
                </FieldFlex>
                {attachData.contract && (
                  <>
                    <FilePlace>
                      <InputFilePlace>
                        <InputFile
                          type="file"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleDriverFile(e, setContractFile, setContractName)
                          }
                        />
                        <FlexColumn>
                          <BlueButton>Upload attachments</BlueButton>
                          <TextEmpty>or drag and drop files</TextEmpty>
                        </FlexColumn>
                      </InputFilePlace>
                    </FilePlace>
                    {contractName && (
                      <DocumentName>
                        <Document />
                        <DocumentText>{contractName}</DocumentText>
                      </DocumentName>
                    )}
                  </>
                )}
                <Error style={{ bottom: '-20px' }}>{err.errors?.contract?.[0]}</Error>
              </InputField>
              <Line style={{ margin: '20px auto' }} />
              <DriverBlock>ATTACHMENTS (OPTIONAL)</DriverBlock>
              <FilePlace>
                <InputFilePlace>
                  <InputFile
                    type="file"
                    multiple
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddAttachments(e)}
                  />
                  <FlexColumn>
                    <BlueButton>Upload attachments</BlueButton>
                    <TextEmpty>or drag and drop files</TextEmpty>
                  </FlexColumn>
                </InputFilePlace>
              </FilePlace>
              {attachmentName.map((elem: any, id: number) => {
                return (
                  <AttachFile key={id}>
                    <DocumentName>
                      <Document />
                      <DocumentText>{elem}</DocumentText>
                    </DocumentName>
                    <div onClick={() => deleteAttachments(id)}>
                      <Close />
                    </div>
                  </AttachFile>
                );
              })}
            </InfoBlock>
          </Info>
        </ModalBlock>
        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleToSave} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalDriver;
