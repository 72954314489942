import React, { useState } from 'react';

import {
  Wrapper,
  Text,
  CheckboxBlock,
  LearnMore,
  Bottom,
  Actions,
} from './driverApp.style';
import { saveDriverApp } from '../../../API/settings';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const DriverApp: React.FC = () => {
  const dispatch = useAppDispatch();

  const { settings } = useAppSelector(
    (state: { settings: any }) => state.settings,
  );

  const [fleetDriveMode, setFleetDriveMode] = useState<boolean>(settings ? settings.fleet_drive_mode : false);
  const [hidePaymentInfo, setHidePaymentInfo] = useState<boolean>(settings ? settings.hide_payment_info : false);
  const [enableSuperLoadboard, setEnableSuperLoadboard] =
    useState<boolean>(settings ? settings.super_loadboard : false);
  const [disableCustomer, setDisableCustomer] = useState<boolean>(settings ? settings.customer_not_available : false);
  const [disableSTI, setDisableSTI] = useState<boolean>(settings ? settings.sti : false);
  const [enableDriverPay, setEnableDriverPay] = useState<boolean>(settings ? settings.driver_pay : false);
  const [enableDriver, setEnableDriver] = useState<boolean>(settings ? settings.driver_edit_carrier : false);

  const handleConfirm = () =>
    dispatch(
      saveDriverApp({
        fleet_drive_mode: fleetDriveMode,
        hide_payment_info: hidePaymentInfo,
        super_loadboard: enableSuperLoadboard,
        customer_not_available: disableCustomer,
        sti: disableSTI,
        driver_pay: enableDriverPay,
        driver_edit_carrier: enableDriver,
      }),
    );

  return (
    <Wrapper>
      <CheckboxBlock style={{ marginTop: 0 }}>
        <Checkbox
          title="Enable Fleet Drive mode on Driver apps"
          checkboxName="fleetDriveMode"
          checked={fleetDriveMode}
          setChecked={() => {
            setFleetDriveMode(!fleetDriveMode);
            setHidePaymentInfo(false);
          }}
        />
        <Text>
          Fleet Drive mode controls and limits what the Drivers of the fleet are
          able to do via their Mobile app.
          <LearnMore>Learn more...</LearnMore>
        </Text>
      </CheckboxBlock>
      <CheckboxBlock>
        <Checkbox
          title="Hide Payment Information on Driver apps (will show price for payment terms: COD, COP and CKOD)"
          checkboxName="hidePaymentInfo"
          checked={fleetDriveMode ? hidePaymentInfo : false}
          disabled={!fleetDriveMode}
          setChecked={() => setHidePaymentInfo(!hidePaymentInfo)}
        />
        <Text>This works when Fleet Driver Mode is checked.</Text>
      </CheckboxBlock>
      <CheckboxBlock>
        <Checkbox
          title="Enable Turbo Loadboard on the Driver App"
          checkboxName="enableSuperLoadboard"
          checked={enableSuperLoadboard}
          setChecked={() => setEnableSuperLoadboard(!enableSuperLoadboard)}
        />
      </CheckboxBlock>
      <CheckboxBlock>
        <Checkbox
          title="Disable Customer not Available button on the Driver App"
          checkboxName="disableCustomer"
          checked={disableCustomer}
          setChecked={() => setDisableCustomer(!disableCustomer)}
        />
      </CheckboxBlock>
      <CheckboxBlock>
        <Checkbox
          title="Disable STI (Subject To Inspection) button on the Driver App"
          checkboxName="disableSTI"
          checked={disableSTI}
          setChecked={() => setDisableSTI(!disableSTI)}
        />
      </CheckboxBlock>
      <CheckboxBlock>
        <Checkbox
          title="Enable Driver Pay field"
          checkboxName="enableDriverPay"
          checked={enableDriverPay}
          setChecked={() => setEnableDriverPay(!enableDriverPay)}
        />
      </CheckboxBlock>
      <CheckboxBlock>
        <Checkbox
          title="Enable Driver to edit Carrier information"
          checkboxName="enableDriver"
          checked={enableDriver}
          setChecked={() => setEnableDriver(!enableDriver)}
        />
      </CheckboxBlock>
      <Bottom>
        <Actions>
          <Button
            text="Save Changes"
            pad="8px 24px"
            handleButton={handleConfirm}
          />
        </Actions>
      </Bottom>
    </Wrapper>
  );
};

export default DriverApp;
