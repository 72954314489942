import { GlobalStyle, Wrapper } from './App.style.js';
import 'react-datepicker/dist/react-datepicker.css';
import Routing from './Routing';

export const App: React.FC = () => {
  return (
    <Wrapper>
      <GlobalStyle />
      <Routing />
    </Wrapper>
  );

};
