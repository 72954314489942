import moment from 'moment';
import React, { useMemo, useState } from 'react';
import Map, { Marker, Popup } from 'react-map-gl';

import { PopupWrapper, Image, Name, Email, Line, StreetName, OnWay } from './trackingMap.style';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ActiveDrivers } from '../../../API/tracking';
import { useAppSelector } from '../../../store/hooks';

type DriverListProps = {
  activeDriverList: ActiveDrivers;
};

const TrackingMap: React.FC<DriverListProps> = ({ activeDriverList }) => {
  let size = 24;
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [coords, setCoords] = useState<any>({
    longitude: '',
    latitude: '',
    time: 0,
  });

  const { activeDriversTracking } = useAppSelector((state: { tracking: any }) => state.tracking);

  const pins = useMemo(
    () =>
      activeDriverList.map((obj, index) => (
        <Marker
          key={obj.id}
          longitude={activeDriversTracking[index]?.longitude ? activeDriversTracking[index]?.longitude : 0}
          latitude={activeDriversTracking[index]?.latitude ? activeDriversTracking[index]?.latitude : 0}
          anchor="bottom"
          style={{
            display: activeDriversTracking[index]?.longitude ? 'block' : 'none',
          }}
          onClick={(e) => {
            e.originalEvent.stopPropagation();
            setPopupInfo(obj);
            setCoords({
              longitude: activeDriversTracking[index]?.longitude,
              latitude: activeDriversTracking[index]?.latitude,
              time: activeDriversTracking[index]?.time,
            });
          }}
        >
          {obj.image ? (
            <img
              src={obj.image ? obj.image : ''}
              alt="location-icon"
              style={{
                width: size,
                cursor: 'pointer',
                borderRadius: '50%',
              }}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
                width: '32px',
                height: '32px',
                cursor: 'pointer',
                borderRadius: '50%',
                backgroundColor: '#E9EAF8',
                fontFamily: 'Montserrat',
                fontWeight: 400,
                fontSize: '12px',
                color: '#09091A',
              }}
            >
              {obj.first_name[0]} {obj.last_name[0]}
            </div>
          )}
        </Marker>
      )),
    [activeDriversTracking]
  );

  return (
    <>
      <Map
        initialViewState={{
          latitude: 40,
          longitude: -100,
          zoom: 3.5,
          bearing: 0,
          pitch: 0,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken="pk.eyJ1IjoidHVyYm9kaXNwYXRjaCIsImEiOiJjbGJtbnJocjkwYXZmM3JwOHhobmJ3cjZiIn0.HfycdIjBcSM3UDG3BygbQA"
      >
        {pins}

        {popupInfo && (
          <Popup
            anchor="top"
            longitude={coords.longitude}
            latitude={coords.latitude}
            onClose={() => setPopupInfo(null)}
            closeButton={false}
          >
            <PopupWrapper>
              {popupInfo.image ? (
                <Image src={popupInfo.image} />
              ) : (
                <div
                  style={{
                    margin: '20px auto 16px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '5px',
                    width: '32px',
                    height: '32px',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    backgroundColor: '#E9EAF8',
                    fontFamily: 'Montserrat',
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#09091A',
                  }}
                >
                  {popupInfo.first_name[0]} {popupInfo.last_name[0]}
                </div>
              )}
              <Name>{popupInfo.first_name + ' ' + popupInfo.last_name}</Name>
              <Email>{popupInfo.email}</Email>
              <Line />
              <StreetName>Street name, 17, 00200</StreetName>
              <OnWay>{`${moment.unix(coords.time).format('H')}h ${moment
                .unix(coords.time)
                .format('mm')}m on the way`}</OnWay>
            </PopupWrapper>
          </Popup>
        )}
      </Map>
    </>
  );
};

export default TrackingMap;
