import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Top,
  BackBlock,
  DownloadSample,
  Block,
  InputFile,
  Browse,
  TypeFile,
} from './importLoads.style';
import { importLoads } from '../../../API/loads';
import { Back, Download } from '../../../assets/icons';
import Container from '../../../layout/Container';
import { useAppDispatch } from '../../../store/hooks';

const ImportLoads: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleChange = async (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);

    await dispatch(importLoads(formData));
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <BackBlock onClick={() => navigate('/loads')}>
              <Back fill="#616172" /> Import loads
            </BackBlock>
            <DownloadSample href="/import_load_sample.csv" download>
              <Download /> Download sample
            </DownloadSample>
          </Top>
          <Block>
            <InputFile
              onChange={handleChange}
              type="file"
              accept=".xlsx, .xls, .csv, .txt"
            />
            <Browse>Browse</Browse>
            <TypeFile>Drag&Drop or upload CSV, XLS, XLSX file here</TypeFile>
          </Block>
          {/* <Errors>
            <ErrorTitle>0:</ErrorTitle>
            <ErrorItem>this field is required</ErrorItem>
            </Errors> */}
        </Wrapper>
      </Container>
    </>
  );
};

export default ImportLoads;
