import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const Plan = styled.div`
  width: 600px;
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;
  margin: 0 20px;
  height: fit-content;

  @media screen and (max-width: 1280px) {
    width: 100%;
    margin: 0 0 20px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 32px 16px;
  }
`;

export const Name = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #09091a;
  margin-bottom: 32px;
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 32px;
`;

export const GetStarted = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #616172;
  border-radius: 4px;
  padding: 8px 24px;
  outline: none;
  border: none;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
`;