import * as React from 'react';
import { SVGProps } from 'react';

type DataProps = {
  data?: string;
};

const PhoneForm: React.FC<DataProps> = (
  { data },
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.152 2.972c.599-.6 1.64-.674 2.258.039l2.238 2.582a.834.834 0 0 1 .018.02c.557.643.438 1.563-.107 2.11l-.622.623a8.871 8.871 0 0 0 2.705 2.71l.623-.623c.545-.546 1.462-.666 2.104-.115l2.614 2.244c.718.617.645 1.663.046 2.263l-1.873 1.876c-.78.78-1.977 1.04-2.983.552a20.305 20.305 0 0 1-9.427-9.44c-.487-1.006-.228-2.203.551-2.983l1.855-1.858Zm6.19 8.385.009-.009-.009.009ZM8.638 8.646l.008-.009a.586.586 0 0 1-.008.009Zm-.318-2.04L6.257 4.224l-1.78 1.783c-.324.325-.381.769-.23 1.079v.001a18.639 18.639 0 0 0 8.654 8.667c.31.15.752.094 1.076-.23l1.798-1.802-2.39-2.052-.845.846a1.142 1.142 0 0 1-1.38.197 10.539 10.539 0 0 1-3.89-3.906 1.142 1.142 0 0 1 .207-1.358l.843-.843Zm4.964 4.977Zm.16.027Z"
      fill={data ? '#616172' : '#C6C7DA'}
    />
  </svg>
);

export default PhoneForm;
