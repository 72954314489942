import { Drawer as MUIDrawer } from '@mui/material';
import styled from 'styled-components';

export const Drawer = styled(MUIDrawer)``;

export const DrawerModalContent = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  padding: 32px;
  gap: 32px;
`;

export const Header = styled.div`
  font-family: 'MontserratSemiBold';
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  color: #09091a;

  & svg {
    & path {
      fill: #09091a;
    }
  }
`;

export const Main = styled.div`
  font-family: 'Montserrat';
`;
