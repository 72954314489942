import { Tabs, Autocomplete, TextField } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 80vh;
  margin-left: 32px;
  overflow: hidden;

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ImportBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;

  @media screen and (max-width: 360px) {
    margin-right: 20px;
  }
`;

export const ImportText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  margin-left: 10px;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const Filter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;

  @media screen and (max-width: 920px) {
    margin-top: 16px;
  }
`;

export const FieldWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-70%);
  right: 7px;
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  margin: 0 auto 12px;
  width: 100%;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 25px 8px 15px;
  outline: none;
  background-color: transparent;

  @media screen and (max-width: 920px) {
    margin: 0 auto 12px;
  }
`;

export const LoadsField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 14.5%;
  height: 50px;
  margin: 0 20px 8px 0;

  @media screen and (max-width: 1650px) {
    width: 190px;
  }

  @media screen and (max-width: 1280px) {
    width: 28%;
  }

  @media screen and (max-width: 920px) {
    width: 46%;
    margin: 0 5px 20px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const FieldIcon = styled.div`
  position: absolute;
  right: 0;
  padding: 10px;
`;

export const SelectedValue = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  min-height: 37px;
`;

export const LoadsSelect = styled.select`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  outline: none;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
`;

export const LoadsAutocompleteSelect = styled(Autocomplete)`
  height: 39px;
`;

export const LoadsTextField = styled(TextField)({
  borderRadius: '4px',
  padding: 0,

  '& input::placeholder': {
    fontFamily: 'MontserratSemiBold',
    fontSize: '16px',
    fontWeight: 600,
    color: '#09091A',
    opacity: 1,
  },

  '& .MuiInputBase-input': {
    height: '21px',
  },

  '& label.Mui-focused': {
    color: '#A0AAB4',
  },

  '& .MuiInput-underline:after': {
    borderBottomColor: '#c6c7da',
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #c6c7da',
    },
    '&:hover fieldset': {
      border: '1px solid #c6c7da',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #c6c7da',
    },
  },
});

export const SearchText = styled.p`
  position: absolute;
  bottom: -3px;
  left: 8px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 12px;
  color: #09091a;
`;

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const TabPanel = styled(Tabs)`
  margin-top: 30px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1650px) {
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  }
`;

export const Tab = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  margin-right: 20px;
  padding: 0 12px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: ${(props) => (props.active ? '#1267CF' : '#616172')};
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  margin: -5px 0 3px;
`;

export const Count = styled.div`
  margin: 0 0 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: ${(props) => (props.active ? '#0E71EB' : '#616172')};
  background-color: ${(props) => (props.active ? '#B0DCFF' : '#e9eaf8')};
`;

export const BorderBottom = styled.div`
  position: absolute;
  bottom: -17px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #1267cf;
`;
