import * as React from "react"
import { SVGProps } from "react"

type DataProps = {
  data?: string;
};

const AddressForm:React.FC<DataProps> = ({data}, props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 8.334c0 2.39-1.328 4.57-2.863 6.155A17.175 17.175 0 0 1 10 16.333a17.177 17.177 0 0 1-2.136-1.844C6.328 12.905 5 10.723 5 8.334c0-2.84 2.264-5 5-5s5 2.16 5 5Zm-5 10s6.667-3.966 6.667-10A6.633 6.633 0 0 0 10 1.667a6.632 6.632 0 0 0-6.667 6.667c0 6.034 6.667 10 6.667 10Zm.833-10a.833.833 0 1 1-1.666 0 .833.833 0 0 1 1.666 0Zm1.667 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      fill={data ? '#616172' : '#C6C7DA'}
    />
  </svg>
)

export default AddressForm
