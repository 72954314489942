import React from 'react';

import { FlexBetween, FlexTopBetween, LoadsBlock, LoadsEdit, LoadsTextRegular, LoadsTitle } from '../loadsItem.style';

type LoadsItemProps = {
  setTextAreaModal?: () => void;
  setTextAreaTitle?: () => void;
  loadInfo: any;
};

const LoadsItemDriverInstructions: React.FC<LoadsItemProps> = ({ setTextAreaModal, setTextAreaTitle, loadInfo }) => {
  return (
    <LoadsBlock overflow>
      <FlexBetween>
        <LoadsTitle>Driver Instructions</LoadsTitle>
        {setTextAreaModal && setTextAreaTitle && (
          <LoadsEdit
            className="noPrint"
            onClick={() => {
              setTextAreaModal();
              setTextAreaTitle();
            }}
          >
            Edit
          </LoadsEdit>
        )}
      </FlexBetween>
      <FlexTopBetween>
        <LoadsTextRegular textWrap>
          {loadInfo?.load?.driver_instructions ? loadInfo?.load?.driver_instructions : 'No driver instructions'}
        </LoadsTextRegular>
      </FlexTopBetween>
    </LoadsBlock>
  );
};

export default LoadsItemDriverInstructions;
