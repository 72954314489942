import { SVGProps } from "react"

const DashboardDelayedDelivers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.254 4 21 10.667l-3.69 3.464-9.457-3.865C9.521 8.826 11.685 8 14.254 8V4Zm-7.85 7.834C4.848 13.934 4 16.768 4 20c1.974-3.255 4.226-5.388 6.796-6.371l-4.392-1.795Zm7.85 3.208V17l1.453-1.364-1.453-.594Z"
      fill="#7767D2"
    />
    <path fill="#7767D2" d="M4 8.851 4.756 7l15.968 6.526-.756 1.851z" />
  </svg>
)

export default DashboardDelayedDelivers
