import Pagination from '@mui/material/Pagination';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadItem from './LoadItem/LoadItem';
import {
  Actions,
  BackButton,
  EmptyLoads,
  FieldIcon,
  Input,
  LoadsList,
  Opt,
  PaginationBlock,
  SelectedValue,
  Top,
  TopText,
  TopTitle,
  TripItemBlock,
  TripItemData,
  TripLabel,
  TripLoadBlock,
  TripLoadButton,
  TripsField,
  TripsSelect,
  TripTitle,
  TripValue,
  Wrapper,
} from './tripItem.style';
import { getDrivers } from '../../../API/drivers';
import { assignDriver, getTripId, updateTrip } from '../../../API/trips';
import { Back, Plus, SelectArrow } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import Container from '../../../layout/Container';
import ModalTripAddLoads from '../../../layout/Modal/ModalTrip/ModalTripAddLoads/ModalTripAddLoads';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Preloader from '../../Preloader/Preloader';

const TripItem: React.FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const PER_PAGE = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const offset = currentPage;

  const refTrips = useRef<any>();

  const { tripElem, loading } = useAppSelector(
    (state: { trips: any }) => state.trips,
  );
  const { driversList } = useAppSelector(
    (state: { driver: any }) => state.driver,
  );
  const [name, setName] = useState<string>('');
  const [pageId, setPageId] = useState<number>(0);
  const [addTripModalOpen, setAddTripModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setName(tripElem?.trip?.name);
    if (tripElem?.trip?.driver)
      setDriverName(
        tripElem?.trip?.driver?.first_name +
          ' ' +
          tripElem?.trip?.driver?.last_name,
      );
  }, [tripElem]);

  const [driverName, setDriverName] = useState<string>('');
  const [driverId, setDriverId] = useState<number>(
    tripElem?.trip?.driver ? tripElem?.trip?.driver?.id : 0,
  );

  const changeSelectState = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    setState: any,
    setStateIndex?: any,
  ) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  useEffect(() => {
    if (name === tripElem?.trip?.name) return;
    const timer = setTimeout(() => {
      dispatch(updateTrip([pageId, name]));
    }, 3000);

    return () => clearTimeout(timer);
  }, [name]);

  useEffect(() => {
    if (driverId === 0 || driverId === tripElem?.trip?.driver?.id) return;
    dispatch(assignDriver([pageId, driverId]));
  }, [driverId]);

  useEffect(() => {
    const queryParams = window.location.pathname;
    if (queryParams.slice(0, 7) === '/trips/') {
      const value: number = parseInt(queryParams.slice(7));
      setPageId(value);
      if (pageId !== 0) {
        dispatch(getTripId(value));
        dispatch(getDrivers(null));
      }
    }
  }, [pageId]);

  useEffect(() => {
    document.body.style.overflow = addTripModalOpen ? 'hidden' : 'unset';
  }, [addTripModalOpen]);

  useOnClickOutside(refTrips, () => setAddTripModalOpen(false));

  if (loading) return <Preloader />;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };

  const pageCount = Math.ceil(tripElem?.trip?.loads.length / PER_PAGE);

  const currentPageData = tripElem?.trip?.loads
    .slice(
      offset * PER_PAGE - PER_PAGE,
      offset * PER_PAGE + PER_PAGE - PER_PAGE,
    )
    .map((elem: any) => {
      return <LoadItem key={elem.id} elem={elem} tripId={pageId} />;
    });

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <TopTitle onClick={() => navigation('/trips')}>
              <BackButton>
                <Back fill="#616172" />
              </BackButton>{' '}
              <TopText>{tripElem?.trip?.name}</TopText>
            </TopTitle>
            <Actions>
              <Button
                text="Add Load"
                pad="10px 20px"
                icon={<Plus />}
                handleButton={() => setAddTripModalOpen(true)}
              />
            </Actions>
          </Top>
          <TripItemBlock>
            <TripItemData style={{ padding: '45px 20px' }}>
              <TripsField>
                <TripLabel htmlFor="tripName">Trip name</TripLabel>
                <Input
                  id="tripName"
                  type="text"
                  value={name}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setName(e.target.value.replace(/[^A-Za-z\s]/gi, ''))
                  }
                />
              </TripsField>
              <TripsField style={{ marginTop: '20px' }}>
                <TripLabel htmlFor="tripName">Assigned to</TripLabel>
                <SelectedValue>
                  {driverName ? driverName : 'Choose...'}
                </SelectedValue>
                <FieldIcon>
                  <SelectArrow />
                </FieldIcon>
                <TripsSelect
                  id="tripName"
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    changeSelectState(e, setDriverName, setDriverId)
                  }
                >
                  <Opt hidden defaultValue></Opt>
                  {driversList?.map((elem: any) => (
                    <Opt
                      key={elem.id}
                      value={elem.first_name + ' ' + elem.last_name}
                      data-value={elem.id}
                    >
                      {elem.first_name} {elem.last_name}
                    </Opt>
                  ))}
                </TripsSelect>
              </TripsField>
            </TripItemData>
            <TripItemData>
              <TripLoadBlock>
                <TripTitle>Total Price: </TripTitle>
                <TripValue>${tripElem.total_price}</TripValue>
              </TripLoadBlock>
              <TripLoadBlock>
                <TripTitle>Broker Fee: </TripTitle>
                <TripValue>${tripElem.broker_fee}</TripValue>
              </TripLoadBlock>
              <TripLoadBlock>
                <TripTitle>Expenses: </TripTitle>
                <TripValue>${tripElem.expenses}</TripValue>
              </TripLoadBlock>
              <TripLoadBlock>
                <TripTitle>{tripElem?.trip?.loads?.length} loads:</TripTitle>
                <TripValue>
                  {tripElem.general_status ? 'All delivered' : 'In process'}
                </TripValue>
              </TripLoadBlock>
              <TripLoadButton>Route Map</TripLoadButton>
            </TripItemData>
          </TripItemBlock>

          <LoadsList>
            {tripElem?.trip?.loads.length ? (
              currentPageData
            ) : (
              <EmptyLoads>No loads</EmptyLoads>
            )}
          </LoadsList>
          {tripElem?.trip?.loads.length > 6 && (
            <PaginationBlock>
              <Pagination
                count={pageCount}
                page={currentPage}
                shape="rounded"
                onChange={handlePageChange}
              />
            </PaginationBlock>
          )}
        </Wrapper>

        {addTripModalOpen && (
          <ModalTripAddLoads
          refTrips={refTrips}
            tripId={pageId}
            name={tripElem.trip.name}
            close={() => setAddTripModalOpen(false)}
          />
        )}
      </Container>
    </>
  );
};

export default TripItem;
