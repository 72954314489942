import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    margin-bottom: 16px;
  }
`;

export const Image = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const ImageDefault = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e9eaf8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageName = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -1px;
  color: #09091A;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  color: #09091a;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  margin-left: 16px;
  cursor: pointer;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const Edit = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  cursor: pointer;
  margin: 0 20px;

  @media screen and (max-width: 1024px) {
    margin: 0;
  }
`;

export const Reactivate = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  cursor: pointer;
  margin: 0 20px;

  @media screen and (max-width: 1024px) {
    margin: 0;
    margin-left: 20px;
  }
`;

export const Delete = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #f30f00;
  cursor: pointer;
  margin-left: 20px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #f4f4f4;
  margin-top: 24px;
`;

export const DriverInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
`;

export const DriverInfoText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  margin-left: 15px;
`;

export const DriverInfoTextDefault = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #9292a4;
  margin-left: 15px;
`;

export const Active = styled.p`
  color: #0baa1b;
  font-family: 'MontserratBold';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 16px;
  background-color: rgba(11, 170, 27, 0.15);
  border-radius: 4px;
  margin-left: 20px;
`;

export const Deactivated = styled.p`
  color: #9292A4;
  font-family: 'MontserratBold';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 16px;
  background-color: #E9EAF8;
  border-radius: 4px;
  margin-left: 20px;
`;
