import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 32px;

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TopTitle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
`;

export const BackButton = styled.div`
  display: inline-flex;
  min-width: 24px;
`;

export const TopText = styled.p`
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
`;

export const TripItemBlock = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const TripItemData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  margin: 0 32px 0 0;
  width: 28%;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0 0 16px 0;
  }
`;

export const TripsField = styled.div`
  position: relative;
  width: 100%;
`;

export const TripLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const Input = styled.input`
  margin-top: 4px;
  display: flex;
  align-items: center;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 30px 8px 16px;
  outline: none;
  background-color: transparent;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const SelectedValue = styled.div`
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  min-height: 37px;
`;

export const TripsSelect = styled.select`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  outline: none;
  padding: 6px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
  width: 100%;
  margin-top: 4px;
`;

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  padding: 10px;
`;

export const TripLoadBlock = styled.div`
  display: flex;
  margin: 8px 0;
`;

export const TripTitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-right: 5px;
`;

export const TripValue = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
`;

export const TripLoadButton = styled.div`
  margin-top: 16px;
  background: #616172;
  border-radius: 4px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  padding: 7px 8px;
  cursor: pointer;
`;

export const LoadsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const PaginationBlock = styled.div`
  margin: 32px 0;
`;

export const EmptyLoads = styled.p`
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 24px;
  color: #616172;
`;
