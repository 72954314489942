import React, { useState } from 'react';

import {
  BlueButton,
  DeliverText,
  DeliverTextEmpty,
  DocumentName,
  FlexBetween,
  InputFile,
  LoadsAttachFile,
  LoadsBlock,
  LoadsFlexColumn,
  LoadsImagePlace,
  LoadsInputFile,
  LoadsTitle,
} from './attachments.style';
import { Close, Document } from '../../../../assets/icons';

type AttachmentsProps = {
  attachment: any;
  setAttachment: any;
};

const Attachments: React.FC<AttachmentsProps> = ({ attachment, setAttachment }) => {
  const [attachmentName, setAttachmentName] = useState<string[]>([]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = event.target.files;
    const arr = Array.from(file || []);

    arr.forEach((elem: any) => {
      if (elem.size / 1024 / 1024 > 2) {
        alert(`${elem.name} is too big`);
      } else {
        setAttachment((prevfiles: any) => [...prevfiles, elem]);
        setAttachmentName((prevfiles: any) => [...prevfiles, elem.name]);
      }
    });
  };

  const deleteAttachments = (index: number) => {
    const dataName = [...attachmentName];
    dataName.splice(index, 1);
    setAttachmentName(dataName);
    const data = [...attachment];
    data.splice(index, 1);
    setAttachment(data);
  };

  const handleOpenDocument = (imageUrl: string) => {
    window.open(imageUrl, '_blank');
  };

  return (
    <>
      <LoadsBlock>
        <FlexBetween>
          <LoadsTitle>Attachments</LoadsTitle>
        </FlexBetween>
        <LoadsImagePlace>
          <LoadsInputFile>
            <InputFile
              type="file"
              accept=".pdf, .png, .jpg, .jpeg"
              multiple
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
            />
            <LoadsFlexColumn>
              <BlueButton>Upload attachments</BlueButton>
              <DeliverTextEmpty>or drag and drop files</DeliverTextEmpty>
            </LoadsFlexColumn>
          </LoadsInputFile>
        </LoadsImagePlace>

        {attachmentName.map((elem: any, id: number) => {
          const fileData = attachment[id];
          const url = URL.createObjectURL(fileData);

          return (
            <LoadsAttachFile key={id} clickable>
              <DocumentName onClick={() => handleOpenDocument(url)}>
                <Document />
                <DeliverText>{elem}</DeliverText>
              </DocumentName>
              <div onClick={() => deleteAttachments(id)}>
                <Close />
              </div>
            </LoadsAttachFile>
          );
        })}
      </LoadsBlock>
    </>
  );
};

export default Attachments;
