import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import {
  addExpense,
  addLoad,
  addVehicle,
  getDataForLoad,
  getLoadData,
  getLoads,
  importLoads,
  LoadData,
  LoadId,
  LoadItem,
  Loads,
  updateCustomer,
  updateDelivery,
  updateExpense,
  updateLoadDetails,
  updatePayment,
  updatePickup,
  updateVehicle,
} from '../../API/loads';

type InitialStateType = {
  loadsList: Loads;
  loading: boolean;
  error: ErrorType | null;
  loadInfo: LoadItem;
  loadId: LoadId;
  loadData: LoadData;
};

const initialState: InitialStateType = {
  loadsList: [],
  loadData: {},
  loading: false,
  error: null,
  loadId: {
    id: 0,
  },
  loadInfo: {
    load_id: '',
    internal_load_id: '',
    inspection_type: '',
    driver_instructions: '',
    status: '',
    points: [
      {
        business_name: '',
        street_address: '',
        city: '',
        state: '',
        date: '',
      },
      {
        business_name: '',
        street_address: '',
        city: '',
        state: '',
        date: '',
      },
    ],
    customer: {
      business_name: '',
      street_address: '',
      city: '',
      state: '',
    },
    payment: {
      price: null,
      method: '',
      driver_pay: null,
      date: '',
    },
    vehicles: [
      {
        type: '',
        vin: '',
        year: '',
        make: '',
        model: '',
        color: '',
        lot_number: '',
        price: null,
        inop: false,
        enclosed: false,
      },
    ],
  },
};

export const loadsSlice = createSlice({
  name: 'loads',
  initialState,
  reducers: {
    showLoadInfo: (state, action) => {
      state.loadInfo = action.payload;
    },
    getLoadId: (state, action) => {
      state.loadId = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getLoads.fulfilled, (state, action) => {
      state.loading = false;
      state.loadsList = action.payload;
      state.loadsList.sort((a: any, b: any) => b.id - a.id);
    });
    builder.addCase(getLoads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoads.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getLoadData.fulfilled, (state, action) => {
      state.loading = false;
      state.loadInfo = action.payload;
      state.error = null;
    });
    builder.addCase(getLoadData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoadData.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getDataForLoad.fulfilled, (state, action) => {
      state.loading = false;
      state.loadData = action.payload;
    });
    builder.addCase(getDataForLoad.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDataForLoad.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(addExpense.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateExpense.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(addLoad.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateLoadDetails.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updatePayment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePayment.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCustomer.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updatePickup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePickup.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateDelivery.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateDelivery.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateDelivery.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(addVehicle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addVehicle.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(updateVehicle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVehicle.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(importLoads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(importLoads.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export const { showLoadInfo, getLoadId, resetError } = loadsSlice.actions;

export default loadsSlice.reducer;
