import styled from 'styled-components';

export const PopupWrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  width: 220px;
  margin: 0 auto;
`;

export const Image = styled.img`
  display: flex;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 20px auto 16px;
`;

export const Name = styled.p`
  font-family: 'MontserratSemiBold';
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #51585c;
`;

export const Email = styled.p`
  font-family: 'Montserrat';
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #7d8285;
  margin-top: 8px;
`;

export const StreetName = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  margin-top: 8px;
`;

export const OnWay = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-top: 12px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c4c4c4;
  margin: 8px 0;
`;
