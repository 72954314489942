import * as React from 'react';
import { SVGProps } from 'react';

const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg width={12} height={12} cursor="pointer" {...props}>
    <path
      d="m6.742 6 3.73-3.84c.063-.063.01-.16-.086-.16H9.252a.248.248 0 0 0-.175.07L6 5.237 2.923 2.07A.243.243 0 0 0 2.748 2H1.614c-.097 0-.15.097-.087.16L5.258 6l-3.73 3.84a.092.092 0 0 0-.027.05.086.086 0 0 0 .01.054c.009.017.023.03.042.04.018.01.04.016.06.016h1.135c.067 0 .13-.026.175-.07L6 6.763 9.077 9.93c.043.044.107.07.175.07h1.134c.097 0 .15-.097.087-.16L6.742 6Z"
      fill="#9292A4"
    />
  </svg>
);

export default Close;
