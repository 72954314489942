import { Btn, Icon } from './button.style';

type ButtonProps = {
  text: string;
  icon?: object;
  pad?: string;
  margin?: string;
  borderRadius?: string;
  handleButton: () => void;
};

const Button: React.FC<ButtonProps> = ({
  text,
  pad,
  margin,
  borderRadius,
  icon,
  handleButton,
}) => {
  return (
    <>
      <Btn
        style={{
          padding: `${pad}`,
          margin: `${margin}`,
          borderRadius: `${borderRadius}`,
        }}
        onClick={handleButton}
      >
        {icon && <Icon>{icon}</Icon>}
        {text}
      </Btn>
    </>
  );
};

export default Button;
