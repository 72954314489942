import * as React from 'react';
import { SVGProps } from 'react';

type IconProps = {
  right?: string;
};

const Check: React.FC<IconProps> = (
  { right },
  props: SVGProps<SVGSVGElement>,
) => (
  <svg width={32} height={32} fill="none" {...props} style={{ position: 'relative', right: right }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m23.778 12.111-10.11 10.111-5.445-5.444 2.333-2.333 3.111 3.11 7.778-7.777 2.333 2.333Z"
      fill="#fff"
    />
  </svg>
);

export default Check;
