import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  HeaderLeft,
  HeaderWrapper,
  LogoOnMobile,
  LogoOnWeb,
  LogoWrapper,
  Phone,
  PhoneLink,
  Register,
  RegisterText,
} from './header.style';
import { ArrowRight, Logo, LogoMobile } from '../../assets/icons';

const Header: React.FC = () => {
  const navigation = useNavigate();

  return (
    <HeaderWrapper>
      <HeaderLeft>
      <LogoWrapper onClick={() => navigation('/')}><LogoOnWeb><Logo /></LogoOnWeb> <LogoOnMobile><LogoMobile /></LogoOnMobile></LogoWrapper>
          <Phone>
            <PhoneLink href="tel:+1 (800) 200 3592">
              +1 (800) 200 3592
            </PhoneLink>
          </Phone>
      </HeaderLeft>
      <Register>
        <ArrowRight />
        <RegisterText href="https://myturbodispatch.com/">Register</RegisterText>
      </Register>
    </HeaderWrapper>
  );
};

export default Header;
