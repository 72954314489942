import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const AuthHeaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;

  @media screen and (max-width: 720px) {
    position: fixed;
    top: 0;
    z-index: 100;
    box-shadow: 0px 2px 3px 0px #15183229;
  }

  @media screen and (max-width: 450px) {
    padding: 0 18px;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoOnWeb = styled.div`
  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const LogoOnMobile = styled.div`
  display: flex;
  align-items: center;

  @media screen and (min-width: 721px) {
    display: none;
  }
`;

export const Phone = styled.div`
  margin-left: 60px;

  @media screen and (max-width: 720px) {
    display: none;
  }
`;

export const PhoneLink = styled.a`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  text-decoration: none;
`;

export const Register = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Notification = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 35px;

  @media screen and (max-width: 450px) {
    display: none;
  }
`;

export const BurgerBlock = styled.div`
  margin-right: 35px;
`;

export const BurgerItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const ImageDefault = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #e9eaf8;
  border-radius: 50%;
  margin-right: 10px;
`;

export const ImageName = styled.div`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  text-transform: uppercase;
`;

export const Dropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const RegisterText = styled.a`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  text-decoration: none;
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 70px;
  right: 40px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(73, 79, 89, 0.15);
  border-radius: 4px;
  padding: 12px 0 0;
  min-width: 225px;
  z-index: 99;
`;

export const UserName = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #51585c;
  margin: 8px auto;
  text-transform: capitalize;
`;

export const UserEmail = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #7d8285;
  margin: 0 auto 8px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eceff1;
  margin: 16px 0;
`;

export const MenuBlock = styled.div`
  margin-bottom: 12px;
`;

export const MenuItem = styled.p`
  padding: 12px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #1e2529;
  cursor: pointer;

  &:hover {
    background-color: #eceff1;
  }
`;
