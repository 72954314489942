import moment from 'moment';
import React from 'react';

import {
  Actions,
  Activity,
  ActivityBlock,
  ActivityScroll,
  ActivityText,
  ActivityTitle,
  Block,
  Cancel,
  InfoItem,
  Title,
  TitleText,
  Top,
  ValueText,
  Wrapper,
} from './modalViewLoads.style';
import { useAppSelector } from '../../../../store/hooks';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  close: () => void;
  typeView: string;
  refPayment: any;
  loadData?: any;
};

const ModalViewLoads: React.FC<ModalAssignProps> = ({ close, typeView, refPayment, loadData }) => {
  const { loadInfo } = useAppSelector((state: { loads: any }) => state.loads);

  const loadInfoData = loadData ?? loadInfo;

  const formattedDate = loadInfoData.load.payment.date;
  return (
    <ModalPortal>
      <Wrapper ref={refPayment}>
        <Top>
          <Title>{typeView === 'Payment' ? 'View payment' : 'Activity'}</Title>
          <Actions>
            <Cancel onClick={close}>Close</Cancel>
          </Actions>
        </Top>
        <Block>
          {typeView === 'Payment' ? (
            <>
              <InfoItem>
                <TitleText>Price</TitleText>
                <ValueText>${loadInfoData.load.payment.price}</ValueText>
              </InfoItem>
              <InfoItem>
                <TitleText>Method</TitleText>
                <ValueText>{loadInfoData.load.payment.method}</ValueText>
              </InfoItem>
              <InfoItem>
                <TitleText>Broker Fee</TitleText>
                <ValueText>
                  {loadInfoData.load.payment.broker_fee ? `$${loadInfoData.load.payment.broker_fee}` : '-'}
                </ValueText>
              </InfoItem>
              <InfoItem>
                <TitleText>Driver Pay</TitleText>
                <ValueText>
                  {loadInfoData.load.payment.driver_pay ? `$${loadInfoData.load.payment.driver_pay}` : '-'}
                </ValueText>
              </InfoItem>
              <InfoItem>
                <TitleText>Date</TitleText>
                <ValueText>{moment(formattedDate).format('MM/DD/YYYY')}</ValueText>
              </InfoItem>
              <InfoItem>
                <TitleText>Paid Amount</TitleText>
                <ValueText>
                  {loadInfoData.load.payment.paid_amount ? `$${loadInfoData.load.payment.paid_amount}` : '-'}
                </ValueText>
              </InfoItem>
              <InfoItem>
                <TitleText>Reference Number</TitleText>
                <ValueText>
                  {loadInfoData.load.payment.reference_number ? loadInfoData.load.payment.reference_number : '-'}
                </ValueText>
              </InfoItem>
              <InfoItem>
                <TitleText>Notes</TitleText>
                <ValueText>{loadInfoData.load.payment.notes ? loadInfoData.load.payment.notes : '-'}</ValueText>
              </InfoItem>
              <InfoItem>
                <TitleText>Invoice ID</TitleText>
                <ValueText>
                  {loadInfoData.load.payment.invoice_id ? loadInfoData.load.payment.invoice_id : '-'}
                </ValueText>
              </InfoItem>
              <InfoItem>
                <TitleText>Invoice Notes</TitleText>
                <ValueText>
                  {loadInfoData.load.payment.invoice_notes ? loadInfoData.load.payment.invoice_notes : '-'}
                </ValueText>
              </InfoItem>
            </>
          ) : (
            <>
              <Activity>
                <ActivityScroll>
                  {loadInfoData?.load?.activity?.map((item: any, i: number) => {
                    return (
                      <ActivityBlock key={i}>
                        <ActivityTitle>{item?.title}</ActivityTitle>
                        <ActivityText>{item?.date}</ActivityText>
                        <ActivityText>{item?.user.email}</ActivityText>
                      </ActivityBlock>
                    );
                  })}
                </ActivityScroll>
              </Activity>
            </>
          )}
        </Block>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalViewLoads;
