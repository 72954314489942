import {useEffect, useState} from "react";

export function useDebounceValue<T> (value: T, delay: number): T {
    const [initialValue, setInitialValue] = useState<T>(value)

    useEffect(() => {
        const clearValue = setTimeout(() => {
            setInitialValue((_) => value)
        }, delay)
        return () => {
            clearInterval(clearValue)
        }
    })

    return initialValue
}