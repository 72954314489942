import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px 40px 24px;

  @media screen and (max-width: 720px) {
    padding-top: 100px;
  }

  @media screen and (max-width: 540px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 425px) {
    align-items: flex-start;
    width: 100%;
  }
`;

export const BackBlock = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #616172;

  @media screen and (max-width: 425px) {
    margin-bottom: 16px;
  }
`;

export const DriverBlock = styled.div`
  margin-top: 34px;
  display: flex;
  width: 100%;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const DriverContactWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 18%;
  margin-right: 24px;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const DriverContact = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 8px;
`;

export const DriverInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const DriverInfo = styled.div`
  width: 100%;
  padding: 12px 24px;
  background-color: #fff;
  border-radius: 8px;

  @media screen and (max-width: 425px) {
    padding: 12px;
  }
`;

export const DriverTop = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DriverStatus = styled.div`
  margin-top: 10px;
`;

export const PeriodDate = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #333443;
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
`;

export const Image = styled.img`
  width: 76px;
  height: 76px;
  border-radius: 50%;
`;

export const ImageDefault = styled.div`
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-color: #e9eaf8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageName = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -1px;
  color: #09091a;
  text-transform: uppercase;
  cursor: pointer;
`;

export const Active = styled.p`
  color: #0baa1b;
  font-family: 'MontserratBold';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 16px;
  background-color: rgba(11, 170, 27, 0.15);
  border-radius: 4px;
`;

export const Deactivated = styled.p`
  color: #9292a4;
  font-family: 'MontserratBold';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 16px;
  background-color: #e9eaf8;
  border-radius: 4px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
  margin: 24px auto;
`;

export const DriverContactBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DriverContactTitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const DriverContactText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
`;

export const AttachmentsTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #9292a4;
`;

export const BlueButton = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #0e71eb;
  cursor: pointer;
  padding-top: 21px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FilePlace = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 0 0;
`;

export const InputFilePlace = styled.div`
  border: 2px dashed #c6c7da;
  min-height: 60px;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputFile = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
`;

export const InputPlace = styled.img`
  max-width: 100%;
  height: 100%;
  border-radius: 4px;
  z-index: 2;
`;

export const TextEmpty = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  margin-left: 4px;
  word-break: break-word;
  width: 100%;
  padding-bottom: 21px;
`;

export const AttachFile = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DocumentName = styled.div`
  display: flex;
  align-items: center;
`;

export const DocumentText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  margin-left: 4px;
`;

export const DriverTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #616172;
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const Status = styled.a`
  display: inline-flex;
  height: 46px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 10px;
  font-family: 'MontserratBold';
  font-weight: '700';
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 5px 30px 5px 0;
  color: #0e71eb;
  background-color: #b0dcff;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

export const FlexBetween = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

export const FleetCalendar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9eaf8;
  border-radius: 4px;
  width: 21px;
  height: 21px;
  position: absolute;
  top: 80%;
  transform: translateY(-80%);
  right: 0;
`;

export const DriverClaims = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DriverClaim = styled.div`
  width: 49%;
  padding: 12px 24px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div``;

export const ClaimFlex = styled.div``;

export const ViewButton = styled.p`
  margin-top: 12px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #0e71eb;
  height: fit-content;
  text-transform: uppercase;
  border-bottom: 1px solid #0e71eb;
  cursor: pointer;
`;

export const LoadID = styled.p`
  border: 1px solid #9292a4;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;

  @media screen and (max-width: 525px) {
    width: 100%;
  }
`;

export const LoadName = styled.span`
  color: #09091a;
  margin-left: 5px;
  font-weight: 700;
`;

export const DriverSmallTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
`;

export const FleetTabs = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Tab = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: '600';
  font-size: 20px;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
`;

export const BorderBottom = styled.div`
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 2px;
  background-color: #1267cf;
`;

export const FleetBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const FleetData = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 720px) {
    width: 25%;
    margin-bottom: 12px;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #9292a4;
  border-radius: 4px;
  overflow: hidden;

  @media screen and (max-width: 650px) {
    width: 100%;
    margin-top: 16px;
  }
`;

export const TabPeriod = styled.div`
  text-align: center;
  width: 100%;
  padding: 6px 24px;
  cursor: pointer;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
`;

export const DriverEfficiency = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #f4f4f4;
  width: 20%;
  position: relative;

  &:last-child {
    border-right: 1px solid #f4f4f4;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
    margin-top: 24px;
    border: none;

    &:last-child {
      border: none;
    }
  }

  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;

export const PercentModal = styled.div`
  width: 265px;
  position: absolute;
  top: 80px;
  left: 30%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 15px 0px #494f5926;
  padding: 30px 6px 6px;
  z-index: 2;

  @media screen and (max-width: 650px) {
    left: 0;
  }

  @media screen and (max-width: 560px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const PercentNumber = styled.p`
  position: absolute;
  top: 16px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #333443;
`;

export const CurrentNumber = styled.p`
  position: absolute;
  top: 3px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #000;
`;

export const InputRange = styled.input`
  width: 100%;
`;

export const DriverEfficiencyTitle = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #000;
  margin-bottom: 17px;
`;

export const DriverEfficiencyData = styled.p`
  display: flex;
  align-items: center;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #000;
  padding: 7px;
  background-color: #e9eaf8;
  border-radius: 4px;
`;

export const ArrowBlock = styled.div`
  display: inline-flex;
  margin-left: 6px;
  cursor: pointer;
`;

export const DriverName = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #09091a;
`;

export const EditWrapper = styled.div`
  margin-left: 12px;
  cursor: pointer;
`;

export const Driver = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #000;

  @media screen and (max-width: 650px) {
    display: none;
  }
`;

export const DriverMobile = styled.p`
  display: none;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #000;

  @media screen and (max-width: 650px) {
    display: block;
  }
`;

export const DriverStatisticTitle = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #000;
  margin-bottom: 17px;
  margin-right: 7px;
`;

export const DriverStatistic = styled.div`
  margin-top: 33px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DriverStat = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const DriverLine = styled.div`
  width: 100%;
  height: 14px;
  background-color: #f4f4f4;
  border-radius: 6px;
  position: relative;
`;

export const ActiveDriverLine = styled.div`
  height: 14px;
  background-color: #0e71eb;
  border-radius: 6px;
  position: absolute;
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
`;

export const SelectedValueWithoutBorders = styled.div`
  width: 85%;
  position: absolute;
  top: 90%;
  transform: translateY(-90%);
  right: 30px;
  padding: 8px 0;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #000;
  background-color: #fff;
  border-radius: 4px;
  border: none;
  min-height: 37px;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 90%;
  transform: translateY(-80%);
  right: 0;
  padding: 10px;
`;

export const Select = styled.select`
  border-radius: 4px;
  outline: none;
  padding: 6px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
`;

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const PaginationBlock = styled.div`
  margin-top: 32px;
`;

export const InputPeriodDate = styled(DatePicker)`
  width: 100%;
  height: 100%;
  border: none;
  color: transparent;
  outline: none;
  background: transparent;
  cursor: pointer;
`;

export const InputDate = styled(DatePicker)`
  width: 100%;
  border: none;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  background: transparent;
  cursor: pointer;
`;