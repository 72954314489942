import * as React from "react"
import { SVGProps } from "react"

const ThreeDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      d="M12 10a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM12 16a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM12 4a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
      fill="#616172"
    />
  </svg>
)

export default ThreeDots
