import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Top,
  BackBlock,
  DriverBlock,
  DriverContactWrapper,
  DriverInfoWrapper,
  DriverContact,
  DriverInfo,
  DriverTop,
  DriverStatus,
  Image,
  ImageDefault,
  ImageName,
  Active,
  Deactivated,
  Line,
  DriverContactBlock,
  DriverContactTitle,
  DriverContactText,
  FilePlace,
  InputFilePlace,
  InputFile,
  FlexColumn,
  BlueButton,
  TextEmpty,
  DocumentName,
  DocumentText,
  AttachFile,
  AttachmentsTitle,
  DriverTitle,
  ButtonWrapper,
  Status,
  PeriodDate,
  FlexBetween,
  FleetCalendar,
  ClaimFlex,
  ViewButton,
  LoadID,
  LoadName,
  DriverSmallTitle,
  DriverClaims,
  DriverClaim,
  FleetTabs,
  Tab,
  BorderBottom,
  FleetBlock,
  FleetData,
  InputPeriodDate,
  InputDate,
  TabWrapper,
  TabPeriod,
  DriverEfficiency,
  DriverEfficiencyTitle,
  DriverEfficiencyData,
  ArrowBlock,
  PercentModal,
  InputRange,
  PercentNumber,
  CurrentNumber,
  DriverName,
  EditWrapper,
  Driver,
  DriverMobile,
  DriverStatistic,
  DriverStat,
  DriverStatisticTitle,
  DriverLine,
  ActiveDriverLine,
  InputField,
  SelectedValueWithoutBorders,
  FieldIcon,
  Select,
  Opt,
  PaginationBlock,
} from './driverItem.style';
import { addAttachments, deleteAttachment, deleteClaimAttachment, getClaims, getDriver } from '../../../API/drivers';
import { Back, Document, Close, Plus, Calendar, ArrowDown, Edit, SelectArrow } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import AuthHeader from '../../../components/Header/AuthHeader';
import ModalClaimDriver from '../../../layout/Modal/ModalDriver/ModalClaimDriver/ModalClaimDriver';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { showClaimInfo } from '../../../store/slices/driversSlice';
import './style.css';

const DriverItem: React.FC = () => {
  const { driverInfo, claims, claimInfo } = useAppSelector((state: { driver: any }) => state.driver);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ref = useRef<any>();
  const refDriver = useRef<any>();
  const [open, setOpen] = useState<boolean>(false);

  const [tab, setTab] = useState<string>('0');
  const [typeDriver, setTypeDriver] = useState<string>(driverInfo?.driver_type.toLowerCase());
  const [tabPeriod, setTabPeriod] = useState<string>('month');
  const [date, setDate] = useState<Date[] | string[]>([new Date(), new Date()]);
  const [startDate, endDate] = date;
  const [percent, setPercent] = useState<string>('50');
  const [showPercent, setShowPercent] = useState<boolean>(false);

  const [modalName, setModalName] = useState<string>('');
  const [actualTo, setActualTo] = useState<string | Date>(new Date());

  const [dispatchers, setDispatchers] = useState<string[]>(['William Hefner', 'Elem Hefner', 'Trea Hefner']);
  const [dispatcher, setDispatcher] = useState<string>('William Hefner');
  const [dispatcherValue, setDispatcherValue] = useState<string>('0');

  const [attachment, setAttachment] = useState<any[]>(driverInfo?.attachments);
  const formattedDate = driverInfo?.hire_at?.split('.');

  const PER_PAGE = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const [pageId, setPageId] = useState<number>(0);

  const offset = currentPage;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const pageCount = Math.ceil(claims.length / PER_PAGE);

  const currentPageData = claims
    .slice(offset * PER_PAGE - PER_PAGE, offset * PER_PAGE + PER_PAGE - PER_PAGE)
    .map((item: any) => {
      return (
        <DriverClaim key={item.id}>
          <FlexBetween style={{ alignItems: 'flex-start' }}>
            <ClaimFlex>
              <LoadID>
                Load ID:<LoadName>{item.load.load_id}</LoadName>
              </LoadID>
              <DriverContactBlock>
                <DriverContactTitle style={{ marginRight: '5px' }}>Brocker:</DriverContactTitle>
                <DriverContactText>Antony Smith</DriverContactText>
              </DriverContactBlock>
              <DriverContactBlock>
                <DriverContactTitle style={{ marginRight: '5px' }}>Vehicle:</DriverContactTitle>
                <DriverContactText>
                  {item.load.vehicles[0].year + ' ' + item.load.vehicles[0].make + ' ' + item.load.vehicles[0].model}
                </DriverContactText>
              </DriverContactBlock>
            </ClaimFlex>
            <ViewButton onClick={() => openModal('view', item)}>View</ViewButton>
          </FlexBetween>
          <Line />
          <DriverSmallTitle>Notes:</DriverSmallTitle>
          <DriverContactTitle style={{ marginTop: '8px' }}>{item.notes}</DriverContactTitle>
        </DriverClaim>
      );
    });

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: FileList | null = event.target.files;
    const arr = Array.from(file || []);
    let formData: any = new FormData();

    arr.forEach((elem: any) => {
      if (elem.size / 1024 / 1024 > 2) {
        alert(`${elem.name} is too big`);
      } else {
        formData.append(`attachments[]`, elem);
      }
    });

    await dispatch(addAttachments([pageId, formData]));
    await dispatch(
      getDriver([
        pageId,
        tabPeriod.toUpperCase(),
        moment(date[0]).format('DD.MM.YYYY'),
        moment(date[1]).format('DD.MM.YYYY'),
      ])
    );
  };

  const deleteAttachments = async (index: number) => {
    await dispatch(deleteAttachment([pageId, index]));
    await dispatch(
      getDriver([
        pageId,
        tabPeriod.toUpperCase(),
        moment(date[0]).format('DD.MM.YYYY'),
        moment(date[1]).format('DD.MM.YYYY'),
      ])
    );
  };

  const deleteClaimAttachments = async (index: number) => {
    await dispatch(deleteClaimAttachment(index));
    await dispatch(getClaims(pageId));
  };

  const openModal = (name: string, data?: any) => {
    setOpen(true);
    setModalName(name);

    dispatch(showClaimInfo(data));
  };

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showPercent && ref.current && !ref.current.contains(e.target)) {
        setShowPercent(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showPercent]);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useEffect(() => {
    const queryParams = window.location.pathname;
    if (queryParams.slice(0, 9) === '/drivers/') {
      const value: number = parseInt(queryParams.slice(9));
      setPageId(value);
      if (pageId !== 0) {
        if (tabPeriod === 'period' && (date[0] === null || date[1] === null)) {
        } else {
          dispatch(
            getDriver([
              pageId,
              tabPeriod.toUpperCase(),
              moment(date[0]).format('DD.MM.YYYY'),
              moment(date[1]).format('DD.MM.YYYY'),
            ])
          );
        }
        dispatch(getClaims(pageId));
      }
    }
  }, [pageId, tabPeriod, date[0], date[1]]);

  useEffect(() => {
    setAttachment(driverInfo?.attachments);
  }, [driverInfo]);

  useEffect(() => {
    if (claims !== undefined) {
      const item = claims.find((item: any) => item.id === claimInfo?.id);
      dispatch(showClaimInfo(item));
    }
  }, [claims]);

  useOnClickOutside(refDriver, () => setOpen(false));

  return (
    <>
      <AuthHeader />
      <Wrapper>
        <Top>
          <BackBlock onClick={() => navigate('/drivers')}>
            <Back fill="#616172" /> Drivers
          </BackBlock>
        </Top>

        <DriverBlock>
          <DriverContactWrapper>
            <DriverContact>
              <DriverTop>
                {driverInfo.image ? (
                  <Image src={driverInfo?.image} />
                ) : (
                  <ImageDefault>
                    <ImageName>
                      {driverInfo.first_name[0]} {driverInfo.last_name[0]}
                    </ImageName>
                  </ImageDefault>
                )}
                <DriverStatus>
                  {driverInfo.status === 'active' ? <Active>Active</Active> : <Deactivated>Deactivated</Deactivated>}
                </DriverStatus>
              </DriverTop>
              <Line />
              <AttachmentsTitle>PROFILE INFORMATION</AttachmentsTitle>
              <DriverContactBlock>
                <DriverContactTitle>Hire Date:</DriverContactTitle>
                <DriverContactText>{moment(formattedDate).format('MM/DD/yyyy')}</DriverContactText>
              </DriverContactBlock>
              <DriverContactBlock>
                <DriverContactTitle>Age:</DriverContactTitle>
                <DriverContactText>{driverInfo?.age} y.o</DriverContactText>
              </DriverContactBlock>
              <DriverContactBlock>
                <DriverContactTitle>Deposit:</DriverContactTitle>
                <DriverContactText>${driverInfo?.deposit}</DriverContactText>
              </DriverContactBlock>
            </DriverContact>
            <DriverContact>
              <AttachmentsTitle>Attachments</AttachmentsTitle>
              <FilePlace>
                <InputFilePlace>
                  <InputFile type="file" onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)} />
                  <FlexColumn>
                    <BlueButton>Upload attachments</BlueButton>
                    <TextEmpty>or drag and drop files</TextEmpty>
                  </FlexColumn>
                </InputFilePlace>
              </FilePlace>
              {attachment?.map((elem: any) => {
                return (
                  <AttachFile key={elem.id}>
                    <DocumentName>
                      <Document />
                      <DocumentText>document({elem.id})</DocumentText>
                    </DocumentName>
                    <div onClick={() => deleteAttachments(elem.id)}>
                      <Close />
                    </div>
                  </AttachFile>
                );
              })}
            </DriverContact>
          </DriverContactWrapper>
          <DriverInfoWrapper>
            <DriverInfo style={{ marginBottom: '9px' }}>
              <FlexBetween>
                <Top>
                  <DriverName>
                    {driverInfo.first_name + ' ' + driverInfo.last_name} <DriverMobile>Driver</DriverMobile>
                  </DriverName>
                  <EditWrapper>
                    <Edit />
                  </EditWrapper>
                </Top>
                <TabWrapper>
                  <TabPeriod
                    style={{
                      color: typeDriver === 'dl_a' ? '#0E71EB' : '#333443',
                      backgroundColor: typeDriver === 'dl_a' ? '#B0DCFF' : 'transparent',
                    }}
                    onClick={() => setTypeDriver('dl_a')}
                  >
                    DL-A
                  </TabPeriod>
                  <TabPeriod
                    style={{
                      borderLeft: '1px solid #9292A4',
                      color: typeDriver === 'owner' ? '#0E71EB' : '#333443',
                      backgroundColor: typeDriver === 'owner' ? '#B0DCFF' : 'transparent',
                    }}
                    onClick={() => setTypeDriver('owner')}
                  >
                    Owner
                  </TabPeriod>
                </TabWrapper>
              </FlexBetween>
              <Driver>Driver</Driver>
              <FlexBetween style={{ alignItems: 'flex-start' }}>
                <DriverStat>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                    }}
                  >
                    <DriverStatistic style={{ flexDirection: 'row' }}>
                      <DriverStatisticTitle>19</DriverStatisticTitle>
                      <DriverEfficiencyTitle>working day</DriverEfficiencyTitle>
                    </DriverStatistic>
                    <DriverStatistic style={{ flexDirection: 'row' }}>
                      <DriverStatisticTitle>6</DriverStatisticTitle>
                      <DriverEfficiencyTitle>weekends</DriverEfficiencyTitle>
                    </DriverStatistic>
                  </div>
                  <DriverLine>
                    <ActiveDriverLine style={{ width: `${(19 / 25) * 100}%` }} />
                  </DriverLine>
                </DriverStat>
                <DriverStatistic>
                  <DriverEfficiencyTitle style={{ marginBottom: '6px' }}>Dispatcher:</DriverEfficiencyTitle>
                  <DriverStatisticTitle>
                    <InputField style={{ marginBottom: 0 }}>
                      <SelectedValueWithoutBorders>{dispatcher}</SelectedValueWithoutBorders>
                      <FieldIcon>
                        <SelectArrow />
                      </FieldIcon>
                      <Select
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                          changeSelectState(e, setDispatcher, setDispatcherValue);
                        }}
                      >
                        {dispatchers?.map((elem: any, i: number) => {
                          return (
                            <Opt key={i} value={elem} data-value={elem.value}>
                              {elem}
                            </Opt>
                          );
                        })}
                      </Select>
                    </InputField>
                  </DriverStatisticTitle>
                </DriverStatistic>
                <DriverStatistic>
                  <DriverEfficiencyTitle style={{ marginBottom: '13px' }}>Efficiency:</DriverEfficiencyTitle>
                  <DriverStatisticTitle>100%</DriverStatisticTitle>
                </DriverStatistic>
              </FlexBetween>
            </DriverInfo>
            <DriverInfo>
              <FlexBetween>
                <DriverSmallTitle>Driver Efficiency</DriverSmallTitle>
                <div style={{ position: 'relative' }}>
                  <TabWrapper>
                    <TabPeriod
                      style={{
                        color: tabPeriod === 'month' ? '#0E71EB' : '#333443',
                        backgroundColor: tabPeriod === 'month' ? '#B0DCFF' : 'transparent',
                      }}
                      onClick={() => setTabPeriod('month')}
                    >
                      Month
                    </TabPeriod>
                    <TabPeriod
                      style={{
                        borderLeft: '1px solid #9292A4',
                        borderRight: '1px solid #9292A4',
                        color: tabPeriod === 'week' ? '#0E71EB' : '#333443',
                        backgroundColor: tabPeriod === 'week' ? '#B0DCFF' : 'transparent',
                      }}
                      onClick={() => setTabPeriod('week')}
                    >
                      Week
                    </TabPeriod>
                    <TabPeriod
                      style={{
                        color: tabPeriod === 'period' ? '#0E71EB' : '#333443',
                        backgroundColor: tabPeriod === 'period' ? '#B0DCFF' : 'transparent',
                      }}
                      onClick={() => setTabPeriod('period')}
                    >
                      Period
                      <InputPeriodDate
                        wrapperClassName="react-datepicker__wrapper"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MM/dd/yyyy"
                        onChange={(update: any) => setDate(update)}
                      />
                    </TabPeriod>
                  </TabWrapper>
                  <PeriodDate>
                    {moment(startDate).format('MM/DD/yyyy') + ' - ' + moment(endDate).format('MM/DD/yyyy')}
                  </PeriodDate>
                </div>
              </FlexBetween>
              <FleetBlock>
                <DriverEfficiency>
                  <DriverEfficiencyTitle>Gross:</DriverEfficiencyTitle>
                  <DriverEfficiencyData>${driverInfo?.efficiency?.gross}</DriverEfficiencyData>
                </DriverEfficiency>
                <DriverEfficiency>
                  <DriverEfficiencyTitle>Miles:</DriverEfficiencyTitle>
                  <DriverEfficiencyData>{driverInfo?.efficiency?.miles}</DriverEfficiencyData>
                </DriverEfficiency>
                <DriverEfficiency>
                  <DriverEfficiencyTitle>$ / Miles:</DriverEfficiencyTitle>
                  <DriverEfficiencyData>${driverInfo?.efficiency?.earn_per_mile}</DriverEfficiencyData>
                </DriverEfficiency>
                <DriverEfficiency>
                  <DriverEfficiencyTitle>Percent:</DriverEfficiencyTitle>
                  <DriverEfficiencyData>
                    {percent}%
                    <ArrowBlock onClick={() => setShowPercent(true)}>
                      <ArrowDown />
                    </ArrowBlock>
                  </DriverEfficiencyData>
                  {showPercent && (
                    <PercentModal ref={ref}>
                      <PercentNumber style={{ left: '5px' }}>0%</PercentNumber>
                      <PercentNumber style={{ right: '5px' }}>100%</PercentNumber>
                      <CurrentNumber
                        style={{
                          left: `${percent}%`,
                          transform: `translateX(${-percent}%)`,
                        }}
                      >
                        {percent}%
                      </CurrentNumber>
                      <InputRange
                        type="range"
                        min="0"
                        max="100"
                        defaultValue={percent}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPercent(e.target.value)}
                        step="1"
                      />
                    </PercentModal>
                  )}
                </DriverEfficiency>
                <DriverEfficiency>
                  <DriverEfficiencyTitle>Payble:</DriverEfficiencyTitle>
                  <DriverEfficiencyData>$2,750.00</DriverEfficiencyData>
                </DriverEfficiency>
              </FleetBlock>
            </DriverInfo>
            <DriverTitle>Fleet</DriverTitle>
            <DriverInfo>
              <FleetTabs>
                <Tab
                  style={{
                    color: tab === '0' ? '#1267CF' : '#616172',
                  }}
                  onClick={() => setTab('0')}
                >
                  Truck {tab === '0' && <BorderBottom />}
                </Tab>

                <Tab
                  style={{
                    color: tab === '1' ? '#1267CF' : '#616172',
                  }}
                  onClick={() => setTab('1')}
                >
                  Trailer {tab === '1' && <BorderBottom />}
                </Tab>
              </FleetTabs>
              <Line />
              <FleetBlock>
                <FleetData>
                  <DriverSmallTitle style={{ marginBottom: '6px' }}>TRUCK</DriverSmallTitle>
                  <DriverContactTitle>TRUCK#1</DriverContactTitle>
                </FleetData>
                <FleetData>
                  <DriverSmallTitle style={{ marginBottom: '6px' }}>Year</DriverSmallTitle>
                  <DriverContactTitle>2021</DriverContactTitle>
                </FleetData>
                <FleetData>
                  <DriverSmallTitle style={{ marginBottom: '6px' }}>Make</DriverSmallTitle>
                  <DriverContactTitle>Ford</DriverContactTitle>
                </FleetData>
                <FleetData>
                  <DriverSmallTitle style={{ marginBottom: '6px' }}>Model</DriverSmallTitle>
                  <DriverContactTitle>F305</DriverContactTitle>
                </FleetData>
                <FleetData>
                  <DriverSmallTitle style={{ marginBottom: '6px' }}>Color</DriverSmallTitle>
                  <DriverContactTitle>White</DriverContactTitle>
                </FleetData>
                <FleetData>
                  <DriverSmallTitle style={{ marginBottom: '6px' }}>State</DriverSmallTitle>
                  <DriverContactTitle>IL</DriverContactTitle>
                </FleetData>
                <FleetData>
                  <DriverSmallTitle style={{ marginBottom: '6px' }}>VIN #</DriverSmallTitle>
                  <DriverContactTitle>1FT8W3DT8LED</DriverContactTitle>
                </FleetData>
                <FleetData style={{ width: '120px' }}>
                  <DriverSmallTitle style={{ marginBottom: '6px' }}>ACTUAL TO</DriverSmallTitle>
                  <FleetTabs>
                    <FleetCalendar>
                      <Calendar />
                    </FleetCalendar>
                    <InputDate
                      wrapperClassName="w-full"
                      id="birthday"
                      dateFormat="MM/dd/yyyy"
                      selected={actualTo}
                      onChange={(date: any) => setActualTo(date)}
                    />
                  </FleetTabs>
                </FleetData>
              </FleetBlock>
            </DriverInfo>
            <DriverTitle>Paperwork</DriverTitle>
            <DriverInfo>
              <Status target="_blank" href={driverInfo?.medcard}>
                <Document color="#0E71EB" />
                <p style={{ marginLeft: '5px' }}>medcard</p>
              </Status>
              <Status target="_blank" href={driverInfo?.driver_license}>
                <Document color="#0E71EB" />
                <p style={{ marginLeft: '5px' }}>driver license</p>
              </Status>
              <Status target="_blank" href={driverInfo?.contract}>
                <Document color="#0E71EB" />
                <p style={{ marginLeft: '5px' }}>contract</p>
              </Status>
            </DriverInfo>
            <FlexBetween style={{ flexDirection: 'row' }}>
              <DriverTitle>Claims</DriverTitle>
              <ButtonWrapper>
                <Button text="Claim" pad="10px 20px" icon={<Plus />} handleButton={() => openModal('new')} />
              </ButtonWrapper>
            </FlexBetween>
            <DriverClaims>{claims.length >= 1 && currentPageData}</DriverClaims>
            {claims.length > 6 && (
              <PaginationBlock>
                <Pagination count={pageCount} page={currentPage} shape="rounded" onChange={handlePageChange} />
              </PaginationBlock>
            )}
          </DriverInfoWrapper>
        </DriverBlock>
      </Wrapper>

      {open && (
        <ModalClaimDriver
          refDriver={refDriver}
          addCheck={modalName}
          deleteClaimAttachments={deleteClaimAttachments}
          close={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default DriverItem;
