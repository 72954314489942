import { useNavigate } from 'react-router-dom';

import {
  SidebarWrapper,
  Top,
  TopTitle,
  Wrapper,
  StyledLink,
  Logout,
  MenuItem,
  Text,
  VerticalLine,
  Line,
} from './sidebarSettings.style';
import { Back } from '../../assets/icons';
import { useAppDispatch } from '../../store/hooks';
import { setLogout } from '../../store/slices/authSlice';

const SidebarSettings: React.FC = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <SidebarWrapper>
      <Top onClick={() => navigation('/dashboard')}>
        <Back fill="#616172" />
        <TopTitle>TMS</TopTitle>
      </Top>
      <Wrapper>
        <StyledLink to="/settings">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Settings
                </Text>{' '}
              </MenuItem>
              {isActive && <VerticalLine />}
            </>
          )}
        </StyledLink>
        <StyledLink to="/carrier-profile">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Carrier Profile
                </Text>
              </MenuItem>{' '}
              {isActive && <VerticalLine />}
            </>
          )}
        </StyledLink>
        <StyledLink to="/dispatcher-profile">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Dispatcher Profile
                </Text>
              </MenuItem>{' '}
              {isActive && <VerticalLine />}
            </>
          )}
        </StyledLink>
        <Line />
        <StyledLink to="/help-center">
          {({ isActive }: any) => (
            <>
              <MenuItem>
                <Text
                  style={{
                    color: isActive ? '#0E71EB' : '#09091a',
                    fontFamily: isActive ? 'MontserratSemiBold' : 'Montserrat',
                    fontWeight: isActive ? 600 : 400,
                  }}
                >
                  Help Center
                </Text>{' '}
              </MenuItem>
              {isActive && <VerticalLine />}
            </>
          )}
        </StyledLink>
        <Logout
          onClick={() => {
            dispatch(setLogout());
            localStorage.setItem('token', '');
            navigation('/');
          }}
        >
          Logout
        </Logout>
      </Wrapper>
    </SidebarWrapper>
  );
};

export default SidebarSettings;
