import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

type LoginUserType = {
  email: string;
  password: string;
};

type ResetPasswordType = {
  email: string;
};

type RestorePasswordType = {
  token: string;
  password: string;
  password_confirmation: string;
};

export type AxiosResponseLogin = {
  access_token: string;
  expire_in: number;
};

export type User = {
  id: number;
  first_name: string;
  last_name: string;
  image: null;
  phone: string;
  email: string;
  usdot: string;
  company_name: string;
};

export const routes = {
  login: 'api/auth/login',
  resetPassword: 'api/auth/password/reset',
  restorePassword: 'api/auth/password/restore',
  me: 'api/auth/me',
  verifyEmail: 'api/auth/verify',
  createChangeEmail: 'api/auth/start-change-email',
  finishChangeEmail: 'api/auth/finish-change-email',
};

export const loginUser = createAsyncThunk<
  AxiosResponseLogin,
  LoginUserType,
  {
    rejectValue: ErrorType;
  }
>('auth/loginUser', async (loginData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.login}`, loginData);
    localStorage.setItem('token', response.data.access_token);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const resetPassword = createAsyncThunk<
  null,
  ResetPasswordType,
  {
    rejectValue: ErrorType;
  }
>('auth/resetPassword', async (resetPass, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.resetPassword}`, resetPass);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const restorePassword = createAsyncThunk<
  null,
  RestorePasswordType,
  {
    rejectValue: ErrorType;
  }
>('auth/restorePassword', async (restorePass, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.restorePassword}`, restorePass);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const getUser = createAsyncThunk<
  User,
  null,
  {
    rejectValue: ErrorType;
  }
>('auth/getUser', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.me}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const verifyEmail = createAsyncThunk<
  null,
  string,
  {
    rejectValue: ErrorType;
  }
>('dispatcherProfile/verifyEmail', async (token, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.verifyEmail}/${token}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const createChangeEmail = createAsyncThunk<
  null,
  string,
  {
    rejectValue: ErrorType;
  }
>('dispatcherProfile/createChangeEmail', async (email, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.createChangeEmail}`, {
      email: email,
    });
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const finishChangeEmail = createAsyncThunk<
  null,
  [string, string],
  {
    rejectValue: ErrorType;
  }
>(
  'dispatcherProfile/finishChangeEmail',
  async ([email, token], { rejectWithValue }) => {
    try {
      const response = await api.post(`${routes.finishChangeEmail}`, {
        email: email,
        token: token,
      });
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue({
        message: e.message,
        errors: e.errors,
      } as ErrorType);
    }
  },
);
