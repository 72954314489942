import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export type ActiveDriver = {
  id?: number;
  first_name: string;
  last_name: string;
  image: string | null;
  email: string;
  phone: string;
  trailer_capacity: string | null;
  driver_license: string | null;
  issuing_state: string | null;
};

export type DriverTracking = {
  driver_id: number;
  longitude: string;
  latitude: string;
  time: number;
}

export type ActiveDrivers = ActiveDriver[];

export type DriverTrackingArray = DriverTracking[];

export const routes = {
  tracking: 'api/crm/tracking',
};

export const getActiveDrivers = createAsyncThunk<
  ActiveDrivers,
  null,
  {
    rejectValue: ErrorType;
  }
>('trips/getActiveDrivers', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.tracking}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});
