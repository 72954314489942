import { SVGProps } from "react"

const FleetCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.25 5.813h-3.188l-.759-2.129a.751.751 0 0 0-.708-.497h-7.19c-.317 0-.6.2-.706.497l-.761 2.128H3.75c-1.036 0-1.875.84-1.875 1.875v10.688c0 1.036.84 1.875 1.875 1.875h16.5c1.036 0 1.875-.84 1.875-1.875V7.687c0-1.035-.84-1.875-1.875-1.875Zm.188 12.562a.188.188 0 0 1-.188.188H3.75a.188.188 0 0 1-.188-.188V7.687c0-.103.085-.187.188-.187h4.376l.4-1.12.537-1.505h5.871l.537 1.505.4 1.12h4.379c.103 0 .188.084.188.188v10.687ZM12 9a3.749 3.749 0 0 0-3.75 3.75A3.749 3.749 0 0 0 12 16.5a3.749 3.749 0 0 0 3.75-3.75A3.749 3.749 0 0 0 12 9Zm0 6a2.25 2.25 0 1 1 .001-4.501A2.25 2.25 0 0 1 12 15Z"
      fill="#9292A4"
    />
  </svg>
)

export default FleetCamera;
