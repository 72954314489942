import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '.';

export type Drivers = [];

export type Attachments = [];

export type Claims = [];

export type DriverItem = {
  id?: number;
  first_name: string;
  last_name: string;
  password?: string;
  password_confirmation?: string;
  email: string;
  phone: string;
  status: string;
  image: string;
  deposit?: number;
  birthday: string;
  hire_at?: string;
  driver_type: string;
  truck_id?: string;
  trailer_id?: string;
  driver_license: string;
  medcard: string;
  contract: string;
  age?: number;
  claims?: Claims;
  attachments?: Attachments;
  efficiency?: {};
};

export const routes = {
  drivers: 'api/crm/drivers',
  claims: 'api/crm/driver-claims',
};

export const getDrivers = createAsyncThunk<
  Drivers,
  null,
  {
    rejectValue: ErrorType;
  }
>('drivers/getDrivers', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.drivers}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const getDriver = createAsyncThunk<
  DriverItem,
  [number, string, string, string],
  {
    rejectValue: ErrorType;
  }
>(
  'drivers/getDriver',
  async (
    [id, efficiency_period_type, date_from, date_to],
    { rejectWithValue },
  ) => {
    try {
      let reg = '&';
      if (efficiency_period_type === 'PERIOD') {
        reg += `efficiency_period_from=${date_from}&efficiency_period_to=${date_to}&`;
      }

      const response = await api.get(
        `${
          routes.drivers
        }/${id}?efficiency_period_type=${efficiency_period_type}${reg.slice(
          0,
          -1,
        )}`,
      );
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue({
        message: e.message,
        errors: e.errors,
      } as ErrorType);
    }
  },
);

export const addDriver = createAsyncThunk<
  null,
  DriverItem,
  {
    rejectValue: ErrorType;
  }
>('drivers/addDriver', async (createData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.drivers}`, createData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateDriver = createAsyncThunk<
  null,
  [DriverItem, number],
  {
    rejectValue: ErrorType;
  }
>('drivers/updateDriver', async ([updateData, id], { rejectWithValue }) => {
  try {
    const response = await api.post(
      `${routes.drivers}/${id}/update`,
      updateData,
    );
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateDriverStatus = createAsyncThunk<
  null,
  DriverItem,
  {
    rejectValue: ErrorType;
  }
>('drivers/updateDriverStatus', async (updateData, { rejectWithValue }) => {
  try {
    const response = await api.post(
      `${routes.drivers}/${updateData.id}/status`,
      { action: updateData.status === 'active' ? 'deactivate' : 'reactivate' },
    );
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const deleteDriver = createAsyncThunk<
  null,
  number,
  {
    rejectValue: ErrorType;
  }
>('drivers/deleteDriver', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${routes.drivers}/${id}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const addAttachments = createAsyncThunk<
  null,
  [number, Attachments],
  {
    rejectValue: ErrorType;
  }
>('drivers/addAttachments', async ([id, updateData], { rejectWithValue }) => {
  try {
    const response = await api.post(
      `${routes.drivers}/${id}/attachments/create`,
      updateData,
    );
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const deleteAttachment = createAsyncThunk<
  null,
  [number, number],
  {
    rejectValue: ErrorType;
  }
>(
  'drivers/deleteAttachment',
  async ([id, attachment_id], { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `${routes.drivers}/${id}/attachments/${attachment_id}`,
      );
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue({
        message: e.message,
        errors: e.errors,
      } as ErrorType);
    }
  },
);

export const getClaims = createAsyncThunk<
  Claims,
  number,
  {
    rejectValue: ErrorType;
  }
>('drivers/getClaims', async (id, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.claims}/${id}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const createClaim = createAsyncThunk<
  null,
  [number, FormData],
  {
    rejectValue: ErrorType;
  }
>('drivers/createClaim', async ([id, formdata], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.claims}/${id}/create`, formdata);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateClaim = createAsyncThunk<
  null,
  [number, FormData],
  {
    rejectValue: ErrorType;
  }
>('drivers/updateClaim', async ([id, formdata], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.claims}/${id}/update`, formdata);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const deleteClaim = createAsyncThunk<
  null,
  number,
  {
    rejectValue: ErrorType;
  }
>('drivers/deleteClaim', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${routes.claims}/${id}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const deleteClaimAttachment = createAsyncThunk<
  null,
  number,
  {
    rejectValue: ErrorType;
  }
>(
  'drivers/deleteClaimAttachment',
  async (attachment_id, { rejectWithValue }) => {
    try {
      const response = await api.delete(
        `${routes.claims}/${attachment_id}/attachment`,
      );
      return response.data.data;
    } catch (e: any) {
      return rejectWithValue({
        message: e.message,
        errors: e.errors,
      } as ErrorType);
    }
  },
);