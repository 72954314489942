import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 8px;
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  margin: 12px auto;
  width: 95%;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 16px;
  outline: none;
`;

export const ListWrapper = styled.div`
  height: calc(100vh - 285px);
  overflow: auto;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  overflow: hidden;

  &:hover {
    background-color: #f4f4f4;
    border-radius: 4px;
  }
`;

export const Image = styled.img`
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  border-radius: 50%;
`;

export const ImageHover = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  padding: 6px;
  height: 32px;
  border-radius: 50%;
  background-color: #e9eaf8;
`;

export const ImageDefault = styled.div`
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-color: #e9eaf8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageName = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -1px;
  color: #09091A;
  text-transform: uppercase;
`;

export const Name = styled.p`
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #09091a;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
`;
