import DatePicker from 'react-datepicker';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-left: 32px;

  @media screen and (max-width: 720px) {
    margin-left: 0;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.p`
  display: flex;
  align-items: center;
  color: #616172;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  cursor: pointer;

  @media screen and (max-width: 1300px) {
    margin-bottom: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const ReportList = styled.div`
  margin-top: 30px;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
`;

export const ReportListScroll = styled.div`
  overflow-x: scroll;
`;

export const ReportTitleBlock = styled.div`
  display: grid;
  gap: 5px;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const ReportTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #09091a;
  text-transform: uppercase;
  white-space: nowrap;

  @media screen and (max-width: 1400px) {
    min-width: 150px;
  }
`;

export const ReportTitleCompany = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #09091a;
  text-transform: uppercase;

  @media screen and (max-width: 1400px) {
    min-width: 300px;
  }
`;

export const ReportDataBlock = styled.div`
  display: grid;
  gap: 5px;
  align-items: flex-start;
  height: 70px;
`;

export const ReportDataTitle = styled.div`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  white-space: nowrap;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1400px) {
    min-width: 150px;
  }
`;

export const ReportDataTitleCompany = styled.div`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1400px) {
    min-width: 300px;
  }
`;

export const ViewOrders = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  cursor: pointer;
  margin-top: 5px;
`;

export const LoadsInputField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 90%;
  transform: translateY(-80%);
  right: 0;
  padding: 10px;
`;

export const InputDate = styled(DatePicker)`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background-color: transparent;
  outline: none;
  padding: 7px 16px;
  min-width: 278px;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;