import * as React from 'react';
import { SVGProps } from 'react';

const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M13.798 7 18 11.202v1.176l-4.202 4.202-1.193-1.177 2.756-2.773H6v-1.68h9.361l-2.773-2.774L13.798 7Z"
      fill="#252736"
    />
  </svg>
);

export default ArrowRight;
