import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export type Fleet = [];

export type FleetData = [];

export type Driver = {
  id?: number;
  first_name?: string;
  last_name?: string;
}

export type Trailer = {
  id?: number;
}

export type FleetItem = {
  id?: number;
  type?: string;
  vin: string;
  plate: string;
  pass: string;
  state: string;
  make: string;
  model: string;
  year: string;
  color: string;
  owner: string;
  trailer?: Trailer;
  insurance?: string;
  registration?: string;
  list_agreement?: string;
  additional?: string;
  annual_inspection_at: string;
  plate_expired_at: string;
  driver?: Driver;
  fleet_type_id: number | null;
  driver_id?: number | null;
  trailer_id?: number | null;
};

export type ImageType = {
  type: string;
  file?: string;
};

export const routes = {
  fleet: 'api/crm/fleets',
};

export const getFleet = createAsyncThunk<
  Fleet,
  null,
  {
    rejectValue: ErrorType;
  }
>('fleets/getFleet', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.fleet}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const getFleetType = createAsyncThunk<
  FleetData,
  null,
  {
    rejectValue: ErrorType;
  }
>('fleets/getFleetType', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.fleet}/create`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});
export const getDataForOneFleet = createAsyncThunk<
    FleetData,
    number,
    {
        rejectValue: ErrorType;
    }
>('fleets/getDataForOneFleet', async (id, { rejectWithValue }) => {
    try {
        const response = await api.get(`${routes.fleet}/${id}`);
        return response.data.data;
    } catch (e: any) {
        return rejectWithValue({
            message: e.message,
        } as ErrorType);
    }
});
export const addFleet = createAsyncThunk<
  null,
  FleetItem,
  {
    rejectValue: ErrorType;
  }
>('fleets/addFleet', async (createData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.fleet}`, createData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const updateFleet = createAsyncThunk<
  null,
  [FleetItem, number],
  {
    rejectValue: ErrorType;
  }
>('fleets/updateFleet', async ([updateData, id], { rejectWithValue }) => {
  try {
    const response = await api.put(`${routes.fleet}/${id}`, updateData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});

export const addFleetExpense = createAsyncThunk<
    null,
    [FleetItem, number],
    {
        rejectValue: ErrorType;
    }
>('fleets/addFleetExpense', async ([updateData, id], { rejectWithValue }) => {
    try {
        const response = await api.post(`${routes.fleet}/${id}/expenses`, updateData);
        return response.data.data;
    } catch (e: any) {
        return rejectWithValue({
            message: e.message,
            errors: e.errors,
        } as ErrorType);
    }
});

export const deleteFleet = createAsyncThunk<
  null,
  number,
  {
    rejectValue: ErrorType;
  }
>('fleets/deleteFleet', async (id, { rejectWithValue }) => {
  try {
    const response = await api.delete(`${routes.fleet}/${id}`);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const attachImage = createAsyncThunk<
  null,
  [number, ImageType],
  {
    rejectValue: ErrorType;
  }
>('fleets/attachImage', async ([id, imageData], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.fleet}/${id}/attach`, imageData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const detachImage = createAsyncThunk<
  null,
  [number, ImageType],
  {
    rejectValue: ErrorType;
  }
>('fleets/detachImage', async ([id, imageData], { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.fleet}/${id}/detach`, imageData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const exportFleets = createAsyncThunk<
  null,
  ImageType,
  {
    rejectValue: ErrorType;
  }
>('fleets/exportFleets', async (type, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.fleet}/export`, type);
    return response.data.message;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});
