import React, { useEffect, useRef, useState } from 'react';

import {
  Wrapper,
  Titles,
  Line,
  LineBlock,
  ScrollBlock,
  ShowActions,
  DropdownMenu,
  MenuBlock,
  MenuItem,
  Item,
  RegularText,
  BoldText,
  Bottom,
  Actions,
  Cancel,
} from './modalFleetNotes.style';
import { ThreeDots } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import ModalPortal from '../../ModalPortal';

type ModalAssignProps = {
  close: () => void;
  setOpenAddNotes: (state: boolean) => void;
  refNotes: any;
};

const ModalFleetNotes: React.FC<ModalAssignProps> = ({
  close,
  setOpenAddNotes,
  refNotes,
}) => {
  const ref = useRef<any>();
  const [showActions, setShowActions] = useState<boolean>(false);

  const addNotes = () => {
    close();
    setOpenAddNotes(true);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (showActions && ref.current && !ref.current.contains(e.target)) {
        setShowActions(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showActions]);

  return (
    <ModalPortal>
      <Wrapper ref={refNotes}>
        <Titles>
          <BoldText>Driver</BoldText>
          <BoldText>Date</BoldText>
          <BoldText>Note</BoldText>
        </Titles>
        <Line />
        <ScrollBlock>
          <Item>
            <ShowActions onClick={() => setShowActions(!showActions)}>
              <ThreeDots />

              {showActions && (
                <DropdownMenu ref={ref}>
                  <MenuBlock>
                    <MenuItem>Pin to screen</MenuItem>
                    <MenuItem>Delete</MenuItem>
                  </MenuBlock>
                </DropdownMenu>
              )}
            </ShowActions>
            <RegularText>Henfry Frei</RegularText>
            <RegularText>19/08/2022</RegularText>
            <RegularText>
              In ultricies. Ornare sit et molestie id elit. Leo, ipsum
              vestibulum sed sit urna dictum dapibus imperdiet dapibus platea
              vulputate ex. Velit interdum
            </RegularText>
          </Item>
          <LineBlock />
        </ScrollBlock>

        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={addNotes}>Add</Cancel>
            <Button text="Close" pad="12px 26px" handleButton={close} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalFleetNotes;
