import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import {
  addTrip,
  getTripId,
  getTrips,
  Trip,
  TripId,
  Trips,
} from '../../API/trips';

type InitialStateType = {
  tripsList: Trips;
  loading: boolean;
  error: ErrorType | null;
  tripElem: Trip;
  tripId: TripId;
};

const initialState: InitialStateType = {
  tripsList: [],
  loading: false,
  error: null,
  tripElem: {},
  tripId: {
    id: 0,
  },
};

export const tripSlice = createSlice({
  name: 'trip',
  initialState,
  reducers: {
    getTripID: (state, action) => {
      state.tripId = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(getTrips.fulfilled, (state, action) => {
      state.loading = false;
      state.tripsList = action.payload;
      state.tripsList.sort((a: any, b: any) => b.id - a.id);
    });
    builder.addCase(getTrips.pending, state => {
      state.loading = true;
    });
    builder.addCase(getTrips.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getTripId.fulfilled, (state, action) => {
      state.loading = false;
      state.tripElem = action.payload;
    });
    builder.addCase(getTripId.pending, state => {
      state.loading = true;
    });
    builder.addCase(getTripId.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(addTrip.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export const { getTripID } = tripSlice.actions;

export default tripSlice.reducer;
