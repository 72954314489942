import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 32px;
  background: #ffffff;
  border-radius: 8px;

  @media screen and (max-width: 600px) {
    padding: 32px 16px;
  }
`;

export const CheckboxBlock = styled.div`
  position: relative;
  margin: 32px 0;
`;

export const LearnMore = styled.span`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 12px;
  color: #0E71EB;
  cursor: pointer;
  margin-left: 5px;
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #09091a;
  margin-bottom: 32px;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;
