import { useEffect, useRef, useState } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import {
  Wrapper,
  Top,
  Info,
  Image,
  ImageDefault,
  ImageName,
  Name,
  Actions,
  Edit,
  Delete,
  Line,
  ContactInfo,
  ContactInfoText,
  ContactInfoTextDefault,
} from './contactsItem.style';
import { deleteContact, getContacts } from '../../../API/contacts';
import {
  AddressForm,
  ContactsForm,
  EmailForm,
  PersonForm,
} from '../../../assets/icons';
import ModalContact from '../../../layout/Modal/ModalContact/ModalContact';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { showContactInfo } from '../../../store/slices/contactsSlice';

const ContactsItem: React.FC = () => {
  const { contactInfo } = useAppSelector(
    (state: { contact: any }) => state.contact,
  );
  const dispatch = useAppDispatch();
  const refContact = useRef<any>();

  const [open, setOpen] = useState<boolean>(false);

  const openModal = () => setOpen(true);

  const handleToDelete = async () => {
    await dispatch(deleteContact(contactInfo.id));
    await dispatch(getContacts(null));
    await dispatch(showContactInfo({}));
  };

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useOnClickOutside(refContact, () => setOpen(false));

  return (
    <Wrapper>
      {Object.keys(contactInfo).length !== 0 && (
        <>
          <Top>
            <Info>
              {contactInfo.image ? (
                <Image src={contactInfo.image} />
              ) : (
                <ImageDefault>
                  <ImageName>
                    {contactInfo.name[0]} {contactInfo.name[1]}
                  </ImageName>
                </ImageDefault>
              )}
              <Name>{contactInfo.name}</Name>
            </Info>
            <Actions>
              <Edit onClick={openModal}>Edit</Edit>
              <Delete onClick={handleToDelete}>Delete</Delete>
            </Actions>
          </Top>
          <Line />
          <ContactInfo>
            {contactInfo.city || contactInfo.address ? (
              <>
                <AddressForm data={contactInfo.city || contactInfo.address} />{' '}
                <ContactInfoText>
                  {contactInfo.city} {contactInfo.address}
                </ContactInfoText>
              </>
            ) : (
              <>
                <AddressForm data={contactInfo.city || contactInfo.address} />{' '}
                <ContactInfoTextDefault>No address</ContactInfoTextDefault>
              </>
            )}
          </ContactInfo>
          <ContactInfo>
            {contactInfo.contact_name ? (
              <>
                <PersonForm data={contactInfo.contact_name} />{' '}
                <ContactInfoText>{contactInfo.contact_name}</ContactInfoText>
              </>
            ) : (
              <>
                <PersonForm data={contactInfo.contact_name} />{' '}
                <ContactInfoTextDefault>No contact name</ContactInfoTextDefault>
              </>
            )}
          </ContactInfo>
          <ContactInfo>
            {contactInfo.email ? (
              <>
                <EmailForm data={contactInfo.email} />{' '}
                <ContactInfoText>{contactInfo.email}</ContactInfoText>
              </>
            ) : (
              <>
                <EmailForm data={contactInfo.email} />{' '}
                <ContactInfoTextDefault>No email</ContactInfoTextDefault>
              </>
            )}
          </ContactInfo>
          <ContactInfo>
            {contactInfo.phone ? (
              <>
                <PersonForm data={contactInfo.phone} />{' '}
                <ContactInfoText>
                  {formatPhoneNumberIntl(contactInfo.phone)}
                </ContactInfoText>
              </>
            ) : (
              <>
                <PersonForm data={contactInfo.phone} />{' '}
                <ContactInfoTextDefault>No phone number</ContactInfoTextDefault>
              </>
            )}
          </ContactInfo>
          <ContactInfo>
            {contactInfo.fax ? (
              <>
                <PersonForm data={contactInfo.fax} />{' '}
                <ContactInfoText>{contactInfo.fax}</ContactInfoText>
              </>
            ) : (
              <>
                <PersonForm data={contactInfo.fax} />{' '}
                <ContactInfoTextDefault>No fax</ContactInfoTextDefault>
              </>
            )}
          </ContactInfo>
          <ContactInfo>
            {contactInfo.notes ? (
              <>
                <ContactsForm data={contactInfo.notes} />{' '}
                <ContactInfoText>{contactInfo.notes}</ContactInfoText>
              </>
            ) : (
              <>
                <ContactsForm data={contactInfo.notes} />{' '}
                <ContactInfoTextDefault>No notes</ContactInfoTextDefault>
              </>
            )}
          </ContactInfo>
        </>
      )}

      {open && (
        <ModalContact
          refContact={refContact}
          contactId={contactInfo.id}
          text="Edit contact"
          close={() => setOpen(false)}
        />
      )}
    </Wrapper>
  );
};

export default ContactsItem;
