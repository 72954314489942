import * as React from 'react';
import { SVGProps } from 'react';

const ArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8.72 3.487 4.5 2 5.89l6 5.61 6-5.61-1.487-1.39L8 8.72Z"
      fill="#09091A"
    />
  </svg>
);

export default ArrowDown;
