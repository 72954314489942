import React from 'react';

import {
  BlueButton,
  FlexBetween,
  LoadsBlock,
  LoadsTextSemiBold,
  LoadsTextVehicle,
  LoadsTitle,
  VehicleDelete,
  VehicleEdit,
  VehicleItem,
  VehicleItemText,
} from '../loadsItem.style';

type LoadsItemProps = {
  setVehicleModal: () => void;
  setAddCheckVehicle?: any;
  setHover: any;
  setDeleteVehicle: () => void;
  loadInfo: any;
  hover: string;
  vehicleItemRef: any;
  vehicleRef: any;
  vehicleModel: any;
  vehicleMake: any;
  vehicleYear: any;
};

const LoadItemVehicle: React.FC<LoadsItemProps> = ({
  setVehicleModal,
  setAddCheckVehicle,
  setDeleteVehicle,
  setHover,
  loadInfo,
  hover,
  vehicleItemRef,
  vehicleRef,
  vehicleModel,
  vehicleMake,
  vehicleYear,
}) => {
  return (
    <>
      <LoadsBlock>
        <FlexBetween>
          <LoadsTitle>Vehicle</LoadsTitle>
          {setAddCheckVehicle && (
            <BlueButton
              className="noPrint"
              onClick={() => {
                setVehicleModal();
                setAddCheckVehicle(true);
              }}
            >
              Add
            </BlueButton>
          )}
        </FlexBetween>
        <FlexBetween>
          <LoadsTextVehicle>{loadInfo?.load?.vehicles?.length} Vehicle</LoadsTextVehicle>
        </FlexBetween>
        {loadInfo?.load?.vehicles?.map((item: any) => {
          return (
            <VehicleItem key={item.id} onMouseOver={() => setHover(item.id)} onMouseLeave={() => setHover('')}>
              <LoadsTextSemiBold>{item.year + ' ' + item.make + ' ' + item.model}</LoadsTextSemiBold>
              <VehicleItemText>{item?.type}</VehicleItemText>
              <VehicleItemText>{item?.vin}</VehicleItemText>
              {hover === item.id && (
                <>
                  <VehicleEdit
                    onClick={() => {
                      setVehicleModal();
                      setAddCheckVehicle(false);
                      vehicleItemRef.current = item;
                    }}
                  >
                    Edit
                  </VehicleEdit>
                  <VehicleDelete
                    onClick={() => {
                      setDeleteVehicle();
                      vehicleRef.current = item.id;
                      vehicleModel.current = item.model;
                      vehicleMake.current = item.make;
                      vehicleYear.current = item.year;
                    }}
                  >
                    Delete
                  </VehicleDelete>
                </>
              )}
            </VehicleItem>
          );
        })}
      </LoadsBlock>
    </>
  );
};

export default LoadItemVehicle;
