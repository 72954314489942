import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';

import { ErrorType } from '../../API';
import {
  getPreviewBOL,
  getPreviewInvoice,
  PreviewInvoice,
  sendBOL,
  sendInvoice,
} from '../../API/invoice';

type InitialStateType = {
  invoice: PreviewInvoice;
  loading: boolean;
  error: ErrorType | null;
};

const initialState: InitialStateType = {
  invoice: '',
  loading: false,
  error: null,
};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(sendInvoice.pending, state => {
      state.loading = true;
    });
    builder.addCase(sendInvoice.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getPreviewInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.invoice = action.payload;
    });
    builder.addCase(getPreviewInvoice.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPreviewInvoice.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(sendBOL.pending, state => {
      state.loading = true;
    });
    builder.addCase(sendBOL.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
    builder.addCase(getPreviewBOL.fulfilled, (state, action) => {
      state.loading = false;
      state.invoice = action.payload;
    });
    builder.addCase(getPreviewBOL.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPreviewBOL.rejected, (state, action) => {
      state.error = action.payload ? action.payload : null;
      state.loading = false;
    });
  },
});

export default invoiceSlice.reducer;
