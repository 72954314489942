import React, { useEffect, useState } from 'react';

import {
  Wrapper,
  Top,
  Drivers,
  DriversLength,
  ActionList,
  Input,
  ListWrapper,
  List,
  Item,
  Image,
  ImageDefault,
  ImageName,
  Name,
  ButtonWrapper,
} from './driverList.style';
import { ActiveDrivers, DriverTracking } from '../../../API/tracking';
import { useAppDispatch } from '../../../store/hooks';
import { setActiveDriversTracking } from '../../../store/slices/trackingSlice';
import Button from '../../Button/Button';

type DriverListProps = {
  activeDriverList: ActiveDrivers;
  actionList: boolean;
  setActionList: (actionList: boolean) => void;
};

const ActiveDriversList: React.FC<DriverListProps> = ({
  activeDriverList,
  actionList,
  setActionList,
}) => {
  const [value, setValue] = useState<string>('');
  const [isActive, setIsActive] = useState<number[]>([]);

  const [data, setData] = useState<DriverTracking[]>([]);

  const dispatch = useAppDispatch();

  const selectDriver = (id: number) => {
    if (!isActive.includes(id)) setIsActive([...isActive, id]);
    else setIsActive(isActive.filter((elem: number) => elem !== id));
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const filteredPersons = activeDriverList.filter((item: any) => {
    return item.first_name.toLowerCase().includes(value.toLowerCase());
  });

  const showSelectedDrivers = () => {
    isActive.forEach((id: number) => {
      window.Echo.private(`tracking.${id}`).listen(
        'UpdateDriverLocationEvent',
        (e: any) => {
          setData([
            ...data,
            {
              driver_id: e.driver_id,
              longitude: e.longitude,
              latitude: e.latitude,
              time: e.time,
            },
          ]);
        },
      );
    });
  };

  useEffect(() => {
    dispatch(setActiveDriversTracking(data));
  }, [data]);

  return (
    <>
      <Wrapper>
        <Top
          style={{ justifyContent: actionList ? 'center' : 'space-between' }}
        >
          <Drivers style={{ display: actionList ? 'none' : 'block' }}>
            Drivers <DriversLength>({activeDriverList.length})</DriversLength>
          </Drivers>
          <ActionList onClick={() => setActionList(!actionList)}>
            {actionList ? 'show' : 'hide'}
          </ActionList>
        </Top>
        <Input
          type="text"
          value={value}
          onChange={handleInput}
          placeholder="Search..."
          style={{ display: actionList ? 'none' : 'block' }}
        />
        <ListWrapper>
          <List>
            {filteredPersons.map((elem: any) => {
              return (
                <Item
                  style={{
                    backgroundColor: isActive.includes(elem.id)
                      ? '#f4f4f4'
                      : '#fff',
                  }}
                  key={elem.id}
                  onClick={() => {
                    selectDriver(elem.id);
                  }}
                >
                  {elem.image ? (
                    <Image src={elem.image} />
                  ) : (
                    <ImageDefault>
                      <ImageName>
                        {elem.first_name[0]} {elem.last_name[0]}
                      </ImageName>
                    </ImageDefault>
                  )}

                  <Name style={{ display: actionList ? 'none' : 'block' }}>
                    {elem.first_name} {elem.last_name}
                  </Name>
                </Item>
              );
            })}
          </List>
        </ListWrapper>
        <ButtonWrapper style={{ display: actionList ? 'none' : 'block' }}>
          <Button
            text="Show on map"
            pad="10px 20px"
            handleButton={showSelectedDrivers}
          />
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};

export default ActiveDriversList;
