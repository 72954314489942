import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Wrapper,
  Top,
  Title,
  Length,
  Actions,
  ImportBlock,
  ImportText,
  ContactBlock,
  ClientBlock,
  ClientItem,
  ButtonWrapper,
} from './contacts.style';
import { getContacts } from '../../API/contacts';
import { Plus, Import } from '../../assets/icons';
import Button from '../../components/Button/Button';
import ContactsItem from '../../components/Contacts/ContactsItem/ContactsItem';
import ContactsList from '../../components/Contacts/ContactsList/ContactsList';
import Container from '../../layout/Container';
import ModalContact from '../../layout/Modal/ModalContact/ModalContact';
import useOnClickOutside from '../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Preloader from '../Preloader/Preloader';

const Contacts: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const refContact = useRef<any>();

  const { contactsList, loading } = useAppSelector(
    (state: { contact: any }) => state.contact,
  );

  const openModal = () => setOpen(true);

  useEffect(() => {
    dispatch(getContacts(null));
  }, []);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  useOnClickOutside(refContact, () => setOpen(false));

  if (loading) return <Preloader />;

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <Title>
              Contacts <Length>({contactsList.length})</Length>{' '}
            </Title>
            <Actions>
              <ImportBlock onClick={() => navigate('/contacts/download')}>
                <Import /> <ImportText>Import</ImportText>
              </ImportBlock>
              <ButtonWrapper>
                <Button
                  text="Add Contact"
                  pad="10px 20px"
                  icon={<Plus />}
                  handleButton={openModal}
                />
              </ButtonWrapper>
            </Actions>
          </Top>
          <ContactBlock>
            <ClientBlock>
              <ContactsList />
            </ClientBlock>
            <ClientItem>
              <ContactsItem />
            </ClientItem>
          </ContactBlock>

          {open && (
            <ModalContact
            refContact={refContact}
              addCheck={true}
              text="Add contact"
              close={() => setOpen(false)}
            />
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default Contacts;
