import Menu from '@mui/material/Menu';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin-left: 32px;
`;

export const Active = styled.span`
  font-family: 'MontserratBold';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 16px;
  border-radius: 4px;
`;

export const MenuBlock = styled(Menu)`
  border-radius: 4px;
  text-align: center;
`;

export const MenuItem = styled.p`
  min-width: 125px;
  padding: 12px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #1e2529;
  cursor: pointer;

  &:hover {
    background-color: #eceff1;
  }
`;

export const MenuItemRed = styled.p`
  padding: 12px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #f30f00;
  cursor: pointer;

  &:hover {
    background-color: #eceff1;
  }
`;
