import { SVGProps } from "react"

const DashboardDelayedPickups = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.723 15.051 12.023 20 7 14.76l1.75-1.867 2.023 2.133V12.62l2.5 1.022v1.386l.931-1.005 2.52 1.03Zm-3.45-3.57V4h-2.5v6.459l2.5 1.021Z"
      fill="#F9BB01"
    />
    <path fill="#F9BB01" d="M4 7.851 4.756 6l15.968 6.526-.756 1.851z" />
  </svg>
)

export default DashboardDelayedPickups
