import { Drawer } from '@mui/material';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 49%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 32px;
  position: relative;

  @media screen and (max-width: 1370px) {
    width: 100%;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 525px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

export const TextStrong = styled.span`
  font-weight: bold;
  font-family: 'MontserratSemiBold';
`;

export const LoadID = styled.p`
  border: 1px solid #9292a4;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  cursor: pointer;
  ${(props) =>
    props.elipsis &&
    css`
      max-width: 50%;
      text-wrap: nowrap;
    `}

  @media screen and (max-width: 525px) {
    width: 100%;
  }
`;

export const LoadName = styled.span`
  color: #09091a;
  margin-left: 5px;
  font-weight: 700;

  ${(props) =>
    props.elipsis &&
    css`
      display: inline-block;
      max-width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 525px) {
    margin-top: 16px;
    justify-content: space-between;
    width: 100%;
  }
`;

export const Action = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  margin-right: 32px;
  cursor: pointer;
`;

export const More = styled.div`
  display: flex;
  cursor: pointer;
`;

export const DriverInfo = styled.div`
  margin-top: 20px;
`;

export const LoadsFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadsFlexColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 525px) {
    margin-top: 15px;
  }
`;

export const DriverExtra = styled.div`
  display: flex;
  align-items: center;
`;

export const LoadsDays = styled.p`
  margin-left: 16px;
  color: #000;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
`;

export const LoadsPayMent = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
`;

export const PopoverContent = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  padding: 10px;
`;

export const LoadsAssigned = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #616172;
  cursor: pointer;
`;

export const LoadsPhone = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #616172;
  margin-top: 10px;
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  font-family: 'MontserratBold';
  font-weight: '700';
  font-size: 12px;
  text-transform: uppercase;
`;

export const CarInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 20px;
`;

export const CarInfo = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Car = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-right: 12px;

  @media screen and (max-width: 600px) {
    margin-bottom: 5px;
    margin-right: 0;
  }
`;

export const Type = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #616172;
  margin-right: 12px;

  @media screen and (max-width: 600px) {
    margin-bottom: 5px;
    margin-right: 0;
  }
`;

export const Vin = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #616172;

  @media screen and (max-width: 600px) {
    margin-bottom: 5px;
  }
`;

export const CarName = styled.span`
  color: #09091a;
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 525px) {
    flex-direction: column;
  }
`;

export const DetailBlock = styled.div`
  width: 32%;

  @media screen and (max-width: 600px) {
    width: 50%;
    margin-bottom: 14px;
  }

  @media screen and (max-width: 525px) {
    width: 100%;
  }
`;

export const DetailTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const DetailText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  line-height: 22px;

  ${(props) =>
    props.elipsis &&
    css`
      width: 90%;
      text-overflow: ellipsis;
      overflow: hidden;
    `}
`;

export const DropdownMenu = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 15px rgba(73, 79, 89, 0.15);
  border-radius: 4px;
  padding: 12px 0 0;
  min-width: 160px;
  z-index: 99;
`;

export const Title = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  padding: 32px;

  @media screen and (max-width: 425px) {
    margin-bottom: 16px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eceff1;
  margin: 0;
`;

export const MenuBlock = styled.div`
  margin-bottom: 12px;
`;

export const MenuItem = styled.p`
  padding: 8px 12px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;

  color: ${(props) => {
    switch (props.type) {
      case 'warning':
        return '#F30F00';
      default:
        return '#09091A';
    }
  }};

  &:hover {
    background-color: #eceff1;
  }
`;
