import React from 'react';

import { dollarSignDecorator } from '../../../../shared/utils/common';
import {
  BlueButton,
  FlexBetween,
  FlexTopBetween,
  LoadsBlock,
  LoadsEdit,
  LoadsTextEmpty,
  LoadsTextRegular,
  LoadsTextSemiBold,
  LoadsTitle,
  PaymentButton,
} from '../loadsItem.style';

type LoadsItemProps = {
  setPaymentModal?: () => void;
  setViewPayment: () => void;
  setTypeVeiw: () => void;
  loadInfo: any;
};

const LoadsItemPayment: React.FC<LoadsItemProps> = ({ setPaymentModal, setViewPayment, setTypeVeiw, loadInfo }) => {
  return (
    <>
      <LoadsBlock>
        <FlexBetween>
          <LoadsTitle>Payment</LoadsTitle>
          {setPaymentModal && (
            <LoadsEdit className="noPrint" onClick={setPaymentModal}>
              Edit
            </LoadsEdit>
          )}
        </FlexBetween>
        <FlexTopBetween>
          <LoadsTextRegular>Price</LoadsTextRegular>
          <LoadsTextSemiBold>{dollarSignDecorator(loadInfo?.load?.payment?.price)}</LoadsTextSemiBold>
        </FlexTopBetween>
        <FlexTopBetween>
          <LoadsTextRegular>Method</LoadsTextRegular>
          <LoadsTextSemiBold>{loadInfo?.load?.payment?.method}</LoadsTextSemiBold>
        </FlexTopBetween>
        <FlexTopBetween>
          <LoadsTextRegular>Broker fee</LoadsTextRegular>
          {loadInfo?.load?.payment?.broker_fee ? (
            <LoadsTextSemiBold>{dollarSignDecorator(loadInfo?.load?.payment?.broker_fee)}</LoadsTextSemiBold>
          ) : (
            <LoadsTextEmpty>No details</LoadsTextEmpty>
          )}
        </FlexTopBetween>
        <FlexTopBetween>
          <LoadsTextRegular>Driver pay</LoadsTextRegular>
          {loadInfo?.load?.payment?.driver_pay > 0 ? (
            <LoadsTextSemiBold>{dollarSignDecorator(loadInfo?.load?.payment?.driver_pay)}</LoadsTextSemiBold>
          ) : (
            <LoadsTextEmpty>No details</LoadsTextEmpty>
          )}
        </FlexTopBetween>
        <PaymentButton>
          <BlueButton
            className="noPrint"
            onClick={() => {
              setViewPayment();
              setTypeVeiw();
            }}
          >
            View notes
          </BlueButton>
        </PaymentButton>
      </LoadsBlock>
    </>
  );
};

export default LoadsItemPayment;
