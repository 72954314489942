import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 32px 40px 24px;

  @media screen and (max-width: 720px) {
    padding-top: 100px;
  }

  @media screen and (max-width: 540px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const TopTitle = styled.p`
  display: flex;
  align-items: center;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #616172;
  cursor: pointer;
`;

export const TruckInfo = styled.div`
  display: flex;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const TopPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 425px) {
    width: 100%;
    margin-top: 8px;
  }
`;

export const ImageTruck = styled.div`
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
  background-color: #000;
`;

export const SpanText = styled.span`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
`;

export const RegularText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const BoldExpenseText = styled.span`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #000;
`;

export const SemiBoldText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 20px;
  color: #09091a;
  margin-bottom: 10px;
`;

export const BoldText = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #616172;
  margin-bottom: 10px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #9292a4;
  margin-bottom: 32px;
`;

export const FleetBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const FleetTruckBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const TruckBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 1480px) {
    flex-direction: column;
  }
`;

export const FleetBlockDetail = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FleetSectionBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;

  @media screen and (max-width: 1280px) {
    width: 78%;
  }
`;

export const FleetSectionBlockBig = styled.div`
  display: flex;
  flex-direction: column;
  width: 62%;

  @media screen and (max-width: 1480px) {
    width: 100%;
  }
`;

export const FleetSectionBlockSmall = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;

  @media screen and (max-width: 1480px) {
    width: 100%;
  }
`;

export const FleetSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

export const FleetInfoItemColumn = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: #fff;
  padding: 22px;
`;

export const FleetInfoItemColumnAttachments = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: #fff;
  padding: 22px;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

export const FleetInfoBlockTruck = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;

  @media screen and (max-width: 1400px) {
    width: 30%;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 720px) {
    width: 45%;
  }

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

export const FleetInfoItemTrailer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  background-color: #fff;
`;

export const FleetInfoItemColumnExpense = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #fff;
`;

export const ExpenseFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 0 24px 0 #aeaeae1a;
  border-top: 1px solid #e8e8e8;
  padding: 14px 22px;
`;

export const ExpenseItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 22px;
`;

export const ExpenseProfile = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExpenseLogo = styled.div`
  min-width: 46px;
  min-height: 46px;
  max-width: 46px;
  max-height: 46px;
  border-radius: 50%;
  background-color: #000;
  margin-right: 8px;
`;

export const ExpenseData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ExpenseDataForPrice = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ExpenseName = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 14px;
  color: #252736;
`;

export const ExpenseDate = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
`;

export const ExpensePrice = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #252736;
  display: flex;
  justify-content: flex-end;
`;

export const ExpenseLoad = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
`;

export const ExpensePhoto = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #0e71eb;
  border-left: 1px solid #e8e8e8;
  padding-left: 10px;
  margin-left: 10px;
  cursor: pointer;
`;

export const FleetInfoItem = styled.div`
  width: 18%;
  height: fit-content;
  border-radius: 8px;
  background-color: #fff;
  padding: 24px 24px 8px;

  @media screen and (max-width: 1280px) {
    width: 20%;
  }
`;

export const FleetInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
`;

export const FleetInfoMilesBlock = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
`;

export const FleetExpenseBlock = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 100%;
  height: 220px;
`;

export const FleetInfoLabel = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

export const MessageItem = styled.div`
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const InlineFlex = styled.div`
  display: inline-flex;
  align-items: flex-start;
`;

export const Status = styled.div`
  display: inline-flex;
  height: auto;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
`;

export const ButtonBlock = styled.div`
  display: inline-flex;
  align-items: center;

  @media screen and (max-width: 425px) {
    margin-top: 16px;
  }
`;
