import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 70%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
  max-height: 90%;
  overflow: auto;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  border-radius: 8px;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TripName = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const InputWrapper = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  padding: 8px 30px 8px 16px;
  outline: none;
  background-color: transparent;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #616172;

  &::placeholder {
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    color: #616172;
  }
`;

export const LoadsListBlock = styled.div`
  padding: 16px 0 0;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const LoadsWrapper = styled.div`
  height: 500px;
  overflow: scroll;

  @media screen and (max-width: 1024px) {
    height: 300px;
  }
`;

export const LoadsTitle = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr repeat(3, 2fr) 3fr;
  align-items: center;
  gap: 15px;
  margin-bottom: 24px;
  padding: 0 24px;
`;

export const LoadsTitleItem = styled.div`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
    padding: 8px 16px;
  }
`;

export const AddLoad = styled.div`
  display: none;
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0e71eb;
  border-radius: 4px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  padding: 8px 16px;
  z-index: 2;

  @media screen and (max-width: 768px) {
    top: 8%;
  }
`;

export const LoadsItem = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr repeat(3, 2fr) 3fr;
  align-items: center;
  gap: 15px;
  padding: 24px;
  cursor: pointer;
  position: relative;
  background-color: transparent;

  &:hover {
    background-color: #F4F4F4;

    ${AddLoad} {
      display: block;
    }
  }
`;

export const LoadData = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  word-break: break-all;

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const LoadStatus = styled.span`
  max-width: fit-content;
  color: #0baa1b;
  font-family: 'MontserratBold';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 16px;
  background-color: rgba(11, 170, 27, 0.15);
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    margin: 0 16px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
`;

export const BottomBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 28px;
`;

export const Close = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  cursor: pointer;
`;
