import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const BoldText = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #252736;
`;

export const RegularText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #333443;
  margin-left: 12px;
`;

export const Bar = styled.div`
  width: 75%;
  background: #f4f4f4;
  border-radius: 6px;
  height: 12px;
  position: relative;
`;

export const Width = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 12px;
  background: #0e71eb;
  border-radius: 6px;
`;

export const Bottom = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #000;
`;
