import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 50%;
  border-radius: 8px;
  display: flex;
  position: relative;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -60px;
`;

export const Image = styled.img`
  margin: 0 auto;
  width: 50%;
  border-radius: 8px;
`;
