import styled from 'styled-components';

export const Block = styled.div`
  margin: 0 auto;
  max-width: 600px;
`;

export const Reset = styled.div`
  margin: 40px auto 0;
  padding: 40px;
  background-color: #fff;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
`;

export const BackTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #09091a;
`;

export const ResetEmail = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  margin-bottom: 16px;
`;

export const AuthBlock = styled.div`
  position: relative;
  padding-bottom: 30px;
`;

export const Error = styled.span`
  font-family: 'Montserrat';
  position: absolute;
  bottom: 0;
  font-weight: 400;
  font-size: 14px;
  color: #f30f00;
`;

export const Margin = styled.div`
  margin-top: 40px;
`;

export const Footer = styled.p`
  text-align: center;
  margin-top: 20px;
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #9292a4;
  text-transform: uppercase;

  @media screen and (max-width: 375px) {
    width: 250px;
    margin: 20px auto 0;
  }
`;
