import React, { useState } from 'react';

import {
  Block,
  TopTitle,
  Title,
  Delete,
  ImagePlace,
  InputFilePlace,
  InputFile,
  InputPlace,
  BlueButton,
  ButtonSave,
  Bottom,
  Error,
} from './w9.style';
import {
  CarrierProfile,
  getCarrierProfile,
  saveW9,
} from '../../../API/carrierProfile';
import Button from '../../../components/Button/Button';
import { useAppDispatch } from '../../../store/hooks';

type CarrierProps = {
  data: CarrierProfile;
};

const W9: React.FC<CarrierProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const [imageFile, setImageFile] = useState<File | string>('');
  const [image, setImage] = useState<string>(data?.w9 ? data?.w9 : '');

  const [err, setErr] = useState({
    message: '',
    errors: { w9: [] },
  });

  const handleConfirm = async () => {
    let formData = new FormData();
    formData.append(`w9`, imageFile);
    const res: any = await dispatch(saveW9(formData));
    if (res.type === 'carrierProfile/saveW9/rejected')
      return setErr(res.payload);
    dispatch(getCarrierProfile(null));
  };

  const handleChange = async (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    let file: File = (target.files as FileList)[0];
    if (file.size / 1024 / 1024 > 2) {
      alert('File is too big!');
    } else {
      const base64: any = await convertBase64(file);
      setImage(base64);
      setImageFile(file);
    }
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  return (
    <Block>
      <TopTitle>
        <Title>W9 *</Title>
        {image && (
          <Delete
            onClick={() => {
              setImage('');
              setImageFile('');
            }}
          >
            Delete
          </Delete>
        )}
      </TopTitle>
      <ImagePlace>
        <InputFilePlace>
          {!image && (
            <InputFile
              type="file"
              accept=".png, .jpg, .jpeg .pdf"
              onChange={(event: React.ChangeEvent) => handleChange(event)}
            />
          )}
          <InputPlace src={image} />

          {!image && <BlueButton>Add W9</BlueButton>}
        </InputFilePlace>
        <Error>{err.errors?.w9?.[0]}</Error>
      </ImagePlace>
      <Bottom>
        <ButtonSave>
          <Button
            text="Save Changes"
            pad="8px 24px"
            handleButton={handleConfirm}
          />
        </ButtonSave>
      </Bottom>
    </Block>
  );
};

export default W9;
