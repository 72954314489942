import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fff;
  width: 30%;
  box-shadow: 2px 6px 24px rgba(16, 40, 68, 0.2);
max-height: 90%;
overflow: auto;
&::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
    }
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Title = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091A;
  margin: 0 23px;

`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: #f4f4f4;
`;

export const InputTextArea = styled.textarea`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 14px;
  color: #09091a;
  outline: none;
  max-width: 92%;
  width: 92%;
  max-height: 162px;
  margin: 0 auto;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 23px 0 0;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;