import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 35%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Title = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091A;
  padding: 20px 23px;

  @media screen and (max-width: 600px) {
    padding: 16px;
  }
`;

export const InvoiceForm = styled.div`
  overflow: hidden;
  height: 362px;
  padding: 32px;

  @media screen and (max-width: 600px) {
    padding: 16px;
  }
`;

export const InvoiceFormScroll = styled.div`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

export const InvoiceField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;
`;

export const InputDiv = styled.div`
  height: 41px;
  border: 1px solid #c6c7da;
  padding: 10px;
  margin-bottom: 12px;
  border-radius: 4px;
`;

export const Label = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin-bottom: 4px;
`;

export const Input = styled.input`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  outline: none;
  width: 100%;
`;

export const SelectedValue = styled.div`
  width: 100%;
  position: absolute;
  top: 55%;
  left: 0;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #c6c7da;
  min-height: 37px;
  max-height: 37px;
`;

export const FieldIcon = styled.div`
  position: absolute;
  top: 85%;
  transform: translateY(-50%);
  right: 0;
  padding: 10px;
`;

export const InvoiceSelect = styled.select`
  border: 1px solid #c6c7da;
  border-radius: 4px;
  outline: none;
  padding: 8px 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: transparent;
  opacity: 0;
`;

export const Opt = styled.option`
  border: 1px solid #c6c7da;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
`;

export const InvoiceLabel = styled.label`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  margin: 0 0 4px;
`;

export const CheckboxBlock = styled.div`
  margin-top: 20px;
`;

export const Error = styled.span`
  position: absolute;
  bottom: -17px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 12px;
  color: #f30f00;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  padding: 0.05px;
  background-color: #F4F4F4;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px;

  @media screen and (max-width: 600px) {
    padding: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.button`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;

export const Preview = styled.p`
  color: #616172;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  margin-right: 40px;
`;
