import * as React from 'react';
import { SVGProps } from 'react';

type DataProps = {
  data?: string;
};

const PersonForm: React.FC<DataProps> = (
  { data },
  props: SVGProps<SVGSVGElement>,
) => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 8.685c1.423 0 2.576-1.179 2.576-2.632s-1.153-2.632-2.575-2.632c-1.423 0-2.576 1.179-2.576 2.632S8.578 8.685 10 8.685Zm4.294-2.632c0 2.422-1.922 4.386-4.293 4.386-2.372 0-4.294-1.964-4.294-4.386s1.922-4.386 4.294-4.386c2.37 0 4.293 1.964 4.293 4.386ZM14.293 13.07c-.948 0-1.717-.785-1.717-1.754h2.02c.627 0 1.233.235 1.704.66.47.424.773 1.009.851 1.645l.336 2.74a1.79 1.79 0 0 1-.417 1.38 1.72 1.72 0 0 1-.581.438 1.687 1.687 0 0 1-.707.155H4.218c-.244 0-.485-.053-.707-.155a1.72 1.72 0 0 1-.581-.438 1.79 1.79 0 0 1-.417-1.379l.335-2.74a2.65 2.65 0 0 1 .852-1.647 2.545 2.545 0 0 1 1.705-.659h2.019c0 .969-.769 1.754-1.717 1.754h-.302a.85.85 0 0 0-.569.22.883.883 0 0 0-.284.549l-.335 2.74h11.565l-.335-2.74a.884.884 0 0 0-.284-.549.848.848 0 0 0-.567-.22h-.303Z"
      fill={data ? '#616172' : '#C6C7DA'}
    />
  </svg>
);

export default PersonForm;
