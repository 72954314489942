import React, { ChangeEvent, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Actions,
  Block,
  BlockScroll,
  Bottom,
  Cancel,
  Error,
  FieldIcon,
  InputDiv,
  Line,
  LoadsBlock,
  LoadsFlex,
  LoadsInput,
  LoadsInputField,
  LoadsInputFieldHalf,
  LoadsInputFieldThird,
  LoadsLabel,
  LoadsSelect,
  LoadsText,
  LoadsTitle,
  Opt,
  SelectedValue,
  Wrapper,
} from './customerModal.style';
import americaStates from '../../../../americaStates.json';
import { getLoadData, updateCustomer } from '../../../../API/loads';
import { SelectArrow } from '../../../../assets/icons';
import Button from '../../../../components/Button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { resetError } from '../../../../store/slices/loadsSlice';
import ModalPortal from '../../ModalPortal';

const defaultErrorState = {
  message: '',
  errors: {
    business_name: [],
    street_address: [],
    city: [],
  },
};

type ModalAssignProps = {
  close: () => void;
  refCustomer: any;
};

const CustomerModal: React.FC<ModalAssignProps> = ({ close, refCustomer }) => {
  const dispatch = useAppDispatch();

  const { loadId, loadInfo, error } = useAppSelector((state: { loads: any }) => state.loads);

  const [loadsCustomer, setLoadsCustomer] = useState({
    businessNameCustomer: loadInfo.load.customer.business_name,
    streetAddressCustomer: loadInfo.load.customer.street_address,
    cityCustomer: loadInfo.load.customer.city,
    zipCustomer: loadInfo.load.customer.zip ? loadInfo.load.customer.zip : '',
    fullNameCustomer: loadInfo.load.customer.full_name ? loadInfo.load.customer.full_name : '',
    faxCustomer: loadInfo.load.customer.fax ? loadInfo.load.customer.fax : '',
    emailCustomer: loadInfo.load.customer.email ? loadInfo.load.customer.email : '',
  });

  const [stateCustomer, setStateCustomer] = useState<string>(loadInfo.load.customer.state);
  const [phoneCustomer, setPhoneCustomer] = useState<string>(
    loadInfo.load.customer.phone ? loadInfo.load.customer.phone : ''
  );

  const [err, setErr] = useState(defaultErrorState);

  useEffect(() => {
    if (error) {
      setErr(error);
    }

    return () => {
      dispatch(resetError());
    };
  }, error);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>, setState: any, name: any, state: any) => {
    if (name === 'businessNameCustomer')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'cityCustomer')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else if (name === 'fullNameCustomer')
      setState({
        ...state,
        [name]: e.target.value.replace(/[^A-Za-z\s]/gi, ''),
      });
    else setState({ ...state, [name]: e.target.value });
  };

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].dataset.value);
  };

  const handleConfirm = async () => {
    const res: any = await dispatch(
      updateCustomer([
        {
          business_name: loadsCustomer.businessNameCustomer,
          street_address: loadsCustomer.streetAddressCustomer,
          city: loadsCustomer.cityCustomer,
          state: stateCustomer,
          zip: loadsCustomer.zipCustomer,
          full_name: loadsCustomer.fullNameCustomer,
          phone: phoneCustomer,
          fax: loadsCustomer.faxCustomer,
          email: loadsCustomer.emailCustomer,
        },
        loadId.id,
      ])
    );
    if (res.type === 'loads/updateCustomer/rejected') return setErr(res.payload);
    await dispatch(getLoadData(loadId.id));
    close();
  };

  return (
    <ModalPortal>
      <Wrapper ref={refCustomer}>
        <LoadsBlock>
          <LoadsTitle>Customer Information</LoadsTitle>
          <Line />
          <Block>
            <BlockScroll>
              <LoadsInputField>
                <LoadsLabel htmlFor="businessNameCustomer">Business Name</LoadsLabel>
                <LoadsInput
                  id="businessNameCustomer"
                  type="text"
                  style={{
                    border: err.errors?.business_name?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({
                      ...err,
                      errors: { ...err.errors, business_name: [] },
                    })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInput(e, setLoadsCustomer, 'businessNameCustomer', loadsCustomer)
                  }
                  value={loadsCustomer.businessNameCustomer}
                />
                <Error>{err.errors?.business_name?.[0]}</Error>
              </LoadsInputField>
              <LoadsInputField>
                <LoadsLabel htmlFor="streetAddressCustomer">Street Address</LoadsLabel>
                <LoadsInput
                  id="streetAddressCustomer"
                  type="text"
                  style={{
                    border: err.errors?.street_address?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                  }}
                  onFocus={() =>
                    setErr({
                      ...err,
                      errors: { ...err.errors, street_address: [] },
                    })
                  }
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInput(e, setLoadsCustomer, 'streetAddressCustomer', loadsCustomer)
                  }
                  value={loadsCustomer.streetAddressCustomer}
                />
                <Error>{err.errors?.street_address?.[0]}</Error>
              </LoadsInputField>
              <LoadsFlex>
                <LoadsInputFieldThird>
                  <LoadsLabel htmlFor="cityCustomer">City</LoadsLabel>
                  <LoadsInput
                    id="cityCustomer"
                    type="text"
                    style={{
                      border: err.errors?.city?.[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                    }}
                    onFocus={() => setErr({ ...err, errors: { ...err.errors, city: [] } })}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInput(e, setLoadsCustomer, 'cityCustomer', loadsCustomer)
                    }
                    value={loadsCustomer.cityCustomer}
                  />
                  <Error>{err.errors?.city?.[0]}</Error>
                </LoadsInputFieldThird>
                <LoadsInputFieldThird>
                  <LoadsLabel htmlFor="stateCustomer">State</LoadsLabel>
                  <SelectedValue>{stateCustomer}</SelectedValue>
                  <FieldIcon style={{ top: '95%' }}>
                    <SelectArrow />
                  </FieldIcon>
                  <LoadsSelect
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      changeSelectState(e, setStateCustomer, setStateCustomer)
                    }
                  >
                    <Opt hidden></Opt>
                    {americaStates?.map((item: any) => (
                      <Opt key={item} value={item} data-value={item}>
                        {item}
                      </Opt>
                    ))}
                  </LoadsSelect>
                </LoadsInputFieldThird>
                <LoadsInputFieldThird>
                  <LoadsLabel htmlFor="zipCustomer">ZIP</LoadsLabel>
                  <LoadsInput
                    id="zipCustomer"
                    type="number"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInput(e, setLoadsCustomer, 'zipCustomer', loadsCustomer)
                    }
                    value={loadsCustomer.zipCustomer}
                  />
                </LoadsInputFieldThird>
              </LoadsFlex>

              <Line />

              <LoadsText>Contact details</LoadsText>
              <LoadsInputField>
                <LoadsLabel htmlFor="fullNameCustomer">Full Name</LoadsLabel>
                <LoadsInput
                  id="fullNameCustomer"
                  type="text"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInput(e, setLoadsCustomer, 'fullNameCustomer', loadsCustomer)
                  }
                  value={loadsCustomer.fullNameCustomer}
                />
              </LoadsInputField>

              <LoadsFlex>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="phoneCustomer">Phone</LoadsLabel>
                  <InputDiv>
                    <PhoneInput
                      international
                      limitMaxLength
                      value={phoneCustomer}
                      id="phoneCustomer"
                      onChange={(value: string) => setPhoneCustomer(value || '')}
                    />
                  </InputDiv>
                </LoadsInputFieldHalf>
                <LoadsInputFieldHalf>
                  <LoadsLabel htmlFor="faxCustomer">Fax</LoadsLabel>
                  <LoadsInput
                    id="faxCustomer"
                    type="number"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleInput(e, setLoadsCustomer, 'faxCustomer', loadsCustomer)
                    }
                    value={loadsCustomer.faxCustomer}
                  />
                </LoadsInputFieldHalf>
              </LoadsFlex>
              <LoadsInputField>
                <LoadsLabel htmlFor="emailCustomer">Email</LoadsLabel>
                <LoadsInput
                  id="emailCustomer"
                  type="email"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleInput(e, setLoadsCustomer, 'emailCustomer', loadsCustomer)
                  }
                  value={loadsCustomer.emailCustomer}
                />
              </LoadsInputField>
            </BlockScroll>
          </Block>
        </LoadsBlock>

        <Line />
        <Bottom>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Save" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default CustomerModal;
