import React, { useEffect, useRef, useState } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import {
  AddTerminalBlock,
  Wrapper,
  TerminalsList,
  Info,
  Top,
  Name,
  EditBlock,
  Block,
  Label,
  Value,
  Line,
  Text,
  LearnMore,
  NoTerminal,
  Actions,
} from './terminals.style';
import { getTerminal } from '../../../API/settings';
import { Edit } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import ModalTerminal from '../../../layout/Modal/ModalTerminal/ModalTerminal';
import useOnClickOutside from '../../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const Terminals: React.FC = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [addCheck, setAddCheck] = useState<string>('');
  const [item, setItem] = useState<number>(0);

  const refTerminal = useRef<any>();

  const dispatch = useAppDispatch();

  const { terminal } = useAppSelector(
    (state: { settings: any }) => state.settings,
  );

  useEffect(() => {
    dispatch(getTerminal(null));
  }, []);

  const openModal = (value: string, i: number) => {
    setModal(true);
    setAddCheck(value);
    setItem(i);
  };

  useEffect(() => {
    document.body.style.overflow = modal ? 'hidden' : 'unset';
  }, [modal])

  useOnClickOutside(refTerminal, () => setModal(false));

  return (
    <>
      {terminal.length < 4 && <AddTerminalBlock>
        <Wrapper>
          <Text>
            Terminals are used to manage orders that involve yards or terminals,
            order splits, and multiple drivers.{' '}
            <LearnMore>Learn more...</LearnMore>
          </Text>
          <NoTerminal>
            {terminal.length
              ? `There are ${terminal.length} terminals added`
              : 'There are no terminals added'}
          </NoTerminal>
        </Wrapper>
        <Actions>
          <Button text="Add Terminal" pad="8px 24px" handleButton={() => openModal('add', 0)} />
        </Actions>
      </AddTerminalBlock>}
      <TerminalsList>
        {terminal?.map((item: any, i: number) => {
          return (
            <Info key={i}>
              <Top>
                <Name>{item?.terminal_name}</Name>
                <EditBlock onClick={() => openModal('edit', i)}>
                  <Edit />
                </EditBlock>
              </Top>
              <Block>
                <Label>City:</Label>
                <Value>{item?.city}</Value>
              </Block>
              <Block>
                <Label>State:</Label>
                <Value>{item?.state}</Value>
              </Block>
              <Block>
                <Label>Zip:</Label>
                <Value>{item?.zip}</Value>
              </Block>
              <Line />
              <Block>
                <Label>Contact Name:</Label>
                <Value>{item?.contact_name}</Value>
              </Block>
              <Block>
                <Label>Phone Number:</Label>
                <Value>{formatPhoneNumberIntl(item?.phone)}</Value>
              </Block>
            </Info>
          );
        })}
      </TerminalsList>

      {modal && <ModalTerminal refTerminal={refTerminal} item={item} addCheck={addCheck} close={() => setModal(false)} />}
    </>
  );
};

export default Terminals;
