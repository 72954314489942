import React, { useEffect, useRef, useState } from 'react';

import {
  Wrapper,
  Top,
  Title,
  Actions,
  DownloadSample,
  DownloadText,
  SearchField,
  Input,
  SearchWrapper,
  FleetTabs,
  Tab,
  Line,
  BorderBottom,
} from './fleet.style';
import { exportFleets, getFleet } from '../../API/fleet';
import { Download, Plus, Search } from '../../assets/icons';
import Button from '../../components/Button/Button';
import FleetTable from '../../components/Fleet/FleetTable';
import Container from '../../layout/Container';
import ModalFleet from '../../layout/Modal/ModalFleet/ModalFleet';
import useOnClickOutside from '../../layout/Modal/useClickOutsideModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Preloader from '../Preloader/Preloader';

const Fleet: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const dispatch = useAppDispatch();
  const refFleet = useRef<any>();
  const { fleetList, loading } = useAppSelector(
    (state: { fleet: any }) => state.fleet,
  );

  const [tab, setTab] = useState<number>(1);
  const [tabValue, setTabValue] = useState<number>(0);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const filteredPersons = fleetList.filter((item: any) => {
    return item.id.toString().toLowerCase().includes(`${value.toLowerCase()}`);
  });

  useEffect(() => {
    dispatch(getFleet(null));
  }, []);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  const openModal = () => setOpen(true);

  const fetchFleet = async () => {
    let res: any;
    if (tab === 1) {
      res = await dispatch(exportFleets({ type: 'truck' }));
    } else {
      res = await dispatch(exportFleets({ type: 'trailer' }));
    }

    const link = document.createElement('a');
    link.href = res.payload;
    link.setAttribute('download', 'fleetTable.csv');
    document.body.appendChild(link);
    link.click();
  };

  useOnClickOutside(refFleet, () => setOpen(false));

  if (loading) return <Preloader />;

  return (
    <>
      <Container>
        <Wrapper>
          <Top>
            <Title>Fleet</Title>
            <Actions>
              <DownloadSample onClick={fetchFleet}>
                <Download /> <DownloadText>Download</DownloadText>
              </DownloadSample>
              <Button
                text={tab === 1 ? 'Add Truck' : 'Add Trailer'}
                pad="10px 20px"
                icon={<Plus />}
                handleButton={openModal}
              />
            </Actions>
          </Top>
          <SearchField>
            <Input
              type="text"
              value={value}
              onChange={handleInput}
              placeholder="Search..."
            />
            <SearchWrapper>
              <Search />
            </SearchWrapper>
          </SearchField>
          <FleetTabs value={tabValue}>
            <Tab
              style={{
                color: tab === 1 ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab(1);
                setTabValue(0);
              }}
            >
              Trucks {tab === 1 && <BorderBottom />}
            </Tab>

            <Tab
              style={{
                color: tab === 2 ? '#1267CF' : '#616172',
              }}
              onClick={() => {
                setTab(2);
                setTabValue(1);
              }}
            >
              Trailers {tab === 2 && <BorderBottom />}
            </Tab>
          </FleetTabs>
          <Line />
          <FleetTable fleetList={filteredPersons} tab={tab} />
          {open && (
            <ModalFleet
              refFleet={refFleet}
              addCheck={true}
              trailerCheck={tab === 1 ? 'truck' : 'trailer'}
              text={tab === 2 ? 'Add Trailer' : 'Add Truck'}
              close={() => setOpen(false)}
            />
          )}
        </Wrapper>
      </Container>
    </>
  );
};

export default Fleet;
