import React, { useState, ChangeEvent } from 'react';
import PhoneInput from 'react-phone-number-input';

import {
  Wrapper,
  Title,
  InvoiceForm,
  InvoiceFormScroll,
  InputDiv,
  Label,
  Input,
  InvoiceField,
  SelectedValue,
  InvoiceSelect,
  Opt,
  FieldIcon,
  InvoiceLabel,
  CheckboxBlock,
  Error,
  Line,
  Bottom,
  Actions,
  Preview,
  Cancel,
} from './modalBOL.style';
import { getPreviewBOL, sendBOL } from '../../../API/invoice';
import { SelectArrow } from '../../../assets/icons';
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useAppDispatch } from '../../../store/hooks';
import ModalPortal from '../ModalPortal';

type ViewFleetImageProps = {
  close: () => void;
  load: any;
  refBol: any;
};

const ModalBOL: React.FC<ViewFleetImageProps> = ({ close, load, refBol }) => {
  const [err, setErr] = useState({
    message: '',
    errors: {
      template: [],
      email: [],
    },
  });

  const [showBol, setShowBol] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [fax, setFax] = useState<string>('');
  const [bol, setBol] = useState<string>('default');
  const [bolName, setBolName] = useState<string>('Default');

  const dispatch = useAppDispatch();

  const changeSelectState = (e: React.ChangeEvent<HTMLSelectElement>, setState: any, setStateIndex?: any) => {
    setState(e.target.value);
    setStateIndex(e.currentTarget.selectedOptions[0].label);
  };

  const getPreview = async () => {
    if (load.status !== 'Delivered') return;
    else {
      const res = await dispatch(getPreviewBOL(load.id));
      const winHtml: string | any = res.payload;
      const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));
      window.open(winUrl, '_blank');
    }
  };

  const handleConfirm = async () => {
    if (load.status !== 'Delivered') return;
    else {
      const res: any = await dispatch(
        sendBOL([
          {
            template: bol,
            fax: fax,
            email: email,
            show_additional_info: showBol ? '1' : '0',
          },
          load.id,
        ])
      );
      if (res.type === 'invoice/sendBOL/rejected') return setErr(res.payload);
      close();
    }
  };

  return (
    <ModalPortal>
      <Wrapper ref={refBol}>
        <Title>Customer Information</Title>
        <Line />
        <InvoiceForm>
          <InvoiceFormScroll>
            <InvoiceField>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                style={{
                  border: err.errors?.email[0] ? '1px solid #ff0000' : '1px solid #c6c7da',
                }}
                onFocus={() => setErr({ ...err, errors: { ...err.errors, email: [] } })}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
              />
              <Error style={{ bottom: '-17px' }}>{err.errors?.email[0]}</Error>
            </InvoiceField>
            <InvoiceField>
              <Label htmlFor="fax">Fax</Label>
              <InputDiv>
                <PhoneInput
                  international
                  limitMaxLength
                  value={fax}
                  id="fax"
                  onChange={(value: string) => setFax(value || '')}
                />
              </InputDiv>
            </InvoiceField>
            <InvoiceField>
              <InvoiceLabel htmlFor="bol">BOL Template</InvoiceLabel>
              <SelectedValue>{bolName ? bolName : 'Select Bol template'}</SelectedValue>
              <FieldIcon>
                <SelectArrow />
              </FieldIcon>
              <InvoiceSelect
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => changeSelectState(e, setBol, setBolName)}
                onFocus={() =>
                  setErr({
                    ...err,
                    errors: { ...err.errors, template: [] },
                  })
                }
              >
                <Opt hidden></Opt>
                <Opt value="default">Default</Opt>
              </InvoiceSelect>

              <Error style={{ bottom: '-30px' }}>{err.errors?.template?.[0]}</Error>
            </InvoiceField>
            <CheckboxBlock>
              <Checkbox
                title="Show Shipper/Customer info in BOL header"
                checkboxName="show BOL"
                checked={showBol}
                setChecked={() => setShowBol(!showBol)}
              />
            </CheckboxBlock>
          </InvoiceFormScroll>
        </InvoiceForm>
        <Line />
        <Bottom>
          <Preview onClick={getPreview}>Preview</Preview>
          <Actions>
            <Cancel onClick={close}>Cancel</Cancel>
            <Button text="Send" pad="12px 26px" handleButton={handleConfirm} />
          </Actions>
        </Bottom>
      </Wrapper>
    </ModalPortal>
  );
};

export default ModalBOL;
