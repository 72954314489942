import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 49%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 32px;
  position: relative;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 525px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LoadID = styled.p`
  border: 1px solid #9292a4;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;

  @media screen and (max-width: 525px) {
    width: 100%;
  }
`;

export const LoadName = styled.span`
  color: #09091a;
  margin-left: 5px;
  font-weight: 700;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 525px) {
    width: 100%;
    margin-top: 16px;
    justify-content: space-between;
  }
`;

export const Action = styled.div`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #616172;
  margin-right: 32px;
  cursor: pointer;
`;

export const DriverInfo = styled.div`
  margin-top: 20px;
`;

export const LoadsFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadsFlexColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const DriverExtra = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 525px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LoadsDays = styled.p`
  margin-left: 16px;
  color: #09091A;
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 525px) {
    margin-left: 0;
  }
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 6px;
  font-family: 'MontserratBold';
  font-weight: '700';
  font-size: 12px;
  text-transform: uppercase;
  color: #0baa1b;
  background-color: rgba(11, 170, 27, 0.15);
  margin-right: 16px;

  @media screen and (max-width: 525px) {
    margin-right: 0;
    margin-bottom: 8px;
  }
`;

export const Car = styled.p`
  font-family: 'MontserratSemiBold';
  font-weight: 600;
  font-size: 16px;
  color: #09091a;
  margin-right: 12px;

  @media screen and (max-width: 525px) {
    margin-bottom: 8px;
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;

  @media screen and (max-width: 525px) {
    flex-direction: column;
  }
`;

export const DetailBlock = styled.div`
  width: 32%;

  @media screen and (max-width: 600px) {
    width: 50%;
    margin-bottom: 14px;
  }

  @media screen and (max-width: 525px) {
    width: 100%;
  }
`;

export const DetailTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 12px;
  color: #616172;
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export const DetailText = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  color: #09091a;
  line-height: 22px;
`;