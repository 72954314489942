import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  cursor: pointer;
`;

export const TopTitle = styled.p`
  font-family: 'MontserratBold';
  font-weight: 700;
  font-size: 24px;
  color: #616172;
  margin-left: 10px;
`;

export const Wrapper = styled.div`
  width: 280px;
  max-height: 475px;
  background-color: #fff;
  padding: 0 26px;
  border-radius: 8px;

  @media screen and (max-width: 829px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 26px auto;
  text-decoration: none;
  height: 24px;
`;

export const Logout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 26px 0 26px 14px;
  text-decoration: none;
  height: 24px;
  color: #09091a;
  font-family: 'Montserrat';
  font-weight: 400;
  cursor: pointer;
`;

export const MenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VerticalLine = styled.div`
  width: 2px;
  height: 24px;
  background-color: #0e71eb;
`;

export const Text = styled.p`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 16px;
  margin-left: 14px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f4f4f4;
  margin: 37px auto;
`;
