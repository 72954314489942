import { createAsyncThunk } from '@reduxjs/toolkit';

import api, { ErrorType } from '../API';

export const routes = {
  helpCenter: 'api/crm/help_center',
};

export type HelpCenter = {
    name: string;
    email: string;
    phone: string;
    message: string;
  };

export const sendMessage = createAsyncThunk<
  null,
  HelpCenter,
  {
    rejectValue: ErrorType;
  }
>('helpCenter/sendMessage', async (sendData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.helpCenter}`, sendData);
    return response.data.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
      errors: e.errors,
    } as ErrorType);
  }
});
